import styled from 'styled-components';

export const BannerContainer = styled.div`
background: transparent;
position: relative;
margin: auto;

.color-overlay {
    position: absolute;
    background: linear-gradient(-45deg, var(--secondary), var(--primary));
    overflow: hidden;
    height: 100%;
    z-index: 0;
    inset: 0;
    border-radius: 1rem;
    mix-blend-mode: hard-light;
    opacity: .95;
    max-height: 159px;
}

&.section-banner {
    /* aspect-ratio: 1180 / 281; */
    min-height: 60px;
    border-radius: 0;
}

&.profile-banner {
    padding: 0;
    background-position: top;
    max-width: 1180px;
    position: relative;
    height: auto;
    width: 100%;

    @media (max-width: 1280px) {
        margin-bottom: 17.25rem;
    }
}
.section-banner-title, .section-banner-text {
    position: relative;
}
img[class*='-shield'] {
    max-width: 60px;
    max-height: 60px;
    @media (max-width: 680px) {
        max-width: 24px;
        max-height: 24px;
    }
}
@media (max-width: 1280px) {
    padding-bottom: 114px;
}

@media (max-width: 560px) {
    &.profile-banner {
        margin-bottom: 0;
    }
}
`

export const AvatarContainer = styled.div`
    background-color: rgba(92,30,121,.7);
    top: 100%;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    z-index: 20;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 44px;
    padding-right: 55px;
    padding-top: 50px;
    padding-bottom: 50px;
    
    p {
        flex-shrink: 1;
        font-size: 1.5rem;
        font-weight: 700;
    }
    p.form-link {
        font-size: 0.75rem;
        text-align: left;
        margin-top: 0.25rem;
    }
    .group {
        display: flex;
        gap: 8px;
    }
    .hexagon-container {
        position: relative;
        top: -30px;
        left: -24px;
        width: clamp(120px, 12.7vw, 130px);
        aspect-ratio: 1;
        @media (max-width: 1280px) {
            left: 0;
            top: 0;
        }
    }

    div.home-user-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 1280px) {
            flex-direction: column;
        }

        .left {
            display: flex;
            flex-direction: row;

            div.greeting-user {
                position: relative;
                margin-left: 2.5rem;

                @media (max-width: 768px) {
                    margin-top: 16px;
                    margin-left: 0;
                    text-align: center;
                }

                p {
                    text-align: left;

                    @media (max-width: 768px) {
                        text-align: center;
                    }
                }

                h1 {
                    color: #fff;
                    font-size: 2rem;
                    margin-bottom: 12px;
                    text-align: left;
                }
            }

            @media (max-width: 1280px) {
                flex-direction: column;
                align-items: center;
                position: relative;
                top: -50px;

                div.greeting-user {
                    p {
                        margin-top: 0;
                    }
                }
                p.form-link {
                    text-align: center;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            div.user-info-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            & > div.line-deco {
                border-right: 1px solid #f5f5fa;
                height: 1.25rem;
                position: relative;
                margin: 0 2rem;
                @media (max-width: 480px) {
                    margin: 0 1rem;
                }

                &.desk {
                    @media (max-width: 480px) {
                        display: none;
                    }
                }
            }    
            p {
                margin: 0;
            }
            p.text-small {
                margin-top: 0.5rem;
                color: #ffffff;
                font-size: 0.75rem;
                @media (max-width: 480px) {
                    font-size: 0.5rem;
                }
            }
            p.text-mmedium {
                font-size: 1rem;
            }
            @media (max-width: 1280px) {
                flex-direction: row;
                position: relative;
                top: -20px;
            }
        }
    }
`

export const SliderHome = styled.div`
position: absolute;
z-index: 0;
border-radius: 20px 20px 0 0;
grid-column: span 2;
max-width: 1180px;
overflow: hidden;

.carousel.carousel-slider {
    aspect-ratio: 1180 / 281;
    overflow: hidden;
}

.slider {
    aspect-ratio: 1180 / 281;
}

.slide {
    div, a {
        height: 100%;
        width: 100%;
    }
    img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }
}

@media (min-width: 1440px) {
  margin-inline: auto;
}
@media (max-width: 480px) {
  height: 100%;
}
@media (max-width: 768px) {
  margin-top: 0;
}
`

export const Anchor = styled.a`
    display: inline-block;
`

export const Planet = styled.img`
    aspect-ratio: 1;
    max-width: 100px;
    height: auto;
`

export const Logo = styled.img`
    max-width: 70px;
`
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  &>div {
    flex: 1 100%;
  }
  input[type="date"] {
    border: 1px solid var(--primary);
    border-radius: 12px;
    width: 100%;
    padding-left: 1rem;
  }
  input[value=""][type="date"] {
    color: transparent; 
  }
  input[value=""][type="date"]:focus {
    color: var(--text); 
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    & > div.form-input {
      flex: 100%;
      flex-shrink: 0;
      width: 100%;
    }
  }
`;

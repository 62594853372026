import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageLoaderComponent from '../../components/PageLoader';
import api from '../../services/api';
import * as S from "./style"
import { useAuth } from '../../contexts/AuthContext';

export default function UsePolicy() {
  const [term, setTerm] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    const Data = async () => {
      try {
        const { data } = await api.get(`term/any`)
        setTerm(data)

      } catch (err) {
        toast.error(err.response.data.message, {
          theme: "colored"
        });
      }
    };
    Data();
  }, [user])

  if (!term) <PageLoaderComponent />

  return <S.Container className="landing">
    <S.PerfectScrollbar >
      <S.Body>
        <div className="text-center mb-5">
          <h1>Regulamento</h1>
        </div>

        <div dangerouslySetInnerHTML={{ __html: term.content }} />
      </S.Body>
    </S.PerfectScrollbar>
  </S.Container>
}
import mecanicas from '../../assets/img/icons/mecanicas.png'
import result from '../../assets/img/icons/result.png'
import fale from '../../assets/img/icons/fale.png'
import { useAuth } from '../../contexts/AuthContext'
import * as S from './style'

function FastAccess(props) {
  const { userCan } = useAuth()
  return <S.GridFastAcccess className="grid medium-space">
    <S.FastLink to="/mecanicas" img={mecanicas}>
      <p className="product-category-box-title">Mecânica</p>
      <button type="button" className="product-category-link"><span>Acesse</span></button>
    </S.FastLink>

    {userCan('read:ranking') && <S.FastLink to="/ranking" img={result}>
      <p className="product-category-box-title">Resultados</p>
      <button type="button" className="product-category-link"><span>Acesse</span></button>
    </S.FastLink>}

    <S.FastLink to="/fale-conosco" img={fale}>
      <p className="product-category-box-title">Fale conosco</p>
      <button type="button" className="product-category-link"><span className="text-gradient">Acesse</span></button>
    </S.FastLink>
  </S.GridFastAcccess>
}

export default FastAccess

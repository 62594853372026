import { useCallback, useEffect, useState } from 'react'
import InputSelect from "../../Forms/InputSelect"

function HoldingSelectorComponent ({onChange, holdingList, ...rest}) {
  const [holdings, setHoldings] = useState([])

  const onChangeParse = useCallback((value) => {
    if (!value) {
      onChange(undefined)
      return
    }
    onChange(JSON.parse(value))
  }, [onChange])

  useEffect (() => {
    setHoldings(holdingList)
  }, [holdingList])

  return <InputSelect {...rest} name="holding-id" id="holding-id" onChange={onChangeParse}>
    <option value="">Selecione uma rede</option>
    {holdings && holdings.map(h => <option value={JSON.stringify(h)}>{h.name}</option>)}
  </InputSelect>
}

export default HoldingSelectorComponent

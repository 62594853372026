import styled from "styled-components"

export const Container = styled.div`
  h2, h3 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 16px;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
`
import styled, { keyframes } from 'styled-components'
import InputPasswordComponent from "../../components/Forms/InputPassword"
import GoogleSocialButtonComponent from '../../components/SocialButton/Google'
import background from '../../assets/img/landing/landing-background.jpg'

const fadeEaseInOut = keyframes`
 0% {
    transform: translate(50%);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0px;
 }
 100% { 
    opacity: 1;
    transform: translateX(0px);
    visibility: visible;
    z-index: 1;
    position: absolute;
    left: 0px;
 }
`

const RotateAnimation = keyframes`
 0% {
    transform: rotate(0deg);
 }
 100% { 
    transform: rotate(360deg);
 }
`

export const Container = styled.div`
   position: relative;
   inset: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url(${background});
   flex-direction: column;
   background-attachment: fixed;
   min-height: 100vh;
   gap: 32px;
   overflow: hidden;
`


export const ContainerLogin = styled.div`
   width: 484px;
   padding: 16px 64px 64px 64px;
   background-color: rgba(92, 30, 121, .4);
   height: 622px;
   box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
   border-radius: 12px;
   margin-bottom: 32px;
   z-index: 1;

   .form {
      margin-top: 76px;
   }
`

export const ContainerRegister = styled.div`
   height: auto;
   max-height: auto;
   img.right {
      position: absolute;
      left: 360px;
      top: -25px;
      max-width: 160px;
   }
   img.left {
      position: absolute;
      left: -60px;
      top: -30px;
      max-width: 140px;
   }
   .tab-switch {
      position: absolute;
      max-width: 60%;
      margin-left: 16px;
      .false {
         color: #888;
         background-color: #4444ee30;
      }
      .active {
         color: #fff;
         background-color: #4444e0;
      }
   }

   h2 {
      padding-top: 60px;
      margin-bottom: 16px;

   }

   form {
      margin-top: 0 !important;
   }

   button.button.primary {
      display: var(--primary);
   }

   @media(min-width: 1365px) {   
      height: 100%;
      max-height: 735px;

      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const ContainerHolder = styled.div`
   width: 100%;
   height: 100%;
   overflow: auto;

   @media(min-width: 1366px) {
      width: 100%;
      height: calc(100% - 76px);
      overflow-y: auto;
      overflow-x: visible;
   }
`

export const Line = styled.div`
   align-items: center;
   display: flex;
   justify-content: center;
   margin-top: 40px;
   margin-bottom: 16px;
`

export const PlanetContainer = styled.div`
   position: absolute;
   top: 50%;
   img {
      animation: ${RotateAnimation} 240s linear forwards infinite;
   }
`

export const Logo = styled.img`
   max-width: 320px;
   margin-top: 32px;
`

export const InputPassword = styled(InputPasswordComponent)`
   .button.primary {
      box-shadow: none;
   }
`

export const Button = styled.button`
   &.button.secondary {
      box-shadow: none;
   }
`

export const GoogleSocialButton = styled(GoogleSocialButtonComponent)`
   div {
      background-color: #fff;
      border-radius: 8px;
   }
`

export const Text = styled.p.attrs(() => {
   return {
      className: 'form-text form-link cursor-pointer'
   }
})`
   font-weight: bold;
`

export const Anchor = styled.a`
   color: white;
`
import reactionLikeImg from '../../../../assets/img/reaction/like.png';
import reactionWowImg from '../../../../assets/img/reaction/wow.png';
import reactionHappyImg from '../../../../assets/img/reaction/happy.png';
import { GridPosts } from './style';


function ProfileBlogPosts() {
  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Meus Posts no Blog</p>
          <h2 className="section-title">Informação de posts no blog</h2>
        </div>
      </div>
      <div class="section-filters-bar v2">
        <form class="form">
          <div class="form-item split medium">
            <div class="form-select">
              <label for="post-filter-category">Filtrar por</label>
              <select id="post-filter-category" name="post_filter_category">
                <option value="0">Reações recebidas</option>
                <option value="1">Qtd. de Comentários</option>
                <option value="2">Qtd. de Compartilhamentos</option>
              </select>
              <svg class="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div class="form-select">
              <label for="post-filter-order">Ordenar por</label>
              <select id="post-filter-order" name="post_filter_order">
                <option value="0">Maior para o menor</option>
                <option value="1">Menor para o maior</option>
              </select>
              <svg class="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div class="form-select">
              <label for="post-filter-show">Mostrar</label>
              <select id="post-filter-show" name="post_filter_show">
                <option value="0">12 Posts por página</option>
                <option value="1">24 Posts por página</option>
              </select>
              <svg class="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <button class="button primary">Filtrar Posts</button>
          </div>
        </form>
      </div>

      <GridPosts>
        {[...Array(3)].map(() => (<div class="post-preview">
          <figure class="post-preview-image liquid">
          </figure>

          <div class="post-preview-info fixed-height">
            <div class="post-preview-info-top">
              <p class="post-preview-timestamp">2 days ago</p>

              <p class="post-preview-title">Aqui está o porquê de eu ter me tornado streamer de games</p>
            </div>

            <div class="post-preview-info-bottom">
              <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

              <a class="post-preview-link" href="profile-post.html">Ler mais...</a>
            </div>
          </div>

          <div class="content-actions">
            <div class="content-action">
              <div class="meta-line">
                <div class="meta-line-list reaction-item-list">
                  <div class="reaction-item">
                    <img class="reaction-image reaction-item-dropdown-trigger" src={reactionWowImg} alt="reaction-wow" />
                  </div>
                  <div class="reaction-item">
                    <img class="reaction-image reaction-item-dropdown-trigger" src={reactionHappyImg} alt="reaction-happy" />
                  </div>
                  <div class="reaction-item">
                    <img class="reaction-image reaction-item-dropdown-trigger" src={reactionLikeImg} alt="reaction-like" />
                  </div>
                </div>
              </div>
            </div>

            <div class="content-action">
              <div class="meta-line">
                <a class="meta-line-link" href="/perfil/blog-posts">5 Comentários</a>
              </div>

              <div class="meta-line">
                <p class="meta-line-text">0 Compartilhamentos</p>
              </div>
            </div>
          </div>
        </div>))}
      </GridPosts>
    </div>

  )
}

export default ProfileBlogPosts;
import styled from "styled-components"

export const Margin = styled.div`
  &:before {
    aspect-ratio: 20 / 6;
    content: '';
    display: block;
  }
`

export const Container = styled.div`
  && {
    margin-top: 6%;
  }
`

export const Title = styled.h1`
  color: var(--white);
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
`

export const ResultContainer = styled.div`
  background-color: #3a134c;
`

export const Wrapper = styled.div`
  border-radius: 24px;
  color: var(--white);
  margin: 2rem auto;
  max-width: 960px;
  padding: 2rem 3rem;
  background-color: #3a134c;
`

export const Table = styled.table`
  font-weight: bold;
  font-size: 24px;
  margin-top: 32px;
  text-align: center;
  width: 100%;
  
  &.transparent-bg tbody td:first-child {
    background-color: transparent;
  }

  td {
    border-collapse: collapse;
    border: 1px solid var(--white);
    padding: 8px;

    &:first-child {
      background-color: #560686;
      text-transform: uppercase;
    }
  }

  thead {
    td {
      text-align: center;
      text-transform: uppercase;
    }  
  }  
  
  & + p {
    margin-top: 8px;
    font-size: 16px;
  }

  &:first-child {
    margin-top: 0px;
  }

  @media (max-width: 560px) {
    font-size: .6rem;
  }
`
export const Center = styled.h2`
  color: var(--white);
  text-align: center;
`
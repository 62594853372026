import { useState, useCallback } from "react"
import { isAxiosError } from 'axios'
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import api from "../../../services/api"
import Template from "../../../template"
import AccountHubIcon from '../../../assets/img/banner/accounthub-icon.png'
import InputText from '../../../components/Forms/InputText'
import InputSelect from '../../../components/Forms/InputSelect'
import { MaskCNPJ } from "../../../utils/mask"
import BannerTitleComponent from "../../../components/BannerTitle"
import { FormValidator } from '../../../domain/FormValidator'
import schema from '../../../config/providerFormScheme.json'
import * as S from './style'

const formValidator = new FormValidator(schema)

export default function NewProvider () {
  const navigate = useNavigate();
  const [provider, setProvider] = useState({});
  const [errors, setErrors] = useState({});

  const handleCreateProvider = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      setErrors({})
      if (!formValidator.validate(provider)) {
        setErrors(formValidator.errors)
        return;
      }
      await api.post('/providers', provider)
      toast.success('Nova loja cadastrada com sucesso!', { theme: 'colored' })
      navigate('/providers')
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.statusCode !== 500) {
          toast.error(err.response.data, { theme: 'colored' });
          return;
        }
      }
      toast.error('Ocorreu um erro no servidor. Por favor tente novamente mais tarde', { theme: 'colored' });
    }
  }, [navigate, provider])

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Loja"
        subTitle="Cadastre todas as lojas que pertencem a sua rede para participar da campanha"
        imgAlt="accounthub-icon"
      />

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Nova loja</p>

              <h2 className="section-title">Cadastre todas as lojas que pertencem a sua rede para participar da campanha</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre o nova loja</p>

              <div className="widget-box-content">
                <form className="form" action="" method="POST" onSubmit={handleCreateProvider}>
                  <S.Row>
                    <InputText name="name" label="Nome" value={provider.name} onChange={(name) => setProvider({...provider, name})} error={errors.name} />
                  </S.Row>
                  <S.Row>
                    <InputText name="cnpj" label="CNPJ" value={provider.cnpj} onChange={(cnpj) => setProvider({...provider, cnpj})} mask={MaskCNPJ} maxLength="18" error={errors.cnpj} />
                  </S.Row>
                  <S.Row>
                    <InputSelect name="provider_type" label="Tipo de loja" value={provider.provider_type} onChange={(provider_type) => setProvider({...provider, provider_type})} error={errors.provider_type}>
                      <option value="">Selecione uma opção</option>
                      <option value="cd">Centro de distribuição</option>
                      <option value="loja">Loja</option>
                    </InputSelect>
                  </S.Row>
                  <S.Row>
                    <button className="button secondary" type="submit">Salvar</button>
                  </S.Row>
                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}
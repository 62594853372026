import Template from '../../template'
import slide1 from '../../assets/img/mecanicas/slider-1.jpg'
import slide2 from '../../assets/img/mecanicas/slider-2.jpg'
import slide3 from '../../assets/img/mecanicas/slider-3.jpg'
import slide4 from '../../assets/img/mecanicas/slider-4.jpg'
import slide5 from '../../assets/img/mecanicas/slider-5.jpg'
import slide6 from '../../assets/img/mecanicas/slider-6.jpg'
import slide7 from '../../assets/img/mecanicas/slider-7.jpg'
import slide8 from '../../assets/img/mecanicas/slider-8.jpg'
import slide9 from '../../assets/img/mecanicas/slider-9.jpg'
import * as S from './style'

function Mecanicas() {
  return <Template homeButton={true}>
    <div className="wrapper">
      <S.MecanicaWidgetBox>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/859817647?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
            title="Ganhe o Mundo Israel_Vídeo Término da Campanha_29.08"
          />
        </div>
        <S.Image src={slide1} alt="" />
        <S.Image src={slide2} alt="" />
        <S.Image src={slide3} alt="" />
        <S.Image src={slide4} alt="" />
        <S.Image src={slide5} alt="" />
        <S.Image src={slide6} alt="" />
        <S.Image src={slide7} alt="" />
        <S.Image src={slide8} alt="" />
        <S.Image src={slide9} alt="" />
      </S.MecanicaWidgetBox>
    </div>

  </Template>
}

export default Mecanicas
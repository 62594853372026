import styled from 'styled-components';

export const EditorContainer = styled.div`
&&& {
  .ql-toolbar {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
  .ql-container {
    border-top: 1px solid rgb(204,204,204);
    border-radius: 1rem;
  }
  .ql-editor {
    min-height: 20rem;
  }
}
`
import { useCallback, useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Template from "../../template"
import PageLoaderComponent from "../../components/PageLoader"
import PostCard from '../../components/PostCard'
import FastAccess from '../../components/FastAccess'
import api from "../../services/api";
import * as S from "./style"

export default function Blog() {
  const [post, setPost] = useState()
  const [relateds, setRelateds] = useState()
  const { slug } = useParams()

  const timestamp = useMemo(() => {
    if (!post) return;
    return new Date(post.createdAt).toLocaleString('pt-BR')
  }, [post])

  const loadPost = useCallback(async () => {
    const response = await api.get(`/post/${slug}`)
    setPost(response.data.post)
    setRelateds(response.data.relateds)
  }, [slug])

  useEffect(() => {
    loadPost()
    document.body.scrollTop = 500;
    document.documentElement.scrollTop = 500;
  }, [loadPost])

  if (!post) return <PageLoaderComponent />

  return (
    <Template banner={false}>
      <FastAccess />
      <S.PostOpenContainer className="content-grid full">
        <article className="post-open">
          <div style={{ marginTop: '250px' }}></div>
          <S.PostCard className="post-open-body">
            <div className="post-open-heading">
              <p className="post-open-timestamp"><span className="highlighted">{timestamp}</span></p>
              <h2 className="post-open-title">{post.title}</h2>
            </div>
            <div className="post-open-content">
              <div className="post-open-content-body">
                <p className="post-open-paragraph" dangerouslySetInnerHTML={{ __html: post.content }}></p>
                <div className="tag-list">
                  {post.Tags && post.Tags.map(tag => <p className="tag-item secondary">{tag.title}</p>)}
                </div>
              </div>
            </div>
            {relateds && <S.PostRelatedPreview className="content-grid medium">
              <div className="section-header medium">
                <div className="section-header-info">
                  <p className="section-pretitle">Ver outras postagens</p>
                  <h2 className="section-title">Posts relacionados</h2>
                </div>
              </div>
              <S.Carousel>
                <div>
                  {relateds.map((post) => <PostCard {...post} />)}
                </div>
              </S.Carousel>
            </S.PostRelatedPreview>}
          </S.PostCard>
        </article>
      </S.PostOpenContainer>
    </Template>
  )
}
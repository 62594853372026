import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Tabela = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  z-index: 1000;
  position: relative;
  tr{
    th{
      padding: 10px;
    }
    td{
      padding: 10px;
    }
  }
@media (max-width: 768px){
  table, thead, tbody, th, td, tr { 
    display: block; 
	}
	
	
	tr { 
    border-radius: 20px;
    margin-bottom: 1.5rem;
    box-shadow: 0 0 10px 0px var(--primary), 5px 5px 10px 0px #000;
    padding: 1rem;
  }

  tr:nth-child(1) {
    border: unset;
    box-shadow: unset;
    th {
      display: none;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  } 
	
	td { 
		border: none;
		position: relative;
		padding-right: 50%;
	}
	
	td:before { 
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
  td::before {
    font-weight: 700;
    font-style: italic;
  }
	
	td:nth-of-type(1):before { content: "Nome:"; }
	td:nth-of-type(2):before { content: "Email:"; }
	td:nth-of-type(3):before { content: "CPF:"; }
  td:nth-of-type(4) { text-align: right }
}
`
export const Wrapper = styled.div`
  padding: 16px;
`;

export const ActionContainer = styled.td`
  display: flex;
`

export const Premmiar = styled.button`
  display: inline-flex;
  background-color: var(--success);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`

export const Edit = styled(Link)`
  display: inline-flex;
  background-color: var(--secondary);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`;

export const Exclude = styled.button`
  display: inline-flex;
  background-color: var(--danger);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--white);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
    margin-right: 16px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .form-input {
      margin-right: 0;
      width: 100%;
      max-width: 30rem;
      margin-bottom: 1rem;
    }
  }
`;

import React, { useRef } from "react";

export default function FilterComponent ({ onChange, onSubmit, label, children }) {
    const inputEl = useRef({ search: null });
    return (
        <div className="section-filters-bar v6">
            <div className="section-filters-bar-actions">
                <form className="form">
                    <div className="form-item split">
                        <div className="form-input small">
                            <input
                                className="form__input__focus"
                                type="text"
                                placeholder=""
                                ref={el => onChange(inputEl.current.search = el)}
                            />
                            <label className="form__label" htmlFor="events-search">{label}</label>
                        </div>

                        <button className="button primary" onClick={(e) => { e.preventDefault(); onSubmit() }}>
                            <svg className="icon-magnifying-glass">
                                <use href="#svg-magnifying-glass"></use>
                            </svg>
                        </button>
                    </div>
                </form>
                {children}
            </div>
        </div>
    )
}

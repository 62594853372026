import styled from 'styled-components'
import backgroundGanheOMundo from '../assets/img/background-home-v3.png'

export const TemplateContainer = styled.div.attrs(() => {
  return {
    className: 'js-background-container'
  }
})`
  p {
    color: var(--white);
    text-shadow: 1px 1px 1px var(--dark);
  }
`

export const Container = styled.div.attrs(() => {
  return {
    className: 'js-container'
  }
})`
  background-image: url(${backgroundGanheOMundo});
  background-size: contain;
  background-repeat: no-repeat;
  min-height: calc((108 * 100vw) / 192);
  width: 100%;
  background-position-y: -7vw;

  
  .wrapper {
    max-width: 1180px;
    margin: 0 auto;

    @media (max-width: 1240px) {
      padding: 0 32px;
      max-width: 100%;
    }

    @media (max-width: 680px) {
      
    }
  }

  .home {
    padding-top: calc(48.25vw - 20%);
  }

  .center {
    padding-top: 25%;

    p {
      margin-top: 8px;
      font-size: 1.625rem;
      font-weight: 700;
      text-align: center;
    }
  }

  > .menu-button {
    padding-top: 24px;
  }
`

export const LeaveButton = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    flex: 0 200px;
  }
`

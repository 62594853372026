import styled from 'styled-components';
import { Link as StyledLink } from "react-router-dom";

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
`;

export const Tabela = styled.div`
  table{
    width: 100%;
  }
  th{
    font-weight: 100;
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    font-weight: bold;
    font-size: 1rem;
  }

  @media (max-width: 1178px) {
    .avatar {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 8fr 2fr;
`;

export const Engagements = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 5fr 5fr;
`;

export const Nubers = styled.div`
text-align: center;
  .colors{
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: ${({ color }) => color};
  }
  p{
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    text-transform: uppercase;
  }
`;

export const Link = styled(StyledLink)`

`

export const Pergunta = styled.div`
  text-align: center;
  h4{
    margin-bottom: 20px;
  }
  button{
    border-radius: 0;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }
  a{
    font-family: Rajdhani,sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }   
`

export const Star = styled.img`
  max-width: 50px;
  width: auto;
  display: block;
  margin: 0;
`

export const StarCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-light);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  img {
    margin-left: 16px;
  }
`;

export const Margin = styled.div`
  &:before {
    aspect-ratio: 20 / 6;
    content: '';
    display: block;
  }
`

export const FooterTable = styled.div`
  width: 100%;
  overflow-x: auto;
  table {
    width: auto;
    font-size: .5rem;
  }

  td {
    padding: 8px;
    background-color: #fff;
    text-align: center;
  }

  thead td, td.head {
    background-color: var(--primary);
    color: var(--light);
  }
`
export const Filtros = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

export const FiltrosCargo = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: .5rem;

  @media (max-width: 768px) {
    gap: 8px;
  }
`

export const Wrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
`

export const Title = styled.h2`
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0.5rem;
`

export const Loading = styled.p`
  text-align: center;
  font-size: 24px;
  padding-top: 32px;
`

export const Selector = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
`
import produce from 'immer'

export default function cart (state = [], action) {
    switch (action.type) {
        case 'ADD_SUCCESS':
            return produce(state, draft => {
                const { product } = action

                draft.push(product)
            })
        case 'REMOVE_FROM_CART_SUCCESS':
            return produce(state, draft => {
                const productIndex = draft.findIndex(p => p.skus[0].codigo === action.id)

                if (productIndex >= 0) {
                    draft.splice(productIndex, 1)
                }
            })
        case "UPDATE_AMOUNT_SUCCESS": {
            return produce(state, draft => {
                const { control, amount } = action
                const productIndex = draft.findIndex(p => p.skus[0].codigo === action.id)

                if (productIndex >= 0) {
                    if (control === 'increase') {
                        draft[productIndex].amount = Number(amount) + 1
                    } else if (control === 'decrease') {
                        draft[productIndex].amount = Number(amount) - 1
                    }
                }
            })
        }
        default:
            return state
    }
}

// const productIndex = draft.findIndex(p => p.codigo === action.products.codigo)

// if (productIndex >= 0) {
//     draft[productIndex].amount += 1;
// } else {
//     draft.push({
//         ...action.products,
//         amount: 1,
//         subTotal: '00.00'
//     })
// }
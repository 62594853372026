import SocialButton from ".."
import googleIcon from "../../../assets/img/google.svg"

function Google({ className, userId }) {
  const queryParam = userId ? `?userId=${userId}` : ''
  const loginUrl = `${process.env.REACT_APP_BASE_URL}google/login${queryParam}`
  const icon = <img src={googleIcon} alt="Google" />
  return <SocialButton className={className} clientLoginUrl={loginUrl} icon={icon} buttonName={`Acesse com o Google`} />
}

export default Google

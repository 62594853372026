import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  justify-content: stretch;

  &>div {
    flex: 1;
  }

  .form-input.small input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  label {
    z-index: 999;
  }
`

export const EyeButton = styled.button`
  	max-width: 60px;
    margin-left: -30px;
    z-index: 2;
`

import styled from 'styled-components';

export const GridMarketPlace = styled.div`
  &&& {
  }.content-grid {
    padding: 0;
  }
  .section-banner {
    display: none;
  }
  .section-header {
    margin-top: 1rem;
  }
    
`
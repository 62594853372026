import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar'
import PageLoaderComponent from '../../../components/PageLoader';
import { useAuth } from '../../../contexts/AuthContext';
import ImageUpload from "../../../components/Forms/ImageUpload";

// Services
import api from '../../../services/api';
import { useModal } from '../../../contexts/ModalContext';
import { useLockedModal } from '../../../contexts/LockedModalContext';
import { useCampaign } from '../../../contexts/CampaignContext';
import { UserUpdateModal } from '../../../components/UserUpdateForm';
import { UserUpdateHoldingModal } from '../../../components/UserUpdateHoldingForm';
import { UserUpdatePasswordForm } from '../../../components/UserUpdatePasswordForm';

// Styles
import * as S from "./style"

export default function Activation() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [reg, setReg] = useState();
    const { campaignData } = useCampaign()
    const { userCan, setAvatar } = useAuth()
    const { openModal, closeModal } = useModal();
    const { openModal: openLockedModal, closeModal: closeLocked } = useLockedModal();

    const handleConfirmTerm = useCallback(async () => {
        closeLocked();
        try {
            const response = await api.post(`/activate/${token.replace("token=", '')}`)
            toast.success(response.data.message, {
                theme: "colored"
            });

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            navigate('/home');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data?.message, {
                theme: "colored"
            });
        }
    }, [closeLocked, navigate, token]);

    const handleAvatarUpload = useCallback(async (img) => {
        try {
            const response = await api.patch(`/me/avatar`, { avatarId: img.image.id });
            setAvatar(response.data.avatar.path)
            handleConfirmTerm();
        } catch (e) { }
    }, [handleConfirmTerm, setAvatar]);

    const handleRejectTerm = useCallback(async () => {
        try {
            const response = await api.get(`/activate/${token.replace("token=", '')}/reject`)
            toast.success(response.data.message, {
                theme: "colored"
            });
            closeModal();
            navigate("/");
        } catch (err) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
        }
    }, [closeModal, navigate, token])

    const openImageUploadModal = useCallback(() => {
        closeLocked(() => {
            openLockedModal({
                header: 'Atualize seu avatar',
                size: 'small',
                body: <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload} error={'avatarError'} />
            })
        })
    }, [closeLocked, handleAvatarUpload, openLockedModal])

    const openUpdateUserModal = useCallback(async () => {
        closeModal(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                size: 'large',
                body: <UserUpdateModal onSave={openImageUploadModal} />
            })
        })
    }, [closeModal, openImageUploadModal, openLockedModal]);

    const openUpdateUserLockedModal = useCallback(async () => {
        closeLocked(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                size: 'large',
                body: <UserUpdateModal onSave={openImageUploadModal} />
            })
        })
    }, [closeLocked, openImageUploadModal, openLockedModal]);

    const openUpdateHoldingModal = useCallback(() => {
        if (!userCan("holdings:update")) {
            openUpdateUserModal()
            return
        }
        closeModal(() => {
            openLockedModal({
                header: 'Atualize os dados da sua rede',
                body: <UserUpdateHoldingModal onSave={openUpdateUserLockedModal} />
            })
        })
    }, [userCan, closeModal, openUpdateUserModal, openLockedModal, openUpdateUserLockedModal]);

    const openUpdatePasswordModal = useCallback(() => {
        closeModal(() => {
            openModal({
                header: 'Atualize sua senha',
                body: <UserUpdatePasswordForm onSave={openUpdateHoldingModal} />
            })
        })
    }, [closeModal, openModal, openUpdateHoldingModal]);

    const openAcceptModal = useCallback(() => {
        openModal({
            header: 'Você tem certeza?',
            body: <>
                <p>Você tem certeza que deseja participar da campanha {campaignData.title}?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={() => closeModal()}>Voltar</button>
                    <button className="button secondary" onClick={openUpdatePasswordModal}>Aceitar</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, campaignData, openUpdatePasswordModal, closeModal]);

    const openRejectModal = useCallback(() => {
        openModal({
            header: 'Você tem certeza?',
            body: <>
                <p>Você tem certeza que NÃO deseja participar da campanha {campaignData.title}?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={handleRejectTerm}>Recusar</button>
                    <button className="button secondary" onClick={() => closeModal()}>Voltar</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, campaignData, handleRejectTerm, closeModal]);

    const regulamento = useCallback((content) => {
        if (content.match(/\.pdf$/)) {
            return <S.Iframe src={content} frameborder="0" />
        }

        return <S.SContent dangerouslySetInnerHTML={{ __html: content }}></S.SContent>
    }, [])

    useEffect(() => {
        const Data = async () => {
            try {
                const { data } = await api.get(`regulation`)
                setReg(data)

            } catch (err) {
                toast.error(err.response.data.message, {
                    theme: "colored"
                });
                // navigate("/");
            }
        };
        Data();
    }, [token])

    if (!reg) return <PageLoaderComponent />

    return <S.Container className="landing">
        <PerfectScrollbar >
            <S.Body>
                <div className="text-center mb-5">
                    <h1>Regulamento</h1>
                </div>
                {regulamento(reg.content)}

                <div className="form-row" style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <div className="form-item" >
                        <button className="button medium secondary mt-5" type="text" onClick={openRejectModal}>
                            Recuso o regulamento
                        </button>
                    </div>

                    <div className="form-item" >
                        <button className="button medium secondary mt-5" type="text" onClick={openAcceptModal}>
                            Aceito o regulamento
                        </button>
                    </div>
                </div>
            </S.Body>
        </PerfectScrollbar>
    </S.Container>
}
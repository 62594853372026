import styled from 'styled-components';

export const SubmitButton = styled.button`
  min-width: 168px;
  width: auto;
  margin-top: 0;
  /* float: right; */
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  display: inline-block;
  height: 48px;
  border-radius: 10px;
  color: var(--white);
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62,63,94,.2);
`;

export const FieldWithError = styled.div`
  .form-input.small {
    margin-bottom: 0;
  }
`;

export const SmallError = styled.small`
  color: var(--danger);
`;

export const PasswordForm = styled.form`
  margin-bottom: 28px;
`;

export const PosAvatar = styled.div`
  margin-top: -16%;
`;

export const Grid = styled.div`
  .form-row.split:nth-last-child(1) {
    display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: max(1.5rem, 10%);
      .form-item {
        width: unset;
        button {
          min-width: 168px;
          margin-top: 0;
          line-height: 1rem;
        }
        max-height: 48px;
        margin-bottom: 0;
        margin-right: 0;
      }
  }
  @media (max-width: 768px) {
    .form-row.split:nth-last-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: max(1.5rem, 10%);
      .form-item {
        width: unset;
        button {
          min-width: unset;
          margin-top: 0;
          line-height: 1rem;
        }
        max-height: 48px;
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
`
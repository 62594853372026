import { useCallback, useEffect, useState } from 'react'
import api from '../../../services/api'
import * as S from './style'

function Table({ uri, cargo, stepId, filter = true }) {
  const [name, setName] = useState('')
  const [data, setData] = useState()
  const [select, setSelect] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [params, setParams] = useState()
  const [clusters, setClusters] = useState()
  const [users, setUsers] = useState()

  const loadData = useCallback(async (params) => {
    try {
      const response = await api.get(uri, { params })
      setData(response.data.ranking)
      setSelect(response.data.select)
      setName(response.data.name)
      setClusters(response.data.cluster)
      setUsers(response.data.users)
      setIsLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [uri])

  const handleChangeSelect = useCallback((select) => {
    const newParams = { ...params, select }
    setParams(newParams)
  }, [params])

  const handleChangeCluster = useCallback((cluster) => {
    const newParams = { ...params, cluster }
    setParams(newParams)
  }, [params])
  const handleChangeName = useCallback((userId) => {
    const newParams = { ...params, userId }
    setParams(newParams)
  }, [params])

  useEffect(() => {
    loadData(params)
  }, [loadData, params])

  useEffect(() => {
    setParams(params => ({ ...params, cargo, stepId }))
  }, [stepId, cargo])

  return <S.TableBG>
    {error ? <S.Center>Ocorreu um erro ao tentar carregar o ranking</S.Center> :
      (isLoading ? <S.Center>Carregando...</S.Center> : <><div>
        <h5>{name}</h5>

        {filter && <S.Filter>
          <S.InputSelect onChange={handleChangeSelect}>
            {select.map(s => <option value={s.id}>{s.name}</option>)}
          </S.InputSelect>
          <S.InputSelect onChange={handleChangeCluster}>
            <option value="">CLUSTER</option>
            {clusters && clusters.map((cluster) => <option value={cluster}>CLUSTER {cluster}</option>)}
          </S.InputSelect>
          <S.InputSelect onChange={handleChangeName}>
            <option value="">BUSCAR</option>
            {users && users.map(user => <option value={user.id}>{user.name}</option>)}
          </S.InputSelect>
        </S.Filter>}
      </div>

        <S.TableScroll>
          <S.Table>
            <thead>
              <tr>
                <td>Poisção</td>
                <td>Nome</td>
                <td>Target</td>
                <td>Real</td>
                <td>Pontos</td>
                <td> </td>
              </tr>
            </thead>
            {data && <tbody>
              {data.map(d => {
                if (!d.Result) return <></>
                if (!d.Result[0]) return <></>
                const content = d.Result[0].content
                const key = Object.keys(content).find(key => key.match(/Total/))
                const total = content[key]
                if (!total) return <></>
                return <tr>
                  <td>{d.position}</td>
                  <td>{d.user.name}</td>
                  <td>{total[0].target}</td>
                  <td>{total[0].real}</td>
                  <td>{d.points}</td>
                  <td></td>
                </tr>
              })}
            </tbody>}
          </S.Table>
        </S.TableScroll>
      </>)}

  </S.TableBG>
}

export default Table

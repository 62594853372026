import HexagonComponent from "../Hexagon";
import planet from '../../assets/img/planet.png'
import * as S from "./style";

export default function BannerTitleHome({ avatar, username, position, stars }) {
    return (
        <S.BannerContainer className="section-banner profile-banner">
            <S.AvatarContainer>
                <div className="home-user-info">
                    <div className="left">
                        {avatar && <div className="hexagon-container">
                            <HexagonComponent src={avatar} />
                        </div>}
                        {!avatar && <S.Planet src={planet} alt="Planeta" />}
                        <div className="greeting-user">
                            <h1>Dashboard</h1>
                            <p>Visão analítica dos resultados</p>
                        </div>
                    </div>
                </div>
            </S.AvatarContainer>
        </S.BannerContainer>
    )
}
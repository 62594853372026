import styled from "styled-components";

export const PageLoaderContainer = styled.div`
  .page-loader{
    background: rgb(57,20,92);
    background: linear-gradient(135deg, rgba(57,20,92,1) 0%, rgba(100,23,84,1) 100%);
  }
  img {
    max-width: 15rem;
  }
  .loader-bar {
    background-color: #fff !important;
  }
  .page-loader-decoration {
    margin-bottom: 2rem;
    background-color: transparent;
  }
  .page-loader-info {
    display: none;
  }
`
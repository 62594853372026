export class InvalidPasswordError extends Error {
  constructor(message) {
    super(message)
    this.name = 'InvalidPasswordError'
  }
}

class PasswordValidatorAdapter {
  isValid(password) {
    if (!password.match(/[a-z]/)) throw new InvalidPasswordError('A senha deve conter pelo menos uma letra minúscula')
    if (!password.match(/[A-Z]/)) throw new InvalidPasswordError('A senha deve conter pelo menos uma letra maiúscula')
    if (!password.match(/[0-9]/)) throw new InvalidPasswordError('A senha deve conter pelo menos um número')
    if (!password.match(/[!@#$%&*]/)) throw new InvalidPasswordError('A senha deve conter pelo menos um caractere especial')
    return true
  }
}

export default PasswordValidatorAdapter

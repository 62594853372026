import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../../contexts/AuthContext'
import BannerTitleComponent from '../../components/BannerTitleHome'
import Template from '../../template'
import api from '../../services/api'
import * as S from './styled'

function Table({ header, content, className }) {
  const size = content[0].length
  return <S.Table className={className}>
    <thead><tr><td colSpan={size}>{header}</td></tr></thead>
    <tbody>
      {content.map(row => <tr>{row.map((column, columnIndex) => {
        const isLastColumn = columnIndex === row.length - 1
        const columnSpan = size - row.length
        return <td colSpan={isLastColumn && columnSpan + 1} dangerouslySetInnerHTML={{ __html: column }} ></td>
      })}</tr>)}
    </tbody>
  </S.Table>
}

function Resultado() {
  const [tables, setTables] = useState()
  const { id } = useParams()
  const { user } = useAuth()

  const fetchResult = useCallback(async () => {
    try {
      const response = await api.get(`/results/${id}`)
      setTables(response.data)
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        toast.error('Ocorreu um erro interno. Por favor tente novamente.', { theme: 'colored' })
        return
      }

      switch (error.response.status) {
        case 400:
          if (error.response.data === 'Invalid param: result') {
            setTables({ message: 'Você não possui resultados' })
          }
          break
        default:
          toast.error('Ocorreu um erro interno. Por favor tente novamente.', { theme: 'colored' })
      }
    }
  }, [id])

  useEffect(() => {
    fetchResult()
  }, [fetchResult])

  if (!tables) return <>...</>

  return <Template>
    <S.Margin />
    <BannerTitleComponent
      username={user.name}
      position={"0"}
      stars={"0"}
    />
    {tables.message ? <S.Center>{tables.message}</S.Center> : <S.Container className="wrapper">
      <S.Title>resultados {tables.user.name}</S.Title>

      <S.Wrapper>
        {tables.result && tables.result.map(table => <>
          <Table {...table} />
          {table.caption && <p>{table.caption}</p>}
        </>)}
      </S.Wrapper>
    </S.Container>}
  </Template>
}

export default Resultado

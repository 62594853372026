import React, { useContext, useState, useCallback, useRef } from "react";
import * as S from './styled';

const ModalContext = React.createContext();

export default function LockedModalProvider ({ children }) {
    const [modalState, setModalState] = useState('');
    const [header, setHeader] = useState('');
    const [body, setBody] = useState('');
    const [size, setSize] = useState('small');
    const modalRef = useRef(null);
    const modalCotentRef = useRef(null);

    const closeModal = useCallback((callback) => {
        setModalState('closing');
        if (callback) {
            modalRef.current.addEventListener('animationend', () => {
                callback();
            }, { once: true })
        }
    }, []);

    const openModal = useCallback(({header, body, size}) => {
        setModalState('opening');
        setHeader(header);
        setBody(body);
        setSize(size || "small");
    }, []);

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <S.Container className={modalState} ref={modalRef}>
                <S.Content ref={modalCotentRef} size={size}>
                    <S.Header>{header}</S.Header>
                    <S.Body>{body}</S.Body>
                </S.Content>
            </S.Container>
        </ModalContext.Provider>
    );
};

export function useLockedModal () {
    const context = useContext(ModalContext);

    if (!context) throw new Error("useLockedModal must be used within a LockedMenuProvider");

    const { openModal, closeModal } = context;

    return { openModal, closeModal };
}

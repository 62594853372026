import React from "react";

// Template
import Template from "../../template"

// Components
import BannerTitleComponent from "../../components/BannerTitle";
import PageLoaderComponent from "../../components/PageLoader"

// Image
import BannerTitle from "../../assets/img/banner/badges-icon.png"

// Hook
import { useFetch } from "../../hooks/useFetch"

// Style
import { ImgPreview } from "./style"

export default function Badges () {

    // request API
    const { data } = useFetch('/quest/my-quests?page=1&type=badges')

    if (!data) {
        return <PageLoaderComponent />
    }

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Medalhas"
                subTitle="Todas as medalhas desbloqueadas e bloqueadas disponíveis"
                imgAlt="badges-icon"
            />
            <div className="grid grid-3-3-3-3 top-space centered">
                {data.content.map((item, index) => (
                    <div className={`badge-item-stat ${!item.unlocked && 'blocked'}`} key={index}>
                        <ImgPreview className="badge-item-stat-image-preview" src={item.quest.questRewards[0].value} alt="badge" />
                        <img className="badge-item-stat-image" src={item.quest.questRewards[0].value} alt="badge" />

                        <p className={`badge-item-stat-title ${!item.unlocked && 'blocked'}`}>{item.quest.name}</p>
                        <p className={`badge-item-stat-text ${!item.unlocked && 'blocked'}`}>{item.quest.description}</p>

                        <div className="progress-stat">
                            <div id="badge-bronze" className="progress-stat-bar" style={{ width: "204px", height: "4px", position: "relative" }}>
                                <canvas style={{ position: "absolute", top: "0px", left: "0px" }} width="204" height="4"></canvas>
                                <canvas style={{ position: "absolute", top: "0px", left: "0px" }} width="204" height="4"></canvas>
                            </div>

                            <div className="bar-progress-wrap">
                                <p className={`bar-progress-info negative center ${!item.unlocked && 'blocked'}`}>
                                    {item.max &&
                                        <span className="bar-progress-text">
                                            {item.progress}
                                            <span className="bar-progress-unit">/ {item.max}</span>
                                        </span>
                                    }
                                    {item.unlocked ? 'unlocked' : 'blocked'}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Template>
    )
}
import styled from "styled-components"

export const Graph = styled.div`
  --primary: #615dfa;
  --secondary: #23d2e2;
  aspect-ratio: 1;
  background: conic-gradient(var(--primary) 0%, var(--secondary) ${props => `${props.value}%`}, #e8e8ef ${props => `${props.value}.1%`}, #e8e8ef 100%);
  border-radius: 50%;
  margin: 0 auto;
  max-width: 140px;
  position: relative;
  width: 100%;

  &:after {
    align-items: center;
    aspect-ratio: 1;
    background-color: #ffffff;
    border-radius: 50%;
    content: "${props => `${props.value}%`}";
    display: flex;
    font-family: Rajdhani, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    margin: 8px;
    position: absolute;
    width: calc(100% - 16px);
  }
`

import styled from 'styled-components'

export const Container = styled.a`
  &:hover {
    color: #000;
  }
  
  & > div {
    align-items: center;
    border: 1px solid rgba(15, 15, 15, 0.15);
    display: flex;
    justify-content: center;
    padding: 9px;
    width: 100%;

    & > img {
      margin-right: 6px;
    }
  }
`
import { useCallback, useEffect, useMemo, useState } from 'react'
import InputSelect from '../Forms/InputSelect';
import api from '../../services/api'

function DistributorSelectorComponent ({regionId, value, onChange, ...props}) {
  const [distributors, setDistributors] = useState([]);

  const hasDistributors = useMemo(() => {
    if (!distributors) return false
    return distributors.length !== 0
  }, [distributors])

  const loadDistributors = useCallback(async () => {
    const params = {}
    if (regionId) params.regionId = regionId
    const response = await api.get(`/distributor`, { params })
    setDistributors(response.data)
  }, [regionId])

  const onChangeParse = useCallback((value) => {
    if (!value) return {}
    onChange(JSON.parse(value))
  }, [onChange])

  useEffect(() => {
    loadDistributors()
  }, [loadDistributors])

  return <InputSelect onChange={onChangeParse} label="Função" {...props}>
    <option value="" key="1">Selecione o distribuidor</option>
    {hasDistributors && distributors.map(r => <option key={r.id} value={JSON.stringify(r)} selected={value === r.id}>{r.cnpj} - {r.name}</option>)}
  </InputSelect>
}

export default DistributorSelectorComponent

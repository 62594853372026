import { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";

// Template
import Template from "../../../../../template";

// Services
import api from "../../../../../services/api";

// Imagens
import BannerTitle from "../../../../../assets/img/banner/marketplace-icon.png"

// Components
import BannerTitleComponent from "../../../../../components/BannerTitle"
import InputText from "../../../../../components/Forms/Input";

// Utils
import { currency } from "../../../../../utils/format";
import { useModal } from "../../../../../contexts/ModalContext";

export default function Checkout (props) {
  const inputRef = useRef({cep: '', referencia: '', numero: ''});
  const [address, setAddress] = useState();
  const { openModal } = useModal();

  //pegar os dados dos produtcs selecionados
  const cart = useSelector((state) => state.cart)

  const subtotal = useCallback(() => {
    const valueTotal = cart.reduce((a, b) => a + b.price * b.amount, 0)
    return currency(valueTotal)
  }, [cart])

  const handleSaveAddress = useCallback(async (e) => {
    e.preventDefault();
    try {
      await api.post('/me/address', { ...address });
      toast.success('Os dados foram atualizado com sucesso', { theme: "colored" });
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar conectar com o servidor', { theme: "colored" });
    }
  }, [address]);

  async function handleSearchCEP() {
    let cep = inputRef.current.cep.value
    try {
      const res = await axios.get(`http://viacep.com.br/ws/${cep}/json/ `)
      setAddress(res.data)
    } catch(e) {
      toast.error('Ocorreu um erro, tente novamente !', { theme: "colored" });
    }
  }


  return ( 
    <Template>

    <BannerTitleComponent
      imgUrl={BannerTitle}
      title="Marketplace"
      subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
      imgAlt="marketplace-icon"
    />

    <div className="grid grid-8-4 small-space">
      <div className="grid-column">
        <div className="widget-box">
          <p className="widget-box-title">Detalhes do pagamento</p>

          <div className="widget-box-content">
            <form className="form">
              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Bairro" disabled defaultValue={address && address.bairro} />
                </div>

                <div className="form-item">
                  <InputText label="Cidade" disabled defaultValue={address && address.localidade} />
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Estado" disabled defaultValue={address && address.uf} />
                </div>

                <div className="form-item">
                  <InputText label="Cep" onBlur={handleSearchCEP} ref={el => inputRef.current.cep = el}/>
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Logradouro" disabled defaultValue={address && address.logradouro}  />
                </div>

                <div className="form-item">
                  <InputText label="Complemento" disabled defaultValue={address && address.complemento}  />
                </div>
              </div>


              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Numero" ref={el => inputRef.current.numero = el}/>
                </div>

                <div className="form-item">
                  <InputText label="Referencia" ref={el => inputRef.current.referencia = el} />
                </div>
              </div>

              <button className="button primary" style={{ marginTop: '16px' }} onClick={handleSaveAddress}>Savar endereco</button>
            </form>
          </div>
        </div>
      </div>

      <div className="grid-column">
        <div className="sidebar-box">
          <p className="sidebar-box-title">Resumo do pedido</p>

          <div className="sidebar-box-items">
            <div className="totals-line-list separator-bottom">
              {cart.map((item) => (
                <div className="totals-line">
                  <div className="totals-line-info">
                    <p className="totals-line-title"><span className="bold">{item.displayName}</span></p>
              
                    <p className="totals-line-text">{currency(item.price)} x {item.amount}</p>
                  </div>
              
                  {/* <p className="price-title"><span className="currency"></span> {currency(item.total)}</p> */}
                </div>
              ))}

            </div>

            <div className="totals-line-list separator-bottom">
            <div className="totals-line">
              <p className="totals-line-title">Total do carrinho</p>

              <p className="price-title"><span className="currency"></span> {subtotal()}</p>
            </div>

              <div className="totals-line">
                <p className="totals-line-title">Frete</p>

                <p className="price-title"><span className="currency"></span> 0.00</p>
              </div>

              <div className="totals-line">
                <p className="totals-line-title">Total</p>

                <p className="price-title"><span className="currency"></span> {subtotal()}</p>
              </div>
            </div>

            <p className="price-title big separator-bottom"> {subtotal()} <span className="currency">Moedas</span></p>
          </div>

          <div className="sidebar-box-items">
            <button typ="button" className="button primary" onClick={(e) => {
              e.preventDefault();
              openModal({
                header: "Obrigado",
                body: (<form className="form">
                <div className="form-row">
                  <p>Em breve seu pedido chegará no endereço cadastrado</p>
                </div>
        
                <div className="popup-box-actions medium void">
                  <Link to="/marketplace" className="popup-box-action full button secondary">Continuar resgatando pontos</Link>
                </div>
              </form>)
              })
            }}>Fazer pedido!</button>
          </div>
        </div>
      </div>
    </div>
  </Template >
  )}
import styled from 'styled-components';
import LandingBg from '../../assets/img/landing/landing-background.jpg';

export const ErrorSectionContainer = styled.div`
&&&.error-section {
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem;
  align-items: flex-end;
  flex-direction: column;
  gap: 3rem;
  background: url(${LandingBg}) no-repeat 0 10%, url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUI5MjgxQjYwNkY2MTFFQTk2RkNCQjRCNkQzOTg0QkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUI5MjgxQjcwNkY2MTFFQTk2RkNCQjRCNkQzOTg0QkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxQjkyODFCNDA2RjYxMUVBOTZGQ0JCNEI2RDM5ODRCQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxQjkyODFCNTA2RjYxMUVBOTZGQ0JCNEI2RDM5ODRCQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PotO/tgAAAA5SURBVHjaYjx3/v5/BghgNDRQYKAUMDFQGbCAXEZNAxn///9PVRdS3cujBo4aOGrgqIFD1ECAAAMAzNUHJNFawkUAAAAASUVORK5CYII=) repeat 0 0,#fff;
  background-position: 0 10%;
  background-size: 100% 100%,auto;
  @media (min-width: 1440px) {
    background-size: contain,auto;
    background-position: 0 10%;
  }
  @media (max-width: 1365px) {
    background-size: auto;
    background-position: right;
    padding: 1rem;
  }

}

.error-section-info {
  position: unset;
  min-width: 10rem;
  width: 40%;
  margin-left: 55vmax;

  
  @media (min-width: 1440px) {
    min-width: unset;
    margin-left: 30vmax;
  }

  @media (max-width: 820px) {
    min-width: 15rem;
    margin: 0 auto;
    a {
      display: block;
    }
  }

  @media (max-width: 400px) {
    align-self: center;
    margin-left: 0;
    width: 100%;
    min-width: unset;
    a {
      display: block;
    }
  }

}
.error-section-title {
  position: unset;
  color: white;
  align-self: center;
  margin-left: 52vmax;

  @media(min-width: 1440px) {
    color: #333;
  }

  @media (max-width: 820px) {
    margin: 0 auto;
    align-self: center;
  }
  @media (max-width: 400px) {
    align-self: center;
  }
}
.error-section-subtitle {
  color: white;
  @media(min-width: 1440px) {
    color: #333;
  }
}
.error-section-text {
  color: white;
  @media(min-width: 1440px) {
    color: #222;
  }
}
`

export const BottomDeco = styled.div`
width: 100%;
aspect-ratio: 1;
position: relative;
z-index: -1;
top: 54%;
background: linear-gradient(143deg, #3b248c 15%, #b430be 30%, #731c99 32%, #b430be 34%, #731c99 45%, #b430be 75%, #731c99 85%);
@media (min-width: 950px) {
  display: none;
}
`

export const ErrorPage = styled.div`
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
`
import { useCallback, useEffect, useState } from 'react'
import Template from "../../template";
import PageLoader from '../../components/PageLoader';
import AditivoRegulamentoComponent from '../../components/AditivoRegulamentoComponent';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import * as S from './style'

function Regulamento() {
  const { user } = useAuth();
  const [reg, setReg] = useState()
  const loadReg = useCallback(async () => {
    const reg = await api.get(`/regulation`)
    setReg(reg.data)
  }, [])

  const regulamento = useCallback((content) => {
    if (content.match(/\.pdf$/)) {
      return <S.Iframe src={content} frameborder="0" />
    }

    return <S.SContent dangerouslySetInnerHTML={{ __html: content }}></S.SContent>
  }, [])

  useEffect(() => {
    loadReg()
  }, [loadReg])

  if (!user || !reg) return <PageLoader />

  return <Template banner={false} homeButton={true}>

    <div style={{ marginBottom: '64px' }} ></div>

    <S.RegulamentoContainer className="widget-box wrapper">
      <div className="widget-box-content">

        <h2>REGULAMENTO/PLANO DE OPERAÇÃO DA PROMOÇÃO</h2>

        <h3>Campanha Ganhe o Mundo</h3>

        {regulamento(reg.content)}

      </div>
    </S.RegulamentoContainer>

    {user.channel.team.name === 'Internos' && <S.RegulamentoContainer className="widget-box wrapper">
      <div className="widget-box-content">

        <AditivoRegulamentoComponent />

      </div>
    </S.RegulamentoContainer>}
  </Template>
}

export default Regulamento;

import { Link } from "react-router-dom"

// Template
import Template from "../../template"

// Components
import BannerTitleComponent from "../../components/BannerTitle"

// Image
import BannerTitle from '../../assets/img/banner/streams-icon.png'
import AvatarSocial from '../../assets/img/avatar/01-social.png'
import Avatar31 from '../../assets/img/avatar/31.jpg'
import Avatar04 from '../../assets/img/avatar/04.jpg'

// Style
import { Thumbnail } from "./style"

export default function Treinamentos () {
  return (
    <Template>
      <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Treinamentos"
        subTitle="Treinamentos e cursos"
        imgAlt="streams-icon"
      />

      <div className="section-filters-bar v2">
        <form className="form">
          <div className="form-item split medium">
            <div className="form-select">
              <label for="stream-filter-show">Mostrar</label>
              <select id="stream-filter-show" name="stream_filter_show">
                <option value="0">Todos</option>
                <option value="1">Treinamento</option>
              </select>
              <svg className="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div className="form-select">
              <label for="stream-filter-criteria">Filtrar por</label>
              <select id="stream-filter-criteria" name="stream_filter_criteria">
                <option value="0">Mais recente</option>
                <option value="1">Não realizados</option>
              </select>
              <svg className="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div className="form-select">
              <label for="stream-filter-count">Mostrar</label>
              <select id="stream-filter-count" name="stream_filter_count">
                <option value="0">12 Por página</option>

              </select>
              <svg className="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <button className="button secondary">Buscar</button>
          </div>
        </form>
      </div>

      <div className="grid grid-3-3-3-3 centered">
        <div className="stream-box">
          <div className="stream-box-video">
            <Thumbnail title="Primeiro treinamento" src="https://img.youtube.com/vi/lQV5myhSqCs/hqdefault.jpg"></Thumbnail>
          </div>

          <div className="stream-box-image">
            <figure className="picture tiny circle liquid">
              <img src={AvatarSocial} alt="avatar-01-social" style={{ borderRadius: '50%' }} />
            </figure>
          </div>

          <div className="stream-box-info">
            <p className="stream-box-title"><Link to="/treinamentos/construtor-de-vendas">Marketing & Vendas</Link></p>

            <p className="stream-box-text"><Link to="/treinamentos/construtor-de-vendas" target="_blank">@ManuelNeto</Link></p>

            <p className="stream-box-category">Categoria: <Link to="/treinamentos/construtor-de-vendas" target="_blank">Vendas</Link></p>
          </div>

          <div className="stream-box-meta">
            <a className="user-avatar small no-outline" href="#anchor">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src="img/avatar/01.jpg"></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"></div>
                </div>

                <p className="user-avatar-badge-text">24</p>
              </div>
            </a>
          </div>
        </div>

        <div className="stream-box">
          <div className="stream-box-video">
            <Thumbnail title="Primeiro treinamento" src="https://img.youtube.com/vi/qWSzguf5mZ4/hqdefault.jpg"></Thumbnail>
          </div>

          <div className="stream-box-image">
            <figure className="picture tiny circle liquid">
              <img src={Avatar31} alt="avatar-31" style={{ borderRadius: '50%' }} />
            </figure>
          </div>

          <div className="stream-box-info">
            <p className="stream-box-title"><Link to="/treinamentos/aprenda-a-vender">Aprenda a vender</Link></p>

            <p className="stream-box-text"><Link to="/treinamentos/aprenda-a-vender" target="_blank">@tiagonigro</Link></p>

            <p className="stream-box-category">Categoria: <Link to="/treinamentos/aprenda-a-vender" target="_blank">Vendas</Link></p>
          </div>

          <div className="stream-box-meta">
            <a className="user-avatar small no-outline" href="#anchor">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src="img/avatar/05.jpg"></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"></div>
                </div>

                <p className="user-avatar-badge-text">12</p>
              </div>
            </a>
          </div>
        </div>

        <div className="stream-box">
          <div className="stream-box-video">
            <Thumbnail title="Primeiro treinamento" src="https://img.youtube.com/vi/MQDUUodMfCQ/hqdefault.jpg"></Thumbnail>
          </div>

          <div className="stream-box-image">
            <figure className="picture tiny circle liquid">
              <img src={Avatar04} alt="avatar-04" style={{ borderRadius: '50%' }} />
            </figure>
          </div>

          <div className="stream-box-info">
            <p className="stream-box-title"><Link to="/treinamentos/digital-2021">Marketing Digital: Guia COMPLETO</Link></p>

            <p className="stream-box-text"><Link to="/treinamentos/digital-2021" target="_blank">@RicardoGodoy</Link></p>

            <p className="stream-box-category">Categoria: <Link to="/treinamentos/digital-2021" target="_blank">Estudos</Link></p>
          </div>

          <div className="stream-box-meta">
            <a className="user-avatar small no-outline" href="#anchor">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={Avatar04}></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"></div>
                </div>

                <p className="user-avatar-badge-text">6</p>
              </div>
            </a>
          </div>
        </div>

        <div className="stream-box">
          <div className="stream-box-video">
            <Thumbnail title="Primeiro treinamento" src="https://img.youtube.com/vi/9qdHBXcwayo/hqdefault.jpg"></Thumbnail>
          </div>

          <div className="stream-box-image">
            <figure className="picture tiny circle liquid">
              <img src={Avatar04} alt="avatar-04" style={{ borderRadius: '50%' }} />
            </figure>
          </div>

          <div className="stream-box-info">
            <p className="stream-box-title"><Link to="/treinamentos/100-days">O Processo de Marketing</Link></p>

            <p className="stream-box-text"><Link to="/treinamentos/100-days" target="_blank">@RicardoGodoy</Link></p>

            <p className="stream-box-category">Categoria: <Link to="/treinamentos/100-days" target="_blank">Case</Link></p>
          </div>

          <div className="stream-box-meta">
            <a className="user-avatar small no-outline" href="#anchor">
              <div className="user-avatar-content">
                <div className="hexagon-image-30-32" data-src={Avatar04}></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-40-44"></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-40-44"></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-22-24"></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-16-18"></div>
                </div>

                <p className="user-avatar-badge-text">6</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Template >
  )
}
import ProgressBar from '../ProgressBar'
import * as S from './styled'

function TableCell({ value }) {
  if (typeof value === 'object') {
    return <td><ProgressBar value={value.value} /></td>
  }
  return <td>{value}</td>
}

function DashboardTable({ title, header, content }) {
  return <S.Container>
    <S.Title>{title}</S.Title>
    <S.Table>
      <thead>
        <tr>
          {header.map(h => <th>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {content.map(con => <tr>{
          con.map(c => <TableCell value={c} />)
        }</tr>)}
      </tbody>
    </S.Table>
  </S.Container>
}

export default DashboardTable
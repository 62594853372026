import InputSelect from '../Forms/InputSelect'

function CargoComponent ({...rest}) {
  return <InputSelect {...rest} name="cargo" label="Cargo">
    <option value="">Selecione o cargo</option>
    <option selected={rest.value === 'Analista'} value="Analista">Analista</option>
    <option selected={rest.value === 'Atendimento'} value="Atendimento">Atendimento</option>
    <option selected={rest.value === 'Coordenador'} value="Coordenador">Coordenador</option>
    <option selected={rest.value === 'Coordenador de Merchandising'} value="Coordenador de Merchandising">Coordenador de Merchandising</option>
    <option selected={rest.value === 'Coordenador de Vendas'} value="Coordenador de Vendas">Coordenador de Vendas</option>
    <option selected={rest.value === 'Diretor Regional'} value="Diretor Regional">Diretor Regional</option>
    <option selected={rest.value === 'Estagiário'} value="Estagiário">Estagiário</option>
    <option selected={rest.value === 'Executivo de vendas'} value="Executivo de vendas">Executivo de vendas</option>
    <option selected={rest.value === 'Gerente'} value="Gerente">Gerente </option>
    <option selected={rest.value === 'Gerente de Área'} value="Gerente de Área">Gerente de Área</option>
    <option selected={rest.value === 'Gerente regional'} value="Gerente regional">Gerente regional</option>
    <option selected={rest.value === 'Outros'} value="Outros">Outros</option>
    <option selected={rest.value === 'Promotor'} value="Promotor">Promotor</option>
    <option selected={rest.value === 'Recepcionista'} value="Recepcionista">Recepcionista</option>
    <option selected={rest.value === 'Representante de vendas'} value="Representante de vendas">Representante de vendas</option>
    <option selected={rest.value === 'Sócio'} value="Sócio">Sócio</option>
    <option selected={rest.value === 'Supervisor'} value="Supervisor">Supervisor</option>
    <option selected={rest.value === 'Supervisor de Merchandising'} value="Supervisor de Merchandising">Supervisor de Merchandising</option>
    <option selected={rest.value === 'Supervisor de Vendas'} value="Supervisor de Vendas">Supervisor de Vendas</option>
    <option selected={rest.value === 'Vendedor'} value="Vendedor">Vendedor</option>
  </InputSelect>
}

export default CargoComponent

import TemplateMecanica from '../../template';
import rankingImage from '../../assets/img/ranking_quiz_onda3.png';
import rankingImageMobile from '../../assets/img/ranking_quiz_onda3_mobile.png';
import * as S from './style'

function RankingQuizTang() {
  return <S.Content>
    <TemplateMecanica>
      <div className="grid medium-space">
        <div className="account-hub-content">

          <div className="grid-column">
            <div className="widget-box">
              <div className="content-grid">

              <picture>
                <source srcset={rankingImageMobile} media="(max-width: 768px)" />
                <S.RankingImage src={rankingImage} />
              </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateMecanica>
  </S.Content>
}

export default RankingQuizTang;

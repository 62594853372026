import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./modules/rootReducers"
import rootSaga from "./modules/rootSaga"

const persistConfig = {
    key: 'root',
    storage
}

const sagaMiddleware = createSagaMiddleware()

const enhancer = applyMiddleware(sagaMiddleware)

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, compose(enhancer));

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)


export {store, persistor};
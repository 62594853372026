import styled from "styled-components";
import { Link } from "react-router-dom";

export const Grid = styled.div`
  margin-top: 64px;
  grid-template-areas: "sidebar content";
  grid-template-columns: 3fr 7fr;
  display: grid;
  grid-gap: 16px;
  align-items: start;


  .hexagon-container {
    margin-inline: auto;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 124px;
    height: 124px;
    top: -60px;
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
    background-color: var(--white);
    .shape-outer {
      width: 85%;
      height: 85%;
    }
    span {
      width: 124px;
      height: 124px;
    }
  }
  .user-short-description {
    max-height: 50px;
    padding-top: 0;
    position: relative;
    .user-short-description-avatar {
      position: static;
      top: 0;
      left: 0;
      margin-left: unset;
      .hexagon-with-border {
      }
    }
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1280px) {
    margin-top: 164px;
  }
`;

export const GridFastAcccess = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 3;
  column-gap: 14px;
  row-gap: 14px;

  @media (max-width: 1300px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-columns: 1fr;
  }

`

export const FastLink = styled(Link)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;

  button {
    background-color: #fff;
    text-align: left;
    margin-top: 40px;
    padding: 1.5px 0;
    width: 78px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 1.5rem;
  }
  p {
    margin-right: 100px;
    width: 100%;
    &.product-category-box-text {
      width: 60%;
    }
  }
  && {
    background: linear-gradient(90deg,var(--primary),var(--secondary));
    button {
      span {
        margin-left: 0.5rem;
        font-size: 1rem;
        background: -webkit-linear-gradient(#625efa,#8c79fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
`
import styled from 'styled-components'

export const DropzoneContainer = styled.div`
  border-radius: 12px;
`

export const Button = styled.button`
  margin-top: 16px;
`

export const DateContainer = styled.div`
  font-size: .75rem;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  color: #fff;
  background: var(--primary);
  padding: .5rem;
  border-radius: 0 0 0 .25rem;
`
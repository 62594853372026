import styled from 'styled-components';

export const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const NoticeContainer = styled.div`
  .form-row:nth-child(3) div{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    button.primary {
      margin-top: 0;
    }
  }
`

export const TextAreaContainer = styled.div`
  margin-top: 1rem;
  position: relative;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 1rem;
`;

export const TextAreaLabel = styled.span`
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  padding-inline: 0.5rem;
  background-color: var(--white);
`;

export const TextArea = styled.textarea`
  position: relative;
  top: 1rem;
  left: 0rem;
  z-index: 1;
`;
import { useState, useCallback, useEffect, useMemo } from 'react';
import * as S from './style';

export default function InputTextWithButton(props) {
  const [internalvalue, setInternalValue] = useState();
  const [isActive, setIsActive] = useState(false);
  const { name, id, label, value, onClick, placeholder, readonly, type } = props;

  const isDirty = useMemo(() => {
    if (internalvalue) return true;
    if (isActive) return true;

    return false;
  }, [isActive, internalvalue]);

  const handleValueChange = useCallback((e) => {
    setInternalValue(e.target.value);
  }, []);

  const handleClick = useCallback((e) => {
    onClick(internalvalue);
  }, [onClick, internalvalue]);

  const isSmall = useMemo(() => !props.large, [props]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return <S.Container className={`form-input with-button ${isSmall && 'small'} ${isDirty ? 'active' : ''}`}>
    <label htmlFor={id}>{label}</label>
    <input
      type={type || "text"}
      id={id}
      name={name}
      value={internalvalue || ""}
      onChange={handleValueChange}
      placeholder={placeholder}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      readOnly={readonly}
      maxLength={props.maxLength}
    />
    <S.SearchButton type="button" className="button primary" onClick={handleClick}>
      <svg class="icon-magnifying-glass">
        <use href="#svg-magnifying-glass"></use>
      </svg>
    </S.SearchButton>
  </S.Container>
}
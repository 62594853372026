import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import api from '../../services/api'
import * as S from './style'

function AdminImageDropzone({typeName, onDrop}) {
  const onDropImage = useCallback(async acceptedFiles => {
    let formData = new FormData();
    formData.append("image", acceptedFiles[0]);
    const response = await api.post('/admin-upload', formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (e) => {
        // let progress = Math.round((e.loaded * 100) / e.total);
      },
    });
    onDrop(response.data.image)
  }, [onDrop])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop: onDropImage, accept: { 'image/*': [] } })

  return <S.DropzoneContent {...getRootProps({isDragActive, isDragAccept, isDragReject, maxFiles: 1})}>
      <input {...getInputProps()} />
      <h3>{typeName}</h3>
      {
        isDragActive ?
          <p>Solte aqui...</p> :
          <p>Arraste uma imagem para cá ou clique para selecionar</p>
      }
    </S.DropzoneContent>
}

export default AdminImageDropzone

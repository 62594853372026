import { useCallback, useEffect } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'

function ClientCallback () {
  const { clientName } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { saveLogin } = useAuth()
  const doGoogleLogin = useCallback (async () => {
    try {
      const code = searchParams.get('code')
      const state = searchParams.get('state')
      const response = await api.get(`/${clientName}/callback`, { params: { code, state }})
      
      saveLogin (response)
    } catch (err) {
      if (err.response.data === 'Missing param: state') {
        toast.error('Você não temos uma conta associada a essa conta google', { theme: 'colored' })
        navigate('/')
        return
      }
      toast.error(err.response.data, { theme: 'colored' })
    }
  }, [clientName, navigate, saveLogin, searchParams])
  useEffect (() => {
    doGoogleLogin ()
  }, [doGoogleLogin])
  return <></>
}

export default ClientCallback

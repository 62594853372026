import styled from "styled-components";

export const Preview = styled.div`
  height: 100%;
`;

export const Figure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;

  img {
    height: auto;
  }
`;

export const MarketplaceContainer = styled.div`
  &&& {
    margin-top: 80px;
  }
`
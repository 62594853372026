import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
`

export const ProgressBar = styled.div`
  --primary: #615dfa;
  --secondary: #23d2e2;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: #e7e8ee;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: block;
    border-radius: 3px;
    width: ${props => props.value}%;
    content: "";
    background: var(--primary);
    background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
    transition: width 2s ease;
  }
`
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import InputText from "../Forms/InputText";
import RegionSelectorComponent from "../RegionSelectorComponent";
import DistributorSelectorComponent from "../DistributorSelectorComponent";
import api from "../../services/api";
import RepublicaGetAddress from '../../domain/RepublicaGetAddress'
import { MaskDocument } from '../../utils/mask'
import { useAuth } from '../../contexts/AuthContext'
import { MaskCEP } from '../../utils/mask'
import * as S from './style'

const republicaGetAddress = new RepublicaGetAddress();

export function UserUpdateHoldingModal({ onSave }) {
  const [holding, setHolding] = useState({});
  const [errors, setErrors] = useState({});

  const { userCan } = useAuth()
  const handleSave = useCallback(async (evt) => {
    evt.preventDefault();
    setErrors({})
    try {
      await api.put(`/holding/${holding.id}`, holding);
      onSave();
    } catch (err) {
      if (!err.response) {
        console.log(err);
        return;
      }
      switch (err.response.statuCode) {
        case 403:
          break;
        case 400:
          toast.error(err.response.data, { theme: 'colored' });
          break;
        default:
          toast.error('Ocorreu um erro interno no servidor, por favor tente novamente', { theme: 'colored' });
          break;
      }
    }
  }, [holding, onSave]);

  const loadMe = useCallback(async () => {
    const response = await api.get(`/holding`);
    const { id, name, cnpj, region_id } = response.data;
    setHolding({ id, name, cnpj, regionId: region_id });
  }, []);

  const handleUpdateCep = useCallback(async (cep) => {
    setHolding((holding) => ({ ...holding, cep }))
    if (cep.length === 9) {
      const { uf: estado, cidade, logradouro, bairro } = await republicaGetAddress.getAddress(cep)
      setHolding((holding) => ({ ...holding, estado, cidade, logradouro, bairro }))
    }
  }, [])

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  return <form onSubmit={handleSave}>
    <S.Row>
      <InputText name="name" label="Nome Fantasia" value={holding.name || ""} onChange={(name) => setHolding({ ...holding, name })} />
    </S.Row>
    <S.Row>
      <InputText name="razao-social" label="Razão Social" value={holding.razaoSocial || ""} onChange={(razaoSocial) => setHolding({ ...holding, razaoSocial })} readonly={true} />
    </S.Row>
    <S.Row>
      <InputText name="cnpj" label="CNPJ" value={holding.cnpj || ""} onChange={(cnpj) => setHolding({ ...holding, cnpj })} readonly={true} />
    </S.Row>
    {userCan("holding-addresses:update") && <>
      <S.Row>
        <InputText name="cep" label="CEP" value={holding.cep || ""} onChange={handleUpdateCep} error={errors.cep} mask={MaskCEP} />
      </S.Row>
      <S.Row>
        <InputText name="estado" label="Estado" value={holding.estado || ""} onChange={(estado) => setHolding({ ...holding, estado })} error={errors.estado} />
        <InputText name="cidade" label="Cidade" value={holding.cidade || ""} onChange={(cidade) => setHolding({ ...holding, cidade })} error={errors.cidade} />
      </S.Row>
      <S.Row>
        <InputText name="bairro" label="Bairro" value={holding.bairro || ""} onChange={(bairro) => setHolding({ ...holding, bairro })} error={errors.bairro} />
        <InputText name="logradouro" label="Rua" value={holding.logradouro || ""} onChange={(logradouro) => setHolding({ ...holding, logradouro })} error={errors.logradouro} />
      </S.Row>
      <S.Row>
        <InputText name="numero" label="Numero" value={holding.numero || ""} onChange={(numero) => setHolding({ ...holding, numero })} error={errors.numero} />
        <InputText name="complemento" label="Complemento" value={holding.complemento || ""} onChange={(complemento) => setHolding({ ...holding, complemento })} error={errors.complemento} />
      </S.Row>
      <S.Row>
        <InputText name="responsavel_legal" label="Nome Representante Legal da empresa" value={holding.responsavelLegal || ""} onChange={(responsavelLegal) => setHolding({ ...holding, responsavelLegal })} error={errors.responsavelLegal} />
      </S.Row>
      <S.Row>
        <InputText name="cpf_responsavel_legal" label="CPF do Representante Legal da empresa" value={holding.cpfResponsavelLegal || ""} onChange={(cpfResponsavelLegal) => setHolding({ ...holding, cpfResponsavelLegal })} error={errors.cpfResponsavelLegal} mask={MaskDocument} />
      </S.Row>
    </>}
    <S.Row>
      <RegionSelectorComponent value={holding.regionId} onChange={(region) => setHolding({ ...holding, regionId: region.id })} />
    </S.Row>
    {holding.regionId && <S.Row>
      <DistributorSelectorComponent regionId={holding.regionId} value={holding.distributorId} onChange={(distributor) => setHolding({ ...holding, distributorId: distributor.id })} />
    </S.Row>}

    <button type="submit" className="button secondary">Salvar</button>
  </form>
}
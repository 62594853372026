import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Template from '../../template'
import BannerTitleComponent from '../../components/BannerTitle'
import BannerTitle from '../../assets/img/banner/quests-icon.png'
import SectionHeaderComponent from '../../components/SectionHeaderComponent'
import HoldingSelectorComponent from '../../components/Selectors/HoldingSelectorComponent'
import InputSelect from '../../components/Forms/InputSelect'
import Checkbox from '../../components/Forms/Checkbox'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import * as S from './style'

function PhotoToApprove () {
  const [modalState, setModalState] = useState('')
  const modalRef = useRef(null)
  const modalCotentRef = useRef(null)
  

  const [providers, setProviders] = useState()
  const [crit, setCrit] = useState()
  const [holdings, setHoldings] = useState()
  const [provider, setProvider] = useState()
  const [photos, setPhotos] = useState()
  const [activePhoto, setActivePhoto] = useState()
  const [loadingProviders, setLoadingProviders] = useState()
  const [loadingPhotos, setLoadingPhotos] = useState()
  const [loadingHolding, setLoadingHolding] = useState()

  const [saving, setSaving] = useState(false)

  const { userCan } = useAuth()

  const inputText = useMemo(() => {
    if (loadingProviders) return 'Carregando...'
    if (!providers) return 'Sem lojas para exibir'
    return 'Selecione uma loja'
  }, [loadingProviders, providers])

  const handleHoldingChange = useCallback (async (holding) => {
    setLoadingProviders(true)
    setProviders()
    setPhotos()
    if (holding) {
      const response = await api.get(`/photo-approve/providers/${holding.id}`)
      setProviders(response.data)
    }
    setLoadingProviders(false)
  }, [])

  const handleChangeProvider = useCallback(async (providerData) => {
    setProvider(provider)
    setLoadingPhotos(true)
    setPhotos()
    if (providerData) {
      const provider = JSON.parse(providerData)
      const response = await api.get(`/photo-approve/photos/${provider.id}`)
      setPhotos(response.data)
    }
    setLoadingPhotos(false)
  }, [provider])

  const loadHoldings = useCallback(async () => {
    setLoadingHolding(true)
    const response = await api.get(`/photo-approve/holdings`)
    setHoldings(response.data.holdings)
    setCrit(response.data.crit)
    setLoadingHolding(false)
  }, [])
  
  const handleClickOutside = useCallback((event) => {
    if (modalCotentRef.current && !modalCotentRef.current.contains(event.target)) {
        setModalState('closing');
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [modalCotentRef]);

    const handleOpenModal = useCallback((photo) => {
      setActivePhoto({...photo, stateId: '', selected: []})
      setModalState('opening');
      document.addEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

    const handleCloseModal = useCallback((callback) => {
      setModalState('closing');
      document.removeEventListener("mousedown", handleClickOutside)
      if (callback) {
          modalRef.current.addEventListener('animationend', () => {
              callback();
          }, { once: true })
      }
  }, [handleClickOutside])

  const handleSaveStatus = useCallback(async (activePhoto) => {
    setSaving(true)
    try {
      await api.patch(`/photo-approve/${activePhoto.id}`, activePhoto)
      const newPhotos = photos.filter(p => p.id !== activePhoto.id)
      setPhotos(newPhotos)
      toast.success('O status da foto foi atualizado com sucesso!', { theme: 'colored' })
      handleCloseModal()
    } catch (err) {
      toast.error('Ocorreu um erro, por favor tente novamente mais tarde!', { theme: 'colored' })
    }
    setSaving(false)
  }, [handleCloseModal, photos])

  const handleChangeId = useCallback((isSelected, id) => {
    let selected = activePhoto.selected || []
    if (isSelected) {
      selected.push(id)
    } else {
      selected = selected.filter(s => s !== id)
    }
    console.log(selected)
    setActivePhoto({...activePhoto, selected})
  }, [activePhoto])

  useEffect(()=> {
    loadHoldings()
  }, [loadHoldings])

  return <Template>
    <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Fotos"
        subTitle="Aprove as fotos enviadas!"
        imgAlt="marketplace-icon"
      />
    
    <SectionHeaderComponent pretitle="Aprove de fotos!" title="Fotos aguardando aprovação" />
    
    <S.Container>
      {!loadingHolding && !!holdings?.length &&
      <S.FilterArea>
        <HoldingSelectorComponent holdingList={holdings} onChange={handleHoldingChange} />
        <InputSelect value={provider?.id} onChange={handleChangeProvider}>
          <option value="">{inputText}</option>
          {providers && providers.map(p => <option
            key={p.id}
            value={JSON.stringify(p)}>{p.name}</option>)}
        </InputSelect>
      </S.FilterArea>
      }
      {!loadingHolding && !holdings?.length &&
        "Todas as fotos foram aprovadas"
      }

      <S.PhotoList>
        {loadingPhotos && <p>Carregando fotos...</p>}
        {photos && <>
          {photos.map(p => <S.PhotoContainer onClick={() => handleOpenModal(p)}>
            <S.Photo
              photo={p.filePath}
              type={p.typeName}
              stateName={p.stateName || "Aguardando foto"}
              reason={p.reason}
              providerId={''}
              photoTypeId={p.typeId}
              index={p.index}
              month={p.month}
              createdAt={p.createdAt}
            />
          </S.PhotoContainer>)}
        </>}
      </S.PhotoList>

        <S.ContainerModal className={modalState} ref={modalRef}>
          <S.ContentLarge ref={modalCotentRef}>
            <div className="popup-close-button popup-picture-trigger" onClick={() => handleCloseModal()}>
                <svg class="popup-close-button-icon icon-cross">
                    <use xlinkHref="#svg-cross"></use>
                </svg>
            </div>
            <S.Body>
              {activePhoto && <>
                <S.PhotoModal>
                  <S.PhotoModalText>
                    <h4>{activePhoto.statusName}</h4>
                    <p>{activePhoto.typeName}</p>
                    <br />                    
                    {userCan('photo:approve') && <>
                        {crit && crit.map(c => <Checkbox name={c.id} text={c.name} checked={activePhoto.selected.includes(c.id)} onChange={(val) => handleChangeId(val, c.id)} />)}
                        <br />
                        <InputSelect onChange={(stateId) => setActivePhoto({...activePhoto, stateId})}>
                          <option selected={activePhoto.stateId === ""} value="">Selecione um estado</option>
                          <option selected={activePhoto.stateId === 2} value={2}>Reprovado</option>
                          <option selected={activePhoto.stateId === 4} value={4}>Aprovado</option>
                        </InputSelect>
                        <S.TextAreaContainer>
                          <S.TextAreaLabel>Motivo</S.TextAreaLabel>
                          <S.TextArea name="reason" id="reason" onChange={(evt) => setActivePhoto({...activePhoto, reason: evt.target.value})}></S.TextArea>
                        </S.TextAreaContainer>
                        <button className="button primary" onClick={() => handleSaveStatus(activePhoto)} disabled={saving}>Salvar</button>
                      </>
                    }

                  </S.PhotoModalText>
                  <S.PhotoModalImage>
                    <S.PhotoModalImageContainer>
                      <S.PhotoLarge src={activePhoto.filePath} alt="Foto" />
                    </S.PhotoModalImageContainer>
                  </S.PhotoModalImage>
                </S.PhotoModal>
              </>} 
            </S.Body>
          </S.ContentLarge>
      </S.ContainerModal>
    </S.Container>

  </Template >
}

export default PhotoToApprove

import { useOutletContext } from "react-router-dom";
import ImageUpload from "../../../../components/Forms/ImageUpload";
import PageLoaderComponent from "../../../../components/PageLoader"
import GoogleSocialButton from '../../../../components/SocialButton/Google'
import api from "../../../../services/api";
import * as S from './style'

function ProfileHome() {
  const [user] = useOutletContext();

  if (!user && "active" in user) {
    return <PageLoaderComponent />;
  }

  const handleAvatarUpload = async (img) => {
    try {
      await api.patch(`/me/avatar`, { avatarId: img.image.id });
      const newUser = { ...user, avatarId: img.image.id, avatar: img.image };
      localStorage.setItem('user', JSON.stringify(newUser));
    } catch (e) { }
  }

  return (
    <div className="account-hub-content">

      <S.Container>
        <S.ProfileWidgetBox title="Informações Pessoais">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Email</p>
              <p className="information-line-text">{user.email}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Cargo</p>
              <p className="information-line-text">{user.cargo}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CPF</p>
              <p className="information-line-text">{user?.document}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Telefone</p>
              <p className="information-line-text">{user?.cellphone}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Data de Nascimento</p>
              <p className="information-line-text">{new Date(user?.birthday).toLocaleDateString('pt-BR', {timeZone: "GMT"})}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Cidade</p>
              <p className="information-line-text">{user?.address?.cidade}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CEP</p>
              <p className="information-line-text">{user?.address?.cep}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Logradouro</p>
              <p className="information-line-text">{user?.address?.logradouro}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Número</p>
              <p className="information-line-text">{user?.address?.numero}</p>
            </div>
          </div>

          <GoogleSocialButton userId={user.id} />

        </S.ProfileWidgetBox>

        <S.ProfileWidgetBox>
          <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload}/>
        </S.ProfileWidgetBox>
        {user.holding && <S.ProfileWidgetBox title="Sobre minha holding">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Nome</p>
              <p className="information-line-text">{user.holding.name}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CNPJ</p>
              <p className="information-line-text">{user.holding.cnpj}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Regional</p>
              <p className="information-line-text">{user.holding.regional_name}</p>
            </div>
          </div>
        </S.ProfileWidgetBox>}

        {user.distributor && <S.ProfileWidgetBox title="Sobre meu distribuidor">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Nome</p>
              <p className="information-line-text">{user.distributor.name}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CNPJ</p>
              <p className="information-line-text">{user.distributor.cnpj}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Regional</p>
              <p className="information-line-text">{user.distributor.regional_name}</p>
            </div>
          </div>
        </S.ProfileWidgetBox>}

      </S.Container>
    </div>

  )
}

export default ProfileHome;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

//Component
import { useModal } from "../../../contexts/ModalContext";

// Services
import api from "../../../services/api"

export default function Recovery () {
    const { token } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const { openModal } = useModal();

    useEffect(() => {        
        async function handleConfirmResetPassword (e) {
            e.preventDefault()
            if (newPassword !== confirmPassword) {
                setError('Verifique se as senhas são iguais!')
            } else {
                try {
                    const response = await api.patch(`session/recovery/${token}`, {
                        password: newPassword
                    })
                    toast.success(response.data.message, {
                        theme: "colored"
                    });
    
                    navigate("/");
                } catch (err) {
                    toast.error(err.response.data.message, {
                        theme: "colored"
                    });
                }
            }
        }

        openModal({
            header: "Redefina sua Senha",
            body: (<form className="form">
            <div className="form-row">
                <div className="form-item">
                    <div className="form-input small mb-3">
                        <input
                            type="password"
                            placeholder=""
                            className="form__input__focus"
                            onChange={e => setNewPassword(e.target.value)}
                        />
                        <label className="form__label">senha</label>
                    </div>
                    <div className="form-input small mb-3">
                        <input
                            type="password"
                            placeholder=""
                            className="form__input__focus"
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                        <label className="form__label">confirmar senha</label>
                    </div>
                    <div className="text-center mb-3">
                        {error && <span className="text-danger">{error}</span>}
                    </div>
                    <div className="mb-3">
                        <button
                            className="popup-box-action full button secondary"
                            onClick={(e) => handleConfirmResetPassword(e)}
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </form>),
        })
    }, [confirmPassword, error, navigate, newPassword, openModal, token])

    return (
        <div className="landing" />
    )
}
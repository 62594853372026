import Dropzone from '../../components/Dropzone';
import Template from '../../template';
import * as S from './style';

function AdminUpload({ type }) {
  // Endpoints enabled at backend
  let enableType = {
    results: {
      text: 'Resultados',
      url: 'results/import',
    }
  };

  return (
    <Template>
      <S.Grid className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Upload {enableType[type].text}</p>

              <h2 className="section-title">Envie uma planilha para atualizar</h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Upload {enableType[type].text}</p>
              <div className="widget-box-content">
                <Dropzone apiEndpoint={enableType[type].url} />
              </div>
            </div>
          </div>
        </div>
      </S.Grid>
    </Template>
  )
}

export default AdminUpload;
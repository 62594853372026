import React from "react";
import HexagonComponent from "../Hexagon";
import bannerImage from '../../assets/img/bannerpeople.png'
import * as S from "./style";

/**
 * 
 * @param {Object} props - BannerTitle Component Props
 * @param {"profile" | "default"} props.variant - choose banner variant
 * @param {string} props.imgUrl - given img url for banner icon
 * @param {string} props.imgAlt - alternative text to be at avatar img on banner
 * @param {string} props.avatar - img url to show inside hexagon centered on banner
 * @param {string} props.username - username do display below hexagon
 * @param {string} props.title - text to be at banner title
 * @param {string} props.subTitle - text to be below banner title
 * @example
 * const avatar = userData.avatar || "avatar.png"
 * return (
 *      <BannerTitle
 *          variant="default"  // This is default value. You can omit it.
 *      />
 * )
 * @returns {React.ReactElement}
 */
export default function BannerTitle({ variant = "default", avatar, username, title, subTitle, imgUrl, imgAlt }) {
    let showAvatar, showIcon, showText;
    switch (variant) {
        // Profile Variant
        case "profile":
            showAvatar = true;
            showIcon = false;
            showText = false;
            break;

        // Default Variant
        case "default":
        default:
            showAvatar = false;
            showIcon = true;
            showText = true;
            break;
    }

    return (
        <S.BannerContainer className={`section-banner ${showAvatar ? 'profile-banner' : ''}`} style={{backgroundImage: `url(${bannerImage})`}}>
            <div className="color-overlay"></div>
            {showIcon && (
                <img className="section-banner-icon" src={imgUrl} alt={imgAlt} />
            )}
            {showAvatar && (
                <S.AvatarContainer>
                    <div className="hexagon-container">
                        <HexagonComponent src={avatar} />
                    </div>
                    <p>{username}</p>
                </S.AvatarContainer>
            )}
            {showText && (
                <>
                    <p className="section-banner-title">{title}</p>
                    <p className="section-banner-text">{subTitle}</p>
                </>
            )}
        </S.BannerContainer>
    )
}
import { useCallback, useEffect, useState } from 'react'
import BannerTitleComponent from '../../components/BannerTitleHome2'
import InputSelect from '../../components/Forms/InputSelect'
import Table from './Table'
import DashboardTable from '../../pages/Admin/Dashboard/DashboardTable'
import Template from '../../template'
import api from '../../services/api'
import * as S from './style'
import './style.css'

function Ranking() {
  const [stepId, setStepId] = useState(1)
  const [cargo, setCargo] = useState()
  const [elegiveis, setElegiveis] = useState([])

  const handleChangeCargo = useCallback(async (cargo) => {
    setCargo(cargo)
  }, [])

  const handleChangeStep = useCallback(async (value) => {
    setStepId(value)
  }, [])

  const loadElegiveis = useCallback(async () => {
    try {
      const response = await api.get('/ranking-elegiveis')
      setElegiveis(response.data)
    } catch (err) {
      // .
    }
  }, [])

  useEffect(() => {
    loadElegiveis()
  }, [loadElegiveis])

  return <Template>

    <S.Margin />

    <div className="wrapper">

      <BannerTitleComponent
        username={''}
        position={"0"}
        stars={"0"}
      />

      <div className="grid medium-space">
        <div className="account-hub-content">

          <div className="grid-column">
            <div className="widget-box">
              <S.Wrapper>
                <S.FiltrosCargo>
                  <InputSelect onChange={handleChangeCargo}>
                    <option value="">Cargo</option>
                    <option value="GR">GR</option>
                    <option value="GA">GA</option>
                    <option value="EX">EX</option>
                  </InputSelect>
                  <InputSelect onChange={handleChangeStep}>
                    <option value="">Etapa</option>
                    <option value="1">Etapa 1</option>
                    <option value="2">Etapa 2</option>
                  </InputSelect>
                </S.FiltrosCargo>

                <S.Grid>
                  <div>
                    <S.Title>Clientes</S.Title>

                    <Table cargo={cargo} stepId={stepId} uri="/ranking/clientes/by-channel" />
                    <Table cargo={cargo} stepId={stepId} uri="/ranking/clientes/by-kpi" />
                    {elegiveis.clientes && <S.DTContainer><DashboardTable {...elegiveis.clientes} /></S.DTContainer>}
                  </div>

                  <div>
                    <S.Title>Time interno</S.Title>

                    <Table cargo={cargo} stepId={stepId} uri="/ranking/internos/by-channel" />
                    <Table cargo={cargo} stepId={stepId} uri="/ranking/internos/by-kpi" />
                    {elegiveis.vendas && <S.DTContainer><DashboardTable {...elegiveis.vendas} /></S.DTContainer>}
                  </div>
                </S.Grid>
              </S.Wrapper>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Template>
}

export default Ranking;

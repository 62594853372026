import * as S from './style'

function SectionHeaderComponent ({ pretitle, title }) {
  return <S.Container>
    <S.HeaderInfo>
      <S.PreTitle>{pretitle}</S.PreTitle>
      <S.Title>{title}</S.Title>
    </S.HeaderInfo>
  </S.Container>
}

export default SectionHeaderComponent

import styled from "styled-components";

export const Banner = styled.div`
  position: relative;
  background: var(--primary);
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  max-width: 592px;
  margin: 0 auto;
  border-radius: 20px;

  @media (max-width: 375px) {
    min-height: auto;
    aspect-ratio: 295 / 73;
    width: 100%;
    padding: 0 16px;
  }
`

export const IconContainer = styled.div`
  flex: 0 150px;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    flex: 0 40px;
    margin-right: 8px;
  }
`
  
export const Icon = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;

  @media (max-width: 375px) {
    max-width: 40px;
    display: block;
  }
`

export const BannerTitle = styled.h2`
  color: var(--white);
  font-size: 1.0rem;

  small {
    display: inline-block;
    font-size: .75rem;
    font-weight: bold;
    padding-top: 8px;

    span {
      font-weight: normal;
    }
  }

  @media (max-width: 375px) {
    font-size: 16px;

    small {
      font-size: 14px;
      display: none;
    }
  }
`

export const BannerLink = styled.a`
  flex: 0 150px;
  padding: 20px 32px;
  margin-left: 16px;
  background-color: var(--light);
  font-weight: bold;
  font-size: 1.0rem;
  border-radius: 16px;
  outline: none;
  text-align: center;
  color: var(--primary);
  font-weight: bold;

  &:hover {
    color: var(--secondary);
  }

  &:after {
    content: "Baixe agora!"
  }

  @media (max-width: 905px) {
    position: absolute;
    inset: 0;
    margin: 0;
    overflow: hidden;
    background-color: transparent;
    text-indent: 9999999px;
    min-width: 100%;
    font-size: .75rem;

    &:after {
      display: none;
    }
  }

  @media (max-width: 375px) {
    font-size: 14px;
    padding: 16px 8px;
    flex: initial;
    min-width: 80px;
    display: none;
  }
`
import { useEffect, useState, useCallback } from "react";

import { useFetch } from "../../../../hooks/useFetch";
import InputText from "../../../../components/Forms/InputText";
import ImageUpload from '../../../../components/Forms/ImageUpload';
import PageLoaderComponent from "../../../../components/PageLoader";
import api from "../../../../services/api";
import { useOutletContext } from "react-router-dom";
import { useCampaign } from "../../../../contexts/CampaignContext";

function ProfileEdit() {
  const [user, setUser] = useOutletContext();
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  });
  const { campaignData } = useCampaign();

  const avatarData = JSON.parse(localStorage.getItem('user')).avatar;
  let avatar = campaignData.defaultAvatar;
  if (avatarData) {
    const { path } = avatarData;
    avatar = `${process.env.REACT_APP_BASE_URL}${path}`;
  }
  const { data } = useFetch('/me');

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })

    try {
      await api.put(`/user/${user.id}`, user);
    } catch (e) {

    }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user]);

  const handleAvatarUpload = async (img) => {
    try {
      await api.patch(`/me/avatar`, { avatarId: img.image.id });
      const newUser = { ...user, avatarId: img.image.id, avatar: img.image };
      setUser(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));
    } catch (e) { }
  }

  useEffect(() => {
    setUser(data);
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [data, setUser])

  if (!user) return <PageLoaderComponent />;
  return (<div className="grid medium-space">
<div className="account-hub-content">
  <div className="section-header">
    <div className="section-header-info">
      <p className="section-pretitle">Meu perfil</p>
      <h2 className="section-title">Informação de perfil</h2>
    </div>
  </div>

  <div className="grid-column">
    <div className="grid grid-3-3-3 centered">
      <div className="user-preview small fixed-height">
        <figure className="user-preview-cover liquid " style={{ "background": "url('avatar') center center / cover no-repeat" }}>
          <img src={avatar} alt="cover-01" style={{ "display": "none" }} />
        </figure>

        <div className="user-preview-info">
          <div className="user-short-description small">
            <div className="user-short-description-avatar user-avatar">
              <div className="user-avatar-border">
                <div className="perfect-circle large">
                  <img src={avatar} alt="cover-01" />
                </div>
              </div>

              <div className="user-avatar-content">
                <div className="hexagon-image-68-74" data-src="img/avatar/01.jpg" style={{ width: "68px", height: "74px", position: "relative" }}><canvas width="68" height="74" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
              </div>

              <div className="user-avatar-progress">
                <div className="hexagon-progress-84-92" style={{ width: '84px', height: '92px', position: 'relative', }}><canvas width="84" height="92" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
              </div>

              <div className="user-avatar-progress-border">
                <div className="hexagon-border-84-92" style={{ width: '84px', height: '92px', position: 'relative', }}><canvas width="84" height="92" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
              </div>

              <div className="user-avatar-badge">
                <div className="user-avatar-badge-border">
                  <div className="hexagon-28-32" style={{ width: '28px', height: '32px', position: 'relative', }}><canvas width="28" height="32" style={{ position: 'absolute', top: '0px', left: '0px' }}></canvas></div>
                </div>

                <div className="user-avatar-badge-content">
                  <div className="hexagon-dark-22-24" style={{ width: '22px', height: '24px', position: 'relative' }}><canvas width="22" height="24" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload} />
    </div>

    <div className="widget-box">
      <p className="widget-box-title">Sobre seu perfil</p>

      <div className="widget-box-content">
        <form className="form" action="" method="POST" onSubmit={handleUserSave}>
          <div className="form-row split">
            <div className="form-item">
              <InputText name="name" label="Nome" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
            </div>

            <div className="form-item">
              <InputText name="email" label="Email" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
            </div>
          </div>

          <div className="form-row split">
            <div className="form-item">
              <InputText name="cellphone" label="Telefone" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: value })} />
            </div>
            <div className="form-item">
              <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: value })} />
            </div>
          </div>

          <div className="form-row split">
            <div className="form-item">
              <InputText name="cellphone" label="Telefone" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: value })} />
            </div>

            <div className="form-item">
              <button type="submit" className="button primary add-field-button" style={{ marginTop: '0', float: 'right' }} disabled={button.isDisabled}>{button.name}</button>
            </div>

          </div>

        </form>
      </div>
    </div>


  </div>
</div>
</div>)
}

export default ProfileEdit;
import styled from 'styled-components';

export const BannerContainer = styled.div`
    position: relative;

    .color-overlay {
        position: absolute;
        background: linear-gradient(90deg, #615dfa, #2becfe);
        overflow: hidden;
        height: 100%;
        z-index: 0;
        inset: 0;
        border-radius: 1rem;
        mix-blend-mode: hard-light;
        opacity: .95;
        max-height: 159px;
    }

    &.profile-banner {
        padding: 0;
        background-position: top;
        background-color: var(--white);
        width: 100%;
        height: clamp(240px, 25vw, 280px);
    }
    .section-banner-title, .section-banner-text {
        position: relative;
    }
    `

export const AvatarContainer = styled.div`
    position: relative;
    border-radius: 5px;
    top: 35px;
    left: 0;
    z-index: 20;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        padding-top: clamp(20px, 1vw, 100px);
    }
    .hexagon-container {
        width: clamp(140px, 12.7vw, 180px);
        aspect-ratio: 1;
    }
`

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Tabela = styled.div`
  background-color: var(--white);
  border-radius: 20px;

  button{
    
  }

  tr{
    th{
      padding: 10px;
      &:last-child {
        width: 10px;
      }
    }
    td{
      padding: 10px;
    }
  }
`
export const Wrapper = styled.div`
  padding: 16px;
`;

export const ActionContainer = styled.td`
  display: flex;
`

export const Premmiar = styled.button`
  display: inline-flex;
  background-color: var(--success);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`

export const Edit = styled(Link)`
  display: inline-flex;
  background-color: var(--secondary);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`;

export const Exclude = styled.button`
  display: inline-flex;
  background-color: var(--danger);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--white);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
    margin-right: 16px;
  }
`;

export const Center = styled.p`
  text-align: center;
  margin: 2rem 0;
`

export const DangerButton = styled.button`
  background-color: var(--danger);
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

export const SuccessButton = styled.button`
  background-color: var(--success);
`

import styled from 'styled-components';

export const Thumbnail = styled.img`
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
`;

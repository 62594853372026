import { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';
import ptBR from 'date-fns/locale/pt-BR';
import Template from "../../../template";
import * as S from './style';
import DatePicker from 'react-datepicker';
import { useFetch } from '../../../hooks/useFetch';
import InputText from '../../../components/Forms/InputText';
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify';
import CardQuizEdit from '../../../components/CardQuizEdit';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const A_MONTH_MS = 30 * 24 * 60 * 60 * 1000

function AdminQuiz() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() + A_MONTH_MS));
  const [saving, setSaving] = useState(false);
  const [newQuizTitle, setNewQuizTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const { data } = useFetch('/quiz');
  const { quizId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    const loadQuiz = async () => {
      try {
        const response = await api.get(`/quiz/${quizId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        const quizData = response.data.quiz.shift();
        setNewQuizTitle(quizData.title)
        setStartDate(new Date(quizData.enableAt))
        setEndDate(new Date(quizData.disableAt))
        const parsedQuestions = quizData.questions.map((question) => ({
          ...question,
          alternatives: question.alternatives.map((alternative) => ({
            ...alternative,
            is_correct: alternative.isCorrect
          }))
        }))
        setQuestions(parsedQuestions)
      } catch (err) {
        if (!err.response) {
          toast.error('Ocorreu um erro interno no servidor', { theme: "colored" })
          return;
        }

        toast.error(err.response.data.message, { theme: "colored" })
      }
    }
    if (quizId) {
      loadQuiz()
    }
  }, [quizId, token])

  const handleSelectCorrect = useCallback((questionIndex, alternativeIndex) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const resetAlternatives = newItems[questionIndex].alternatives.map((alternative) => ({ ...alternative, is_correct: false }))
      newItems[questionIndex].alternatives = resetAlternatives;
      newItems[questionIndex].alternatives.splice(alternativeIndex, 1, {
        ...resetAlternatives[alternativeIndex],
        is_correct: true,
      })
      return newItems;
    })
  }, [setQuestions])

  const handleRemoveAlternative = useCallback((questionIndex, alternativeId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const newAlternatives = newItems[questionIndex].alternatives.filter((alternative, index) => index !== alternativeId)
      newItems[questionIndex].alternatives = newAlternatives;
      return newItems;
    })
  }, [setQuestions])

  const handleRemoveQuestion = useCallback((questionIndex) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const newQuestions = newItems.filter((question, index) => index !== questionIndex)
      return newQuestions;
    })
  }, [setQuestions])

  const handleAddQuestion = useCallback(() => {
    const newQuestion = {
      title: "",
      alternatives: [
        {
          title: "",
          is_correct: false,
        },
        {
          title: "",
          is_correct: false,
        },
        {
          title: "",
          is_correct: false,
        },
        {
          title: "",
          is_correct: false,
        }
      ]
    }
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      newItems.push(newQuestion)
      return newItems;
    })
  }, [setQuestions])

  const handleAddAlternatives = useCallback((questionId) => {
    const newAlternative = {
      title: "",
      is_correct: false,
    }

    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      if (newItems[questionIndex].alternatives.indexOf(newAlternative) === -1) {
        newItems[questionIndex].alternatives.push(newAlternative);
      }
      return newItems;
    })
  }, [setQuestions])

  const handleChangeStatement = useCallback((v, questionIndex) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      newItems[questionIndex].title = v;
      return newItems;
    })
  }, [setQuestions])

  const handleChangeAlternativeTxt = useCallback((v, questionIndex, alternativeIndex) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems[questionIndex]
      const alternative = question.alternatives[alternativeIndex]
      alternative.title = v
      question.alternative = alternative;
      newItems[questionIndex] = question;
      return newItems;
    })
  }, [setQuestions])

  const handleSubmitQuiz = async () => {
    setSaving(true);
    try {
      if (quizId) {
        const response = await api.put(`/quiz/${quizId}`, {
          title: newQuizTitle,
          enableAt: startDate,
          disableAt: endDate,
          questions: questions.map((question) => {
            return {
              title: question.title,
              alternatives: question.alternatives.map((alternative) => (
                {
                  title: alternative.title,
                  is_correct: alternative.is_correct
                }))
            }
          })
        })
        setSaving(false)
        if (response.status === 200) {
          toast.success('Quiz editado com sucesso!', { theme: "colored" })
          setQuestions([])
          setNewQuizTitle("")
          setStartDate(new Date())
          setEndDate(new Date(new Date().getTime() + A_MONTH_MS))
          navigate("/admin/quiz")
        }
        return;
      } else {
        const response = await api.post('/quiz', {
          title: newQuizTitle,
          enableAt: startDate,
          disableAt: endDate,
          questions: questions.map((question) => {
            return {
              title: question.title,
              alternatives: question.alternatives.map((alternative) => (
                {
                  title: alternative.title,
                  is_correct: alternative.is_correct
                }))
            }
          })
        })
        setSaving(false)
        if (response.status === 200) {
          toast.success('Quiz enviado com sucesso!', { theme: "colored" })
          setQuestions([])
          setNewQuizTitle("")
          setStartDate(new Date())
          setEndDate(new Date(new Date().getTime() + A_MONTH_MS))
        }
        return;
      }
    } catch (error) {
      setSaving(false)

      if (error.response) {
        toast.error(error.response.data.message || error.response.statusText, {
          theme: "colored"
        });
        return;
      }
      toast.error('Ocorreu um erro no servidor', {
        theme: "colored"
      });
      return;
    }
  }

  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Quiz Admin</p>
            <h2 className="section-title">{quizId ? "Edite um quiz existente" : "Adicione um novo quiz"}</h2>
          </div>
        </div>
        <div className="grid-column">
          <div className="widget-box">
            <div className="widget-box-content">
              <form className="form" action="" method="POST" onSubmit={(e) => { e.preventDefault(); handleSubmitQuiz() }}>
                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Título" value={newQuizTitle} onChange={(v) => setNewQuizTitle(v)} />
                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <S.DatePickerLabel>Data de início</S.DatePickerLabel>
                    <DatePicker
                      locale={ptBR}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy HH:mm"
                      selected={startDate}
                      minDate={new Date()}
                      timeCaption="hora"
                      minTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 17)}
                      maxTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 20)}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="form-item">
                    <S.DatePickerLabel>Data de encerramento</S.DatePickerLabel>
                    <DatePicker
                      locale={ptBR}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy HH:mm"
                      selected={endDate}
                      minDate={new Date()}
                      timeCaption="hora"
                      minTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 17)}
                      maxTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 20)}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <div className="section-header">
                      <div className="section-header-info">
                        <p className="section-pretitle">Questões</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleAddQuestion()}>+ Questão</button>
                  </div>
                </div>
                <hr />
                <div className="form-row split">
                  <div className="form-item">
                    {questions.map((question, questionIndex) => (
                      <S.NewQuestion key={`question-${question.id}`}>
                        <InputText
                          label={`Questão ${questionIndex + 1}`}
                          value={question.title}
                          onChange={(v) => handleChangeStatement(v, questionIndex)} />
                        <S.NewAlternatives>
                          <div className="form-row split">
                            {question.alternatives.map((alternative, alternativeIndex) => (
                              <div key={`question-${questionIndex}-alternative-${alternativeIndex}`} className="form-item">
                                <S.AlternativeBox>
                                  <InputText
                                    label={`Alternativa ${alternativeIndex + 1}`}
                                    value={alternative.title}
                                    onChange={(v) => handleChangeAlternativeTxt(v, questionIndex, alternativeIndex)} />
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => handleSelectCorrect(questionIndex, alternativeIndex)}
                                      className={`${questions[questionIndex].alternatives[alternativeIndex].is_correct ? "correct" : ""}`}
                                    >
                                      CORRETA
                                    </button>
                                    <button
                                      type="button"
                                      onClick={(e) => { e.preventDefault(); handleRemoveAlternative(questionIndex, alternativeIndex) }}
                                      className="danger"
                                    >
                                      X
                                    </button>
                                  </div>
                                </S.AlternativeBox>
                              </div>
                            ))}
                          </div>
                        </S.NewAlternatives>
                        <div className="question-button-container">
                          <button
                            type="button"
                            className="button"
                            onClick={(e) => { e.preventDefault(); handleAddAlternatives(question.id) }}
                          >
                            + Alternativas
                          </button>
                          <button
                            type="button"
                            className="button remove danger"
                            onClick={(e) => { e.preventDefault(); handleRemoveQuestion(questionIndex) }}
                          >
                            X
                          </button>
                        </div>
                        <hr />
                        <br />
                      </S.NewQuestion>
                    ))}

                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <button
                      type="submit"
                      className="button"
                      disabled={saving}
                    >
                      {`Salvar ${quizId ? "Edição" : "Novo"}`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="section-header">
          <div className="section-header-info">
            <h2 className="section-title">Selecione um quiz para editar</h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <div className="widget-box-content">
              {data?.quizzes?.length > 0 && <div className="grid" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                {data?.quizzes?.map(quiz => <CardQuizEdit template="trofeu" key={quiz.id} quiz={quiz}></CardQuizEdit>)}
              </div>}
              {data?.quizzes?.length === 0 && <p style={{ textAlign: "center", marginTop: '64px', fontSize: '1rem' }}><strong>Não há quizzes para edição</strong></p>}
            </div>
          </div>
        </div>

      </div>
    </div>
  </Template>
}

export default AdminQuiz;
import Open from "../../assets/img/quest/openq-b.png"
import Cover from "../../assets/img/quest/cover/01.png"

export default function QuestItem(props) {
  const {
    quest_reward_value,
    quest_name,
    quest_description,
    qp_progress,
    task_percent,
    quest_reward_type_name,
  } = props;

  const progress = qp_progress;

  let reward;
  if (quest_reward_type_name !== 'Medalhas') {
    reward = `${quest_reward_value} ${quest_reward_type_name}`;
  } else {
    reward = 'Medalha';
  }

  return (<div className="quest-item" >
    <figure className="quest-item-cover liquid" style={{ background: "rgba(0, 0, 0, 0) url(&quot;img/quest/cover/01.png&quot;) no-repeat scroll center center / cover" }}>
      <img src={Cover} alt="cover-01" />
    </figure>

    <p className="text-sticker small-text">
      <svg className="text-sticker-icon icon-plus-small">
        <use href="#svg-plus-small"></use>
      </svg>
      {reward}
    </p>

    <div className="quest-item-info">
      <div className="quest-item-badge">
        <img src={Open} alt="openq-b" />
      </div>

      <p className="quest-item-title">{quest_name}</p>

      <p className="quest-item-text">{quest_description}</p>

      <div className="progress-stat">
        <div id="quest-sk" className="progress-stat-bar" style={{ width: "228px", height: "4px", position: "relative" }}>
          {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="228" height="4"></canvas>
              <canvas style="position: absolute; top: 0px; left: 0px;" width="228" height="4"></canvas> */}
        </div>

        <div className="bar-progress-wrap small">
          <p className="bar-progress-info negative start">
            <span className="bar-progress-text no-space">{progress}<span className="bar-progress-unit">/</span>{task_percent}</span>completo
          </p>
        </div>
      </div>

      <div className="quest-item-meta">
        <div className="user-avatar-list">
          <div className="user-avatar micro no-stats">
            <div className="user-avatar-border">
              <div className="hexagon-22-24" style={{ width: "22px", height: "24px", position: "relative" }}>
                {/*<canvas style="position: absolute; top: 0px; left: 0px;" width="22" height="24"></canvas>*/}
              </div>
            </div>

            <div className="user-avatar-content">
              <div className="hexagon-image-18-20" data-src="img/avatar/08.jpg" style={{ width: "18px", height: "20px", position: "relative" }}>
                {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="18" height="20"></canvas>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div >)
}
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import NoticeGroup from './NoticeGroup'
import MobileMenu from './MobileMenu'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useAuth } from '../../contexts/AuthContext'
import logoImage from '../../assets/img/logo.png'
import * as S from "./style"

export default function Header({ logoSize, logo = false }) {
    const [activeSettings, setActiveSettings] = useState(false)
    const [wrapperRef] = useClickOutside(() => setActiveSettings(false))
    const { userCan, doLogout } = useAuth()

    const toggleSettings = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveSettings(!activeSettings)
    }, [activeSettings])

    return (
        <S.HeaderContainer>
            <header className="header">
                <div className="header-actions">
                    <MobileMenu />
                </div>
                {logo && <nav className="header-actions">
                    <div className="header-brand">
                        <div className="logo">
                            <Link to="/home">
                                <S.Logo size={logoSize} src={logoImage} alt="Logo da campanha" />
                            </Link>
                        </div>
                    </div>
                </nav>}
                <div className="header-actions">
                    {userCan('newsfeed:read') && <div className="action-list">
                        <NoticeGroup />
                    </div>}

                    <div className="action-item-wrap">
                        <div className="action-item dark header-settings-dropdown-trigger" onClick={toggleSettings} >
                            <svg className="action-item-icon icon-settings">
                                <use href="#svg-settings"></use>
                            </svg>
                        </div>

                        <S.Dropbox active={activeSettings} className="dropdown-navigation header-settings-dropdown" ref={wrapperRef}>

                            {userCan('users:read') && <Link className="dropdown-navigation-link" to="/admin">Admin</Link>}
                            <Link className="dropdown-navigation-link" to="/faq">Perguntas frequentes</Link>
                            <Link className="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
                            <Link className="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
                            <Link className="dropdown-navigation-link" to="/terms">Termos de Uso</Link>
                            <S.LeaveButton
                                onClick={doLogout}
                                className="dropdown-navigation-button button small secondary"
                                type="button"
                            >
                                <i className="fas fa-door-closed"></i>
                                <span>Sair</span>
                            </S.LeaveButton>
                        </S.Dropbox>
                    </div>
                </div>
            </header>
        </S.HeaderContainer>
    )
}
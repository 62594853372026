import * as S from './style'

function Banner ({ text, link }) {
  return <S.Banner to="">
  <S.IconContainer>
    <S.Icon alt='Download' src="https://cdn-icons-png.flaticon.com/512/724/724933.png" style={{maxWidth: '50px', filter: 'invert(1)'}} />
  </S.IconContainer>

  <S.BannerTitle>{text}</S.BannerTitle>

  <S.BannerLink href={link} download />
</S.Banner>
}

export default Banner

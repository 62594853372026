import styled from "styled-components"

export const Container = styled.div`
  &>div {
    display: flex;
    align-items: center;
  }

  a {
    color: rgb(35, 210, 226);
  }
`

export const Checkbox = styled.input`
  margin-right: .5rem;
  aspect-ratio: 1;
  min-width: 1.25rem;
  @media (max-width: 480px) {
    width: 3.5rem;
  }
`

export const Label = styled.label`
  user-select: none;
`

import GoldMedalBadgePreview from '../../../../assets/img/badge/gold-b.png';
import WarriorMedalBadgePreview from '../../../../assets/img/badge/warrior-b.png';


function ProfileBadges() {
  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Minhas Badges</p>
          <h2 className="section-title">Informação de badges</h2>
        </div>
      </div>

      <div className="grid-column">
        <div class="grid grid-4-4-4 centered">
          {[...Array(2)].map(() => (
            <>
              <div class="badge-item-preview">
                <img class="badge-item-preview-image" src={GoldMedalBadgePreview} alt="badge-gold-b" />

                <div class="badge-item-preview-info">
                  <p class="badge-item-preview-title">Gold User</p>

                  <p class="badge-item-preview-text">Possui mais de 500 post no perfil</p>

                  <p class="badge-item-preview-timestamp">Desbloqueado em 14 Jan. 2022</p>
                </div>
              </div>

              <div class="badge-item-preview">
                <img class="badge-item-preview-image" src={WarriorMedalBadgePreview} alt="badge-warrior-b" />

                <div class="badge-item-preview-info">
                  <p class="badge-item-preview-title">O Guerreiro</p>

                  <p class="badge-item-preview-text">Ajudou o time a se empenhar às regras da comunidade</p>

                  <p class="badge-item-preview-timestamp">Desbloqueado em 26 Ago. 2021</p>
                </div>
              </div>
            </>))}
        </div>
      </div>
    </div>
  )
}

export default ProfileBadges;
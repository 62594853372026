import styled from "styled-components"

export const Container = styled.div`
  max-width: 1240px;
  height: 1vh;
  margin: 0 auto;
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`
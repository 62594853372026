import { useCallback, useEffect, useState } from 'react';
import * as S from './style';
function Cookie ({title, children, siteName, buttonText, onAccept}) {
  const [show, setShow] = useState(true);

  const handleAccept = useCallback(() => {
    const now = Date.now();
    localStorage.setItem(`cookie@${siteName ?? ""}`, now.toString());
    setShow(false);
    if (onAccept) onAccept(now);
  }, [onAccept, siteName]);

  useEffect(() => {
    const acceptedCookie = localStorage.getItem(`cookie@${siteName ?? ""}`);
    if (acceptedCookie) {
      setShow(false);
      return;
    }
  }, [siteName]);

  if(!show) return <></>;

  return <S.AvisoCookiesHolder>
      <S.AvisoCookies>
        <h4>{title ?? "Este website utiliza cookies"}</h4>
        {children ?? <>
          <p>As configurações de cookies neste site são definidas para que possamos dar-lhe a melhor experiência enquanto estiver aqui.</p>
          <p>Se desejar, você pode alterar as configurações de cookies a qualquer momento em seu navegador.</p>
        </>}

        <S.Button onClick={handleAccept}>{buttonText ?? "Ok. Eu entendi!"}</S.Button>
      </S.AvisoCookies>
    </S.AvisoCookiesHolder>
}

export default Cookie;

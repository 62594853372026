import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 999;

  &:before {
    position: absolute;
    inset: 0;
    display: block;
    content: "";
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(4px) grayscale(.5);
    transition: all 1s;
    opacity: 0;
  }

  &.opening {
    pointer-events: all;
    &:before {
      opacity: 1;
    }

    &>div {    
      animation-name: open;
      animation-play-state: running;
      animation-duration: 1.5s;
      top: 0;
      opacity: 1;
    }

  }

  &.closing {
    &:before {
      transition-delay: .5s;
    }

    &>div {
      animation-name: close;
      animation-play-state: running;
      animation-duration: 1s;
      top: -50%;
      opacity: 0;
    }
  }

  @keyframes open {
    0% {
      opacity: 0;
      top: -10%;
    }
    33% {
      opacity: 0;
      top: -10%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 0;
      top: -10%;
    }
  }
`;

const width = {
  small: 'width: 400px;',
  large: 'width: 800px;'
}

export const Content = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 12px;
  padding: 24px 28px;
  background-color: var(--white);
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  animation-duration: 1s;
  top: -50%;
  opacity: 0;
  max-width: 50%;
  ${({size}) => width[size]}
  .popup-close-button {
    background-color: var(--primary);
  }
  .popup-close-button:hover {
    background-color: var(--hover);
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
  .form-row {
    p {
      margin-bottom: 1rem;
    }
  }
`;

export const Header = styled.h4`
  margin-bottom: 16px;
`;

export const Body = styled.div`
  max-height: 65vh;
  overflow-y: auto;
`;

import { GridGroups } from './style';
import groupAvatarExample from '../../../../assets/img/avatar/24.jpg';
import HexagonComponent from '../../../../components/Hexagon';

function ProfileGroups() {

  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Meus grupos</p>
          <h2 className="section-title">Informação de grupos</h2>
        </div>
      </div>

      <div className="section-filters-bar v1">
        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-input small with-button">
              <label for="groups-search">Procurar grupos</label>
              <input type="text" id="groups-search" name="groups_search" />
              <button className="button primary">
                <svg className="icon-magnifying-glass">
                  <use href="#svg-magnifying-glass"></use>
                </svg>
              </button>
            </div>

            <div className="form-select">
              <label for="groups-filter-category">Filtrar por</label>
              <select id="groups-filter-category" name="groups_filter_category">
                <option value="0">Mais recentes</option>
                <option value="1">Mais membros</option>
                <option value="2">Alfabética</option>
              </select>
              <svg className="form-select-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>
          </form>

          <div className="filter-tabs">
            <div className="filter-tab active">
              <p className="filter-tab-text">Mais recentes</p>
            </div>

            <div className="filter-tab">
              <p className="filter-tab-text">Mais membros</p>
            </div>

            <div className="filter-tab">
              <p className="filter-tab-text">Alfabética</p>
            </div>
          </div>
        </div>

        <div className="section-filters-bar-actions">
          <div className="view-actions">
            <a className="view-action text-tooltip-tft-medium active" href="profile-groups.html" data-title="Big Grid">
              <svg className="view-action-icon icon-big-grid-view">
                <use href="#svg-big-grid-view"></use>
              </svg>
            </a>

            <a className="view-action text-tooltip-tft-medium" href="profile-groups-small-grid.html" data-title="Small Grid">
              <svg className="view-action-icon icon-small-grid-view">
                <use href="#svg-small-grid-view"></use>
              </svg>
            </a>

            <a className="view-action text-tooltip-tft-medium" href="profile-groups-list.html" data-title="List">
              <svg className="view-action-icon icon-list-grid-view">
                <use href="#svg-list-grid-view"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <GridGroups>
        {[...Array(5)].map(() => (<div className="user-preview">
          <figure className="user-preview-cover liquid">
          </figure>

          <div className="user-preview-info">
            <div className="tag-sticker">
              <svg className="tag-sticker-icon icon-public">
                <use href="#svg-public"></use>
              </svg>
            </div>

            <div className="user-short-description">
              <a className="user-short-description-avatar user-avatar medium no-stats" href="group-timeline.html">
                <div className="user-avatar-border">
                  <div className="hexagon-120-130"></div>
                </div>

                <div className="user-avatar-content">
                  <HexagonComponent src={groupAvatarExample} className="hexagon-groups" />
                </div>
              </a>

              <p className="user-short-description-title"><a href="group-timeline.html">Cosplayers de Todo o Mundo</a></p>

              <p className="user-short-description-text">Todos os cosplayers são bem-vindos!</p>
            </div>

            <div className="user-stats">
              <div className="user-stat">
                <p className="user-stat-title">139</p>

                <p className="user-stat-text">membros</p>
              </div>

              <div className="user-stat">
                <p className="user-stat-title">105</p>

                <p className="user-stat-text">posts</p>
              </div>

              <div className="user-stat">
                <p className="user-stat-title">7.3k</p>

                <p className="user-stat-text">visitas</p>
              </div>
            </div>

            <div className="user-avatar-list medium reverse centered">
              <div className="user-avatar smaller no-stats">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/03.jpg"></div>
                </div>
              </div>

              <div className="user-avatar smaller no-stats">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/05.jpg"></div>
                </div>
              </div>

              <div className="user-avatar smaller no-stats">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/10.jpg"></div>
                </div>
              </div>

              <div className="user-avatar smaller no-stats">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/02.jpg"></div>
                </div>
              </div>

              <div className="user-avatar smaller no-stats">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/06.jpg"></div>
                </div>
              </div>

              <a className="user-avatar smaller no-stats" href="group-members.html">
                <div className="user-avatar-border">
                  <div className="hexagon-34-36"></div>
                </div>

                <div className="user-avatar-content">
                  <div className="hexagon-image-30-32" data-src="img/avatar/23.jpg"></div>
                </div>

                <div className="user-avatar-overlay">
                  <div className="hexagon-overlay-30-32"></div>
                </div>

                <div className="user-avatar-overlay-content">
                  <p className="user-avatar-overlay-content-text">+132</p>
                </div>
              </a>
            </div>

            <div className="user-preview-actions">
              <p className="button secondary full">
                <svg className="button-icon icon-join-group">
                  <use href="#svg-join-group"></use>
                </svg>
                Entrar no grupo!
              </p>
            </div>
          </div>
        </div>))}

      </GridGroups>
    </div>
  )
}

export default ProfileGroups;
import ErrorComponent from '../ErrorComponent'
import * as S from './styled'

/**
 * Adiciona um checkbox
 * @param (bool) checked
 * @param (string) name
 * @param (ReactNode) text
 */
function Checkbox({ checked, name, label, text, onChange, error }) {
  return <S.Container>
    <div>
      <S.Checkbox
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        value={label}
        onChange={(evt) => onChange(evt.target.checked, evt.target.value)}
      />
      <S.Label htmlFor={name}>{text}</S.Label>
    </div>
    <ErrorComponent>{error}</ErrorComponent>
  </S.Container>
}

export default Checkbox
import api from "../services/api"

class RepublicaGetAddress {
  async getAddress (cep) {
    const clearCep = cep.replace(/\D/, '')
    const request = await api.get(`cep?cep=${clearCep}`)
    return request.data
  }
}

export default RepublicaGetAddress

import styled from 'styled-components'
import InputSelectComponent from '../../../components/Forms/InputSelect'

export const TableBG = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  min-height: 400px;
  height: 1px;
  margin-top: 16px;
  
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`

export const Filter = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;

  h5 {
    font-size: 16px;
  }
`

export const TableScroll = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const Table = styled.table`
  color: #3e3f5e;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 16px;
  width: 100%;

  td {
    padding: 8px 0;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  thead tr td {
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
  }

  tr:nth-child(2) {
    td {
      padding-top: 16px;
    }
  }
`

export const InputSelect = styled(InputSelectComponent)`
  select {
    font-size: 14px;
    padding: 8px;
    height: auto;
    background-color: var(--primary);
    color: var(--white);
  }
`

export const Center = styled.div.attrs(() => {
  return {
    className: 'js-center'
  }
})`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  height: 100%;
  justify-content: center;
  width: 100%;
`
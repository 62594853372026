import { useMemo } from 'react'
import { Link } from 'react-router-dom';
import HexagonComponent from "../../components/Hexagon"
import cover from '../../assets/img/banner-profile.png';
import * as S from './style'

function MemberComponent({ member }) {
  const avatar = useMemo(() => {
    if (!member.avatar) return null;
    return member.avatar.path
  }, [member])

  return <div className="user-preview">
    <figure className="user-preview-cover liquid" style={{ background: `rgba(0, 0, 0, 0) url(${cover}) no-repeat scroll center center / cover` }}>
      <S.ImgPreview src={cover} alt="cover-04" />
    </figure>

    <div className="user-preview-info">
      <div className="user-short-description">
        <p className="user-short-description-avatar user-avatar medium">
          <div className="user-avatar-border">
            <HexagonComponent src={avatar} size="extra-large" />
          </div>
        </p>

        <p className="user-short-description-title">
          <p>{member.name}</p>
        </p>
      </div>

      {/* <div className="badge-list small">
        <div className="badge-item">
          <img src={Img01} alt="badge-silver-s" />
        </div>

        <div className="badge-item">
          <img src={Img02} alt="badge-fcultivator-s" />
        </div>

        <div className="badge-item">
          <img src={Img03} alt="badge-scientist-s" />
        </div>

        <div className="badge-item">
          <img src={Img04} alt="badge-rmachine-s" />
        </div>

        <Link className="badge-item" to="/profile-badges">
          <img src={Img05} alt="badge-blank-s" />
          <p className="badge-item-text">+29</p>
        </Link>
      </div> */}

      <div className="user-preview-actions">
        <a href={avatar} download className="button secondary" disabled={!avatar}>Download</a>

        <Link className="button primary" to={`/admin/notice/${member.id}`}>Enviar Mensagem</Link>
      </div>
    </div>
  </div>
}

export default MemberComponent;

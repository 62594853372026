import { useAuth } from '../../../contexts/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import PageLoaderComponent from '../../../components/PageLoader';
import PostCard from '../../../components/PostCard';
import VideoSectionComponent from '../../../components/VideoSectionComponent'
import Template from '../../../template';
import api from '../../../services/api';
import * as S from './style';

function AdminBlog() {
  const { user, doLogout, userCan } = useAuth();
  const [posts, setPosts] = useState();

  const loadBlog = useCallback(async () => {
    try {
      const response = await api.get(`/post`);
      setPosts(response.data.posts);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: "colored" });
          doLogout();
          return;
        }
      }
    }
  }, [doLogout]);

  useEffect(() => {
    loadBlog();
  }, [loadBlog])
  if (!user || !posts) return <PageLoaderComponent />;


  return <Template banner={false} homeButton={true}>
    <div style={{ marginTop: '4rem' }}></div>

    <VideoSectionComponent />

    <div className="container">
      <S.Grid>

        <S.HubContent className="account-hub-content">

          <S.GridPosts>
            {userCan('post:create') && <S.NewPostContainer className="create-entity-box post-preview">
              <div className="create-entity-box-cover"></div>
              <div className="create-entity-box-avatar">
                <svg className="create-entity-box-avatar-icon icon-group">
                  <use href="#svg-group"></use>
                </svg>
              </div>
              <div className="create-entity-box-info">
                <p className="create-entity-box-title">Criar nova notícia</p>
                <p className="create-entity-box-text">Clique no botão para adicionar nova notícia</p>
                <Link to="/admin/blog/post" className="button primary full popup-manage-group-trigger">Criar nova notícia</Link>
              </div>
            </S.NewPostContainer>}
            {posts.map(post => <PostCard key={post.id} {...post} />)}
          </S.GridPosts>
        </S.HubContent>
      </S.Grid>
    </div>

  </Template>
}

export default AdminBlog;
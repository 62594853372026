import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import RTEditor from '../../../../components/RTEditor'
import ImageDropzone from '../../../../components/AdminImageDropzone'
import InputText from '../../../../components/Forms/InputText'
import Template from '../../../../template/index'
import api from '../../../../services/api'
import * as S from './style'

function NewPost() {
  const [bannerPreviewLink, setBannerPreviewLink] = useState()
  const [message, setMessage] = useState({ tags: [] })
  const navigate = useNavigate()
  const handleChangeBanner = useCallback((banner) => {
    console.log(banner)
    setBannerPreviewLink(banner.path)
    setMessage(message => ({ ...message, bannerId: banner.id }))
  }, [])
  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      await api.post(`/post`, message)
      toast.success('O post foi criado com sucesso!', { theme: 'colored' })
      navigate('/admin/blog')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status !== 500) {
          console.log(error.response.data)
          const [, param] = error.response.data.toString().match(/: (.*)$/);
          if (error.response.data.match(/^Missing param/))
            toast.error(`O ${param} precisa ser informado`, { theme: 'colored' })
          return
        }
      }

      toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' })
    }
  }, [message, navigate])
  return <Template>
    <S.PostOpenContainer className="content-grid full" style={{ marginTop: '150px' }}>
      <article className="post-open">
        <S.BannerContainer className="post-open-cover liquid">
          {bannerPreviewLink && <S.ImagePreview src={bannerPreviewLink} alt="Banner" />}
          <ImageDropzone onDrop={handleChangeBanner} />
        </S.BannerContainer>

        <form action="" method='post' onSubmit={handleSubmit}>
          <S.PostCard className="post-open-body">
            <div className="post-open-heading">
              <p className="post-open-timestamp"><span className="highlighted">{new Date().toLocaleString('pt-BR')}</span></p>
              <h2 className="post-open-title"><InputText value={message.title} onChange={(title) => setMessage({ ...message, title })} /></h2>
            </div>
            <div className="post-open-content">
              <div className="post-open-content-body" style={{ width: '100%' }}>
                <p className="post-open-paragraph">
                  <RTEditor onChange={(content) => setMessage({ ...message, content })} />
                </p>
                <div className="tag-list">
                  {false && [].map(tag => <p className="tag-item secondary">{tag.title}</p>)}
                </div>
                <button className='primary button'>Salvar</button>
              </div>
            </div>
          </S.PostCard>
        </form>
      </article>
    </S.PostOpenContainer>
  </Template>
}

export default NewPost

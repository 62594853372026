import { useMemo, useCallback } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorContainer } from './style';
import ImageUploader from "quill-image-uploader";
import api from '../../services/api';


const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'font', 'size', 'color', 'align',
  'link', 'image', 'background', 'script'
];

Quill.register('modules/imageUploader', ImageUploader);
function RTEditor ({onChange}) {
  const uploadImage = useCallback(async(file) => {
      if (!file) return;
    const formData = new FormData();
    formData.append("image", file);
    const response = await api.post('/upload', formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return `${response.data.image?.path}`
  }, []);

  const modules = useMemo(() => ({
    toolbar: [
        ['image', 'link'],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
      ],
    imageUploader: {
      upload: uploadImage
    }
  }), [uploadImage]);

  return (
    <EditorContainer>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        onChange={onChange}
      />
    </EditorContainer>
  );
};

export default RTEditor;
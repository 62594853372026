import { useState } from 'react'
import InputText from '../InputText'
import * as S from './styled'

function InputPassword({ className, ...rest }) {
  const [passwordType, setPasswordType] = useState('password')

  return <S.InputWrapper className={className}>
    <InputText {...rest} type={passwordType} />
    {
      passwordType === "password" ?
        <S.EyeButton type="button" className='button primary' onClick={() => setPasswordType("text")}>
          <i class="far fa-eye"></i>
        </S.EyeButton> :
        <S.EyeButton type="button" className='button primary' onClick={() => setPasswordType("password")}>
          <i class="far fa-eye-slash"></i>
        </S.EyeButton>
    }
  </S.InputWrapper>
}

export default InputPassword

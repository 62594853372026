import { useCallback, useEffect, useState } from 'react'
import InputSelect from '../Forms/InputSelect'
import api from '../../services/api'

function ChannelComponent({ ...rest }) {
  const [channelsList, setChannelsList] = useState()

  const loadChannelData = useCallback(async () => {
    const response = await api.get('/channels')
    console.log(response);
    setChannelsList(response.data.channels)
  }, [])

  useEffect(() => {
    loadChannelData()
  }, [loadChannelData])

  return <InputSelect {...rest} name="cargo" label="Canal">
    <option value="">Selecione o canal</option>
    {channelsList && channelsList.map(channel => <option selected={rest.value === channel.id} value={channel.id}>{channel.name}</option>)}
  </InputSelect>
}

export default ChannelComponent

import React, { useCallback, useState, useEffect } from "react";
import { Link } from 'react-router-dom'

// Template
import Template from "../../template"

// Component
import BannerTitleComponent from "../../components/BannerTitle"
import PageLoaderComponent from "../../components/PageLoader"

// Image
import BannerTitle from "../../assets/img/banner/marketplace-icon.png"

// Hooks
import { useFetch } from "../../hooks/useFetch"

export default function Category () {
  const [filtered, setFiltered] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [priceFrom, setPriceFrom] = useState(null);
  const [name, setName] = useState(null);
  const [priceTo, setPriceTo] = useState(null);
  const [active, setActive] = useState([]);
  const { data } = useFetch("all-products")

  const handleActive = useCallback((e) => {
    if (e.target.checked) {
      const a = [...active, e.target.value];
      const filtered = data.produtos.filter(p => a.includes(String(p.categoria[0].id)))
      setActive(a);
      setFiltered(filtered);
      if (a.length === 0) {
        setFiltered(data.produtos);
      }
    } else {
      const a = [...active.filter(a => a !== e.target.value)];
      const filtered = data.produtos.filter(p => a.includes(String(p.categoria[0].id)))
      setActive(a);
      setFiltered(filtered);
      if (a.length === 0) {
        setFiltered(data.produtos);
      }
    }
  }, [data, active]);

  const handleFilter = useCallback((e) => {
    let filtered = data.produtos;

    if (priceFrom !== null) {
      filtered = filtered.filter(p => {
        return p.skus[0].preco / 100 > parseFloat(priceFrom);
      });
    }

    if (priceTo !== null && priceTo !== '0') {
      filtered = filtered.filter(p => {
        return p.skus[0].preco / 100 < parseFloat(priceTo);
      });
    }

    setFiltered(filtered);
  }, [data, priceFrom, priceTo]);

  const handleName = useCallback((e) => {
    e.preventDefault();
    if (name !== null) {
      const f = data.produtos.filter(p => {
        return p.displayName.indexOf(name) !== -1;
      });
      setFiltered(f);
    }
  }, [data, name]);

  const orderBy = useCallback((e) => {
    e.preventDefault();
    const a = filtered.sort((p1, p2) => {
      return p1.displayName < p2.displayName ? -1 : 1;
    })

    setFiltered(a);
  }, [filtered]);

  useEffect(() => {
    if (!data) return;
    setFiltered(data.produtos);

    const categorias = data.produtos.map(p => p.categoria[0]).reduce((acc, c) => {
      const a = acc.find(c2 => c2.nome === c.nome);
      if (!a) {
        acc.push(c);
      }
      return acc
    }, []);

    setCategorias(categorias);
  }, [data]);

  if (!data) {
    return <PageLoaderComponent />
  }

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Marketplace"
        subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
        imgAlt="marketplace-icon"
      />

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Buscar produtos</p>

          <h2 className="section-title">Filtro de produtos</h2>
        </div>

        <div className="section-header-actions">
          <Link className="section-header-subsection" to='/marketplace'>Marketplace</Link>

          {/* <p className="section-header-subsection">Digital Items</p> */}
        </div>
      </div>

      <div className="section-filters-bar v4">
        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split">
              <div className="form-input small">
                <label htmlfor="items-search"></label>
                <input type="text" id="items-search" name="items_search" value={name || ""} onChange={(e) => setName(e.target.value)} />
              </div>

              <button className="button primary" onClick={handleName}>
                <svg className="icon-magnifying-glass">
                  <use href="#svg-magnifying-glass"></use>
                </svg>
              </button>
            </div>
          </form>
        </div>

        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split medium">
              <div className="form-select small">
                <label htmlfor="items-filter-order">Organizar por</label>
                <select id="items-filter-order" name="items_filter_order">
                  <option value="0">Decrescente</option>
                  <option value="1">Crescente</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use href="#svg-small-arrow"></use>
                </svg>
              </div>

              <button className="button secondary" onClick={orderBy}>Aplicar filtros</button>
            </div>
          </form>
        </div>
      </div>

      <div className="grid grid-3-9 small-space">
        <div className="marketplace-sidebar">
          <div className="sidebar-box">
            <p className="sidebar-box-title">Categorias</p>

            <div className="sidebar-box-items">
              {categorias.map(c => (<div className="checkbox-line">
                <div className="checkbox-wrap">
                  <input type="checkbox" id={c.id} name="category_all" value={c.id} onChange={handleActive} />
                  <div className="checkbox-box">
                    <svg className="icon-cross">
                      <use href="#svg-cross"></use>
                    </svg>
                  </div>
                  <label for={c.id}>{c.nome}</label>
                </div>

                <p className="checkbox-line-text">&nbsp;</p>
              </div>))}
            </div>

            <p className="sidebar-box-title">Range de preço</p>

            <div className="sidebar-box-items small-space">
              <div className="form-item split">
                <div className="form-input small active always-active currency-decorated">
                  <label htmlfor="price-from">De</label>
                  <input type="text" id="price-from" name="price_from" value={priceFrom | ""} onChange={(e) => setPriceFrom(e.target.value)} />
                </div>

                <div className="form-input small active always-active currency-decorated">
                  <label htmlfor="price-to">Até</label>
                  <input type="text" id="price-to" name="price_to" value={priceTo | ""} onChange={(e) => { setPriceTo(e.target.value) }} />
                </div>
              </div>
            </div>

            <p className="button small primary" onClick={handleFilter}>Aplicar filtro de preço!</p>
          </div>
        </div>

        <div className="marketplace-content">
          <div className="grid grid-3-3-3 centered">
            {filtered.map(p => <div className="product-preview" style={{ height: '100%' }}>
              <Link to={`/product/${p.codigo}`}>
                <figure className="product-preview-image liquid" style={{ background: 'url("https://www.pontofrio-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=' + p.fotoGrande + '") center center / cover no-repeat' }}>
                  <img src={"https://www.pontofrio-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=" + p.fotoGrande + ""} alt="item-01" style={{ display: 'none' }} />
                </figure>
              </Link>

              <div className="product-preview-info">
                <p className="text-sticker"><span className="highlighted"></span>{(p.skus[0].preco / 100).toFixed(2).toString().replace(/\./g, ',')}</p>

                <p className="product-preview-title"><Link to={`/product/${p.codigo}`}>{p.displayName}</Link></p>

              </div>

            </div>)}

          </div>

          <div className="section-pager-bar-wrap align-right">
            <div className="section-pager-bar">
              <div className="section-pager">
                <div className="section-pager-item active">
                  <p className="section-pager-item-text">01</p>
                </div>

                <div className="section-pager-item">
                  <p className="section-pager-item-text">02</p>
                </div>

                <div className="section-pager-item">
                  <p className="section-pager-item-text">03</p>
                </div>

                <div className="section-pager-item">
                  <p className="section-pager-item-text">04</p>
                </div>

                <div className="section-pager-item">
                  <p className="section-pager-item-text">05</p>
                </div>

                <div className="section-pager-item">
                  <p className="section-pager-item-text">06</p>
                </div>
              </div>

              <div className="section-pager-controls">
                <div className="slider-control left disabled">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use href="#svg-small-arrow"></use>
                  </svg>
                </div>

                <div className="slider-control right">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use href="#svg-small-arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template >
  )
}
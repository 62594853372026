import { useCallback, useEffect, useState } from 'react'
import InputSelect from '../Forms/InputSelect'
import api from '../../services/api'

function ChannelComponent({ ...rest }) {
  const [regionsList, setRegionsList] = useState()

  const loadRegionData = useCallback(async () => {
    const response = await api.get('/regions')
    setRegionsList(response.data.regions)
  }, [])

  useEffect(() => {
    loadRegionData()
  }, [loadRegionData])

  return <InputSelect {...rest} name="region" label="Região">
    <option value="">Selecione a região</option>
    {regionsList && regionsList.map(region => <option selected={rest.value === region.id} value={region.id}>{region.name}</option>)}
  </InputSelect>
}

export default ChannelComponent

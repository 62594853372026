import { useEffect, useCallback, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import Template from "../../template"
import { useAuth } from "../../contexts/AuthContext"
import PageLoaderComponent from "../../components/PageLoader"
import FastAccess from "../../components/FastAccess"
import AditivoRegulamentoComponent from "../../components/AditivoRegulamentoComponent"
import { useLockedModal } from '../../contexts/LockedModalContext'
import api from '../../services/api'
import "react-responsive-carousel/lib/styles/carousel.min.css"

export default function Perfil(props) {
  const { user, doLogout } = useAuth();
  const [dashboardData, setDashboardData] = useState({});
  const [setPosts] = useState()
  const { openModal, closeModal } = useLockedModal()

  const loadDashboard = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard`);
      setDashboardData(response.data);
      setPosts(response.data.posts);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: "colored" });
          doLogout();
          return;
        }
      }
    }
  }, [doLogout, setPosts]);

  const isLoaded = useMemo(() => user && dashboardData, [dashboardData, user])

  const handleReject = useCallback(() => {
    doLogout()
  }, [doLogout])

  const handleAccept = useCallback(async () => {
    try {
      await api.post('/aditivo/aceite')
      closeModal()
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar aceitar o aditivo. Por favor tente novamente.', { theme: 'colored' })
    }
  }, [closeModal])

  const handleOpenRegulamento = useCallback(() => {
    openModal({
      header: 'Aditivo de regulamento',
      size: 'large',
      body: <><AditivoRegulamentoComponent />
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
          <button className="button primary" onClick={handleReject}>Rejeitar</button>
          <button className="button primary" onClick={handleAccept}>Aceitar</button>
        </div>
      </>,
    })
  }, [handleAccept, handleReject, openModal])

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard, user.id])

  useEffect(() => {
    if (user.channel.team.name === 'Internos' && !user.aditivo1) {
      openModal({
        body: <>Você tem um aditivo no Regulamento da Campanha - Ganhe o Mundo Israel, e o seu aceite é necessário.
          <button onClick={handleOpenRegulamento} className='button primary'>Ver o regulamento</button></>,
        header: 'Aditivo de regulamento'
      })
    }
  }, [handleOpenRegulamento, openModal, user.aditivo1, user.channel])

  if (!isLoaded) return <PageLoaderComponent />;

  return <Template banner={false} logoSize={1} homeButton={false}>
    <div className="wrapper home">
      <FastAccess />
    </div>
  </Template >
}
import * as S from './style'

function AvisoDePrivacidade() {
  return <S.Container>
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_1.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_2.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_3.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_4.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_5.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_6.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_7.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_8.png" alt="" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/documentos/aviso-de-privacidade/363e2801_9.png" alt="" />
  </S.Container>
}

export default AvisoDePrivacidade
import icone from '../../../assets/img/icons/mundo.png'
import * as S from './styled'

function RankingElement({ position, user, points, id }) {
  return <S.Container>
    <S.Escudo src={icone} alt="Ganhe o mundo" />
    <S.Posicao>{position}</S.Posicao>
    <S.Nome to={`/resultado/${id}`}>{user.name.toUpperCase()}</S.Nome>
    <S.Pontos>{points || 0}</S.Pontos>
    <S.NomeMobile to={`/resultado/${id}`}>{user.name.toUpperCase()}</S.NomeMobile>
  </S.Container>
}

export default RankingElement

import Newsfeed from "../../../Newsfeed";
import * as S from "./style";

function ProfileNewsfeed() {
  return (
    <S.GridNewsfeed>
      <Newsfeed />
    </S.GridNewsfeed>
  );
}

export default ProfileNewsfeed;

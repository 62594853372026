import React, { useState, useEffect, useCallback } from "react";
import api from "../../services/api";

// Template
import Template from "../../template"

// Data
import { useFetch } from "../../hooks/useFetch"

// Component
import PageLoaderComponent from '../../components/PageLoader';
import QuestItem from '../../components/QuestItem'
import Open from "../../assets/img/quest/openq-b.png"

export default function Quests() {
    const [questList, setQuestList] = useState([]);
    const [filter, setFilter] = useState({});
    const { data } = useFetch('quest')

    const fetchData = useCallback(async (params) => {
        const { data } = await api.get('quest/filter', {
            params
        });
        setQuestList(data.content);
    }, []);

    const handleFilter = useCallback(async (e) => {
        e.preventDefault();
        fetchData(filter);
    }, [fetchData, filter])

    useEffect(() => {
        fetchData(null);
    }, [fetchData]);


    if (!data) return <PageLoaderComponent />
    return (
        <Template>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Complete qualquer missão!</p>
                    <h2 className="section-title">Missões Disponíveis</h2>
                </div>
            </div>

            <div className="grid grid-3-3-3-3 centered">
                {data.content.map((q, key) => (<QuestItem key={key} {...q} />))}
            </div>

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Ganhe EXP e suba de nível</p>

                    <h2 className="section-title">Histórico de Missões</h2>
                </div>
            </div>

            <div className="section-filters-bar v2">
                <form className="form">
                    <div className="form-item split medium">
                        <div className="form-select">
                            <label htmlFor="quest-filter-show">Missões</label>
                            <select id="quest-filter-show" name="quest_filter_show" model={filter.quest_status} onChange={(e) => setFilter({ ...filter, quest_status: e.target.value })}>
                                <option value="Todas">Todas as missões</option>
                                <option value="Completas">Missões Completas</option>
                                <option value="Incompletas">Missões Incompletas</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select">
                            <label htmlFor="quest-filter-criteria">Filtrar por</label>
                            <select id="quest-filter-criteria" name="quest_filter_criteria" model={filter.quest_reward} onChange={(e) => setFilter({ ...filter, quest_reward: e.target.value })}>
                                <option value="Todas">Todas as missões</option>
                                <option value="Moedas">Missões de Moedas</option>
                                <option value="Experiência">Missões de EXP</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select">
                            <label htmlFor="quest-filter-order">Ordenador por</label>
                            <select id="quest-filter-order" name="quest_filter_order" model={filter.order} onChange={(e) => setFilter({ ...filter, order: e.target.value })}>
                                <option value="new">Mais recente</option>
                                <option value="old">Mais antiga</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <button className="button secondary" onClick={handleFilter}>Filtrar Missão</button>
                    </div>
                </form>
            </div>

            {questList ? <div className="table table-quests split-rows">
                <div className="table-header">
                    <div className="table-header-column">
                        <p className="table-header-title">Missão</p>
                    </div>

                    <div className="table-header-column">
                        <p className="table-header-title">Descrição</p>
                    </div>

                    <div className="table-header-column centered padded-big-left">
                        <p className="table-header-title">Prêmio</p>
                    </div>

                    <div className="table-header-column padded-big-left">
                        <p className="table-header-title">Progresso</p>
                    </div>
                </div>
                {questList.map((m, key) => <div className="table-body same-color-rows" key={key}>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <img className="table-image" src={Open} alt="openq-s" />

                                <p className="table-title">{m.quest_name}</p>
                            </div>
                        </div>

                        <div className="table-column">
                            <p className="table-text">{m.quest_description}</p>
                        </div>

                        <div className="table-column centered padded-big-left">
                            <p className="text-sticker void">
                                <svg className="text-sticker-icon icon-plus-small">
                                    <use href="#svg-plus-small"></use>
                                </svg>
                                {m.quest_reward_type_name !== 'Medalhas' ? `${m.quest_reward_value} ${m.quest_reward_type_name}` : "Medalha"}
                            </p>
                        </div>

                        <div className="table-column padded-big-left">
                            <div className="progress-stat-wrap">
                                <div className="progress-stat">
                                    <div id="quest-line-sm" className="progress-stat-bar" style={{ width: "200px", height: "4px", position: "relative" }}>
                                        {/*<canvas style="position: absolute; top: 0px; left: 0px;" width="200" height="4"></canvas>
                                            <canvas style="position: absolute; top: 0px; left: 0px;" width="200" height="4"></canvas> */}
                                    </div>

                                    <div className="bar-progress-wrap">
                                        <p className="bar-progress-info medium negative">
                                            <span className="bar-progress-text no-space">{m.qp_progress}<span className="bar-progress-unit">/</span>{m.task_percent}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
                :
                <div className="loader-bars">
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                </div>}
        </Template>
    )
}
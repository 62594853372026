import styled from 'styled-components';

export const OrdenedList = styled.ol`
  counter-reset: list;
`;

export const ListItem = styled.li`
  :before {
    display: inline;
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
  }
`;

export const WidgetBox = styled.div`
  p.widget-box-title {
    font-size: 1.5rem;
  }
  @media(max-width: 480px) {
    p.widget-box-title {
      font-size: 1.25rem;
    }
    .simple-accordion-title {
      font-size: 1.15rem !important;
    }
    .simple-accordion-text {
      font-size: 1rem !important;
    }
  }
`
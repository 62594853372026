import styled from "styled-components"
import { Link } from "react-router-dom"

export const GridFastAcccess = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;

  @media (max-width: 862px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 434px) {
    grid-template-columns: 1fr;
  }
`

export const FastLink = styled(Link).attrs(() => {
  return {
    className: 'product-category-box category-all'
  }
})`
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-top: 35px;
  position: relative;
  &:before {
    background-image: url(${({ img }) => img});
    background-size: ${({ size }) => size || "60px"};
    background-position: 15px 10px;
    background-repeat: no-repeat;
    content: "";
    display: block;
    position: absolute;
    inset: 0;
  }
  &.product-category-box.category-all {
    position: relative;
    backdrop-filter: blur(8px);
    background: linear-gradient(90deg,#7d568f80,#643b7780);
    box-shadow: 2px 2px 2px #00000080;
    height: 120px;
  }
  button {
  span {
      background: -webkit-linear-gradient(#5c1e79,#3c144f);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  button {
  span {
      background: -webkit-linear-gradient(#5c1e79,#3c144f);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  }

  img {
    z-index: 0;
  }
  button {
    align-items: center;
    background-color: #fff;
    bottom: 16px;
    display: inline-flex;
    justify-content: flex-start;
    min-height: 24px;
    padding: 0 16px;
    position: absolute;
    text-align: left;
    width: auto;
    
    span {
      font-size: 1rem;
    }
  }
  p {
    position: relative;
    z-index: 20;
    &.product-category-box-text {
      margin-right: 5rem;
    }
  }
`

import { Link } from 'react-router-dom'
import HeaderComponent from '../components/Header'
import FooterComponent from '../components/FooterComponent'
import * as S from './styled'

export default function Template({ children, className, logoSize = 0, homeButton = true, firstBlock = true }) {
    return <S.TemplateContainer first={firstBlock}>
        <HeaderComponent logoSize={logoSize} />

        <S.Container className={className}>
            {homeButton && <div className="wrapper menu-button">
                <S.LeaveButton>
                    <Link to="/home" className="button secondary">Menu</Link>
                </S.LeaveButton>
            </div>}

            {children}
        </S.Container>

        <FooterComponent />
    </S.TemplateContainer>
}
import * as S from './styled'

function AditivoRegulamentoComponent() {
  return <S.Container>
    <h2>PRIMEIRO ADITIVO AO REGULAMENTO - CAMPANHA GANHE O MUNDO </h2>
    <h2>TIME DE VENDAS</h2>
    <p><strong>MONDELEZ BRASIL LTDA.,</strong> empresa com sede na Av. Juscelino Kubitschek de Oliveira, 13300 - Cidade Industrial de Curitiba, Estado do Paraná, inscrita no CNPJ/MF sob o n.º 33.033.028/0001-84; e, <strong>MONDELEZ BRASIL NORTE E NORDESTE LTDA.,</strong> com sede na Rodovia Luiz Gonzaga, BR 232, Km 51, S/N, Vitória de Santo Antão, Pernambuco, inscrita no CNPJ/MF sob o n.º 10.144.076/0001-44, em conjunto denominadas indistintamente “MDLZ”, </p>
    <h3>CONSIDERANDO QUE:</h3>
    <p>i) A MDLZ é desenvolvedora da Campanha Ganhe o Mundo (“Programa”), cujo prazo de vigência é de 1º/04/2023 até 30/09/2023;</p>
    <p>ii) O Programa consiste na mecânica, descrita no item “H” do Preâmbulo do Regulamento (Anexo I), que visa premiar os empregados participantes do Programa que atingirem as metas previstas no Anexo I, durante o período pré-estabelecido no item “F” do preâmbulo e conforme demais critérios constantes no Regulamento;</p>
    <p>iii) A MDLZ se reserva o direito de, caso aconteça alguma falha nos sistemas operacionais do Programa, alterar a forma de apuração das metas e performance dos participantes, sem qualquer prejuízo aos empregados, mediante comunicação aos participantes imediatamente por e-mail, nos termos da Cláusula 7.1;</p>
    <p>A MDLZ decide promover, pelo presente Primeiro Aditivo ao Regulamento (“Aditivo”), as seguintes alterações ao Regulamento do Programa, de acordo com as condições abaixo:</p>
    <p>1. A MDLZ resolve alterar a mecânica de premiação do Programa indicada no Anexo I do Regulamento referente, exclusivamente, aos participantes classificados como <strong>“Vendedores/Executivos GKA”</strong>, que retroagirá à data do início da vigência do Programa, ou seja, a partir de 1º/04/2023. </p>
    <p>1.1.	 A fim de facilitar a compreensão, a MDLZ consolida o Anexo I no presente Aditivo já contemplando a devida alteração na mecânica dos participantes classificados como “Vendedores/Executivos GKA”, que passará a valer em substituição ao Anexo I do Regulamento para todos os efeitos e fins de direito.</p>
    <p>3. Permanecem em pleno vigor todas as demais cláusulas e condições do Regulamento que não foram alteradas pelo presente Aditivo, em especial a mecânica de premiação dos demais participantes. </p>
    <p>4. Todas as deliberações objeto deste Aditivo retroagem à data do início da campanha, ou seja, a 1º/04/2023. </p>
    <p>5. Os Participantes declaram, desde já, que estão cientes e de acordo com as alterações decorrentes deste Aditivo, formalizando por meio do preenchimento do Termo de Adesão contido no “Anexo II”.</p>
    <h3>CONSOLIDAÇÃO DO ANEXO I - MECÂNICA DA PREMIAÇÃO, PRÊMIOS E PÚBLICO ALVO</h3>
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image001.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image002.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image003.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image004.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image005.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image006.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image007.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image008.png" alt="" />
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image009.png" alt="" />
    <h3>PREMIAÇÃO:</h3>
    <img src="https://img.casadebraganca.com/ganhe-o-mundo/aditivo-1-v2/image010.jpg" alt="" />
    <h2>ANEXO II - TERMO DE ADESÃO AO PRIMEIRO ADITIVO AO REGULAMENTO - CAMPANHA GANHE O MUNDO</h2>
    <p>Por este termo, o empregado da Mondelez, declara para os devidos fins que concorda com o Primeiro Aditivo ao Regulamento do Programa (“Aditivo”), que ocorrerá no período estipulado no item F do preâmbulo do Regulamento, promovida pela Mondelez nos termos do regulamento do Programa que foi submetido pela Mondelez ao Participante. [DE 01/04/2023 À 30/09/2023]. </p>
    <p>Nesse sentido, ao assinar e aderir o presente Programa, declara o Participante que: </p>
    <p>a) Conhece e está integralmente de acordo com o regulamento e premissas do Programa, bem como com mecânica alterada por meio do Aditivo;</p>
    <p>b) Concorda plenamente com as regras e critérios de avaliação destacados no Regulamento do Programa, ficando acordado que, caso o empregado não se enquadre ou deixem de cumprir as regras do Programa, deixará de participar do mesmo;</p>
    <p>c) Reconhece que o Programa busca incentivar o aumento no volume de vendas, mas que não garante nenhuma obtenção de sucesso em seu negócio;</p>
    <p>d) A Mondelez decidirá exclusivamente sobre as metas a serem atingidas, bem como fará a apuração dos resultados, podendo pelo ato de liberalidade alterar a mecânica de apuração comunicando previamente ao início de cada Etapa via e-mail.</p>
  </S.Container>
}

export default AditivoRegulamentoComponent
import React, { useContext, useState } from "react"
import * as S from './style'

const DesktopMenuContext = React.createContext()

export default function DesktopMenuProvider({ children }) {
    const [open, setOpen] = useState(false)

    const menuItens = [
        {
            name: "Home",
            path: "/home",
            permission: "",
            children: <svg className="menu-item-link-icon icon-info">
                <use href="#svg-info"></use>
            </svg>
        },
        {
            name: "Membros",
            path: "/members",
            permission: "users:read",
            children: <svg className="menu-item-link-icon icon-group">
                <use href="#svg-group"></use>
            </svg>
        },
        {
            name: "Canal de notícias",
            path: "/perfil/newsfeed",
            permission: "",
            children: <svg className="menu-item-link-icon icon-newsfeed">
                <use href="#svg-newsfeed"></use>
            </svg>
        },
        {
            name: "Resultados",
            path: "/resultados",
            permission: "read:result",
            children: <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
            </svg>
        },
        {
            name: "Ranking",
            path: "/ranking",
            permission: "read:ranking",
            children: <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
            </svg>
        },
        {
            name: "Jogada extra",
            path: "/quiz",
            permission: "read:quiz",
            children: <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
            </svg>
        },
        {
            name: "Ranking do Quiz",
            path: "/ranking-quiz",
            permission: "read:ranking-quiz",
            children: <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
            </svg>
        },
        {
            name: "KPI",
            path: "/quests",
            permission: "read:quest",
            children: <svg className="menu-item-link-icon icon-quests">
                <use href="#svg-quests"></use>
            </svg>
        },
        {
            name: "Mecânicas",
            path: "/mecanicas",
            permission: "read:mecanicas",
            children: <S.AwesomeMenuIconSmall>
                <i className="fas fa-cog"></i>
            </S.AwesomeMenuIconSmall>
        },
        {
            name: "Troca de pontos",
            path: "/marketplace",
            permission: "read:troca-pontos",
            children: <svg className="menu-item-link-icon icon-marketplace">
                <use href="#svg-marketplace"></use>
            </svg>
        },
    ]

    return (
        <DesktopMenuContext.Provider value={{ open, setOpen, menuItens }}>
            {children}
        </DesktopMenuContext.Provider>
    )
}

export function useMenu() {
    const context = useContext(DesktopMenuContext)

    if (!context) throw new Error("useMenu must be used within a DesktopMenuProvider")

    const { open, setOpen, menuItens } = context

    return { open, setOpen, menuItens }
}

import useSWR from 'swr'
import api from '../services/api';

export function useFetch(url) {
    const { data, error, mutate } = useSWR(url, async url => {
        const response = await api.get(url);

        return response.data;
    }, { refreshInterval: 60000 })

    return { data, error, mutate }
}
import * as S from './styled'

function SocialButton({ className, clientLoginUrl, icon, buttonName }) {
  return <S.Container className={className} href={clientLoginUrl}>
    <div>
      {icon}
      {buttonName}
    </div>
  </S.Container>
}

export default SocialButton

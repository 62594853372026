import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import ligaLogo from '../../assets/img/icons/mundo.png'
import * as S from './style'

function FooterComponent() {
  const { userCan } = useAuth()
  return <S.Footer>
    <img src={ligaLogo} alt="logo liga gigantes da execução" />
    <div className="footer-links">
      <a href="https://privacyportal-de.onetrust.com/webform/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/42251e9d-f6be-4ae0-971c-f311d8047e5a" target="_blank" rel="noreferrer">Requerimentos sobre Privacidade de Dados</a>
      <Link to="/aviso-de-privacidade" target="_blank" rel="noreferrer">Aviso de Privacidade da Mondelez</Link>
      <Link to="/regulamento">Regulamento</Link>
      <Link to="/terms">Termos de uso</Link>
    </div>

    {userCan('legal-text:read') && <S.LegalText>
      Consulte os Produtos Participantes, Regras e Formas de Participação, Prêmios e Regulamento aqui.
      CERTIFICADO DE AUTORIZAÇÃO SRE/ME Nº 03.025987/2023. Período de participação: de 03/04/2023 a 30/09/2023.
      Imagens meramente ilustrativas.
    </S.LegalText>}
  </S.Footer>
}

export default FooterComponent

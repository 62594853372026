import styled from "styled-components";

export const Container = styled.div`
    margin-top: 64px;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
`

export const HeaderInfo = styled.div``

export const PreTitle = styled.p`
    color: #8f91ac;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
`

export const Title = styled.h2`
    margin-top: .5rem;
    font-size: 1.625rem;
    font-weight: 700;
`

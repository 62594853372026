import * as S from './styled'

function ProgressBar({ value }) {
  return <S.Container>
    <S.ProgressBar value={value} />
    {value}%
  </S.Container>
}

export default ProgressBar

import { useCallback, useEffect, useMemo, useState } from 'react'
import InputSelect from '../Forms/InputSelect';
import api from '../../services/api'

function RoleSelectorComponent ({value, onChange, ...props}) {
  const [regions, setRegions] = useState([])

  const hasRegion = useMemo(() => {
    if (!regions) return false
    return regions.length > 0
  }, [regions])

  const loadRegions = useCallback(async () => {
    const response = await api.get(`/region`)
    setRegions(response.data)
  }, [])

  const onChangeParse = useCallback((value) => {
    if (!value) return onChange({})
    onChange(JSON.parse(value))
  }, [onChange])

  useEffect(() => {
    loadRegions()
  }, [loadRegions])

  return <InputSelect onChange={onChangeParse} label="Regional" {...props}>
    <option value="" key="1">Selecione a regional</option>
    {hasRegion && regions.map(r => <option key={r.id} value={JSON.stringify(r)} selected={value === r.id}>{r.name}</option>)}
  </InputSelect>
}

export default RoleSelectorComponent

import { useMemo } from 'react';

export default function PaginationComponent ({currentPage, totalPages, onChagePage}) {
  const pages = useMemo(() => {
    if (currentPage <= 3)
      return new Array(5).fill(0).map((_, key) => (key + 1));
    
    if (currentPage >= totalPages - 3)
      return new Array(5).fill(0).map((_, key) => (totalPages - 5 + key));

    return new Array(5).fill(0).map((_, key) => (currentPage - 2 + key));;
  }, [currentPage, totalPages]);
  
  if (totalPages === 1) return <></>;

  return <div className="section-pager-bar">
      <div className="section-pager">
        
        {pages.map(page => <div className={`section-pager-item ${page === currentPage ? 'active' : ''}`}>
          <p className="section-pager-item-text" onClick={() => onChagePage(page)}>{page}</p>
        </div>)}
        
      </div>

      <div className="section-pager-controls">
        <div className={`slider-control left ${currentPage === 1 && 'disabled'}`} onClick={() => onChagePage(1)}>
          <svg className="slider-control-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>

        <div className={`slider-control right ${currentPage === totalPages && 'disabled'}`} onClick={() => onChagePage(totalPages)}>
          <svg className="slider-control-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>
      </div>
    </div>
}
function WidgetBox({ title, children, className }) {
  return (
    <div className={`widget-box${className ? ` ${className}` : ''}`}>
      <p className="widget-box-title">{title}</p>

      <div className="widget-box-content">
        {children}
      </div>
    </div>
  )
}

export default WidgetBox;
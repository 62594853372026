import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HexagonComponent from "../Hexagon";
import ganheOMundo from '../../assets/img/icons/mundo.png'
import planet from '../../assets/img/planet.png'
import { useAuth } from '../../contexts/AuthContext'
import api from "../../services/api"
import * as S from "./style"

export default function BannerTitleHome({ avatar, username, position, stars }) {
    const [banners, setBanners] = useState()
    const [info, setInfo] = useState()
    const { user } = useAuth()

    const loadBanners = useCallback(async () => {
        const response = await api.get(`/banner/`)
        setBanners(response.data.banners)
    }, [])

    const loadUserInfo = useCallback(async () => {
        const response = await api.get(`/user-info`)
        setInfo(response.data.result)
    }, [])

    useEffect(() => {
        loadBanners()
        loadUserInfo()
    }, [loadBanners, loadUserInfo])

    if (!banners) return '...'

    return (
        <S.BannerContainer className="section-banner profile-banner">
            <Link to={`/resultado/${user.id}`}>
                <S.AvatarContainer>
                    <div className="home-user-info">
                        <div className="left">
                            {avatar && <div className="hexagon-container">
                                <HexagonComponent src={avatar} />
                            </div>}
                            {!avatar && <S.Planet src={planet} alt="Planeta" />}
                            <div className="greeting-user">
                                <h1>MINHA POSIÇÃO</h1>
                                <p>{username}</p>
                            </div>
                        </div>
                        {info ? <div className="right">
                            <div className="line-deco desk" aria-hidden="true" />

                            <div className="group">
                                <div className="line-deco" aria-hidden="true" />
                                <div className="user-info-container">
                                    <p>{info.position}</p>
                                    <p className="text-small">POSIÇÃO</p>
                                </div>
                            </div>

                            <div className="line-deco" aria-hidden="true" />

                            <div className="group">
                                <div className="line-deco" aria-hidden="true" />
                                <div className="user-info-container">
                                    <p>{info.points}</p>
                                    <p className="text-small">PONTOS</p>
                                </div>
                            </div>

                            <div className="line-deco" aria-hidden="true" />

                            <div className="group">
                                <S.Logo src={ganheOMundo} alt="Ganhe o Mundo" />
                            </div>
                        </div> : <>Carregando as informações</>}
                    </div>
                </S.AvatarContainer>
            </Link>
        </S.BannerContainer>
    )
}
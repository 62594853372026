import styled from 'styled-components';

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
  &.desk-visible {
    display: none;
  }

  &.mobile-visible {
    display: none;
  }

  @media (min-width: 1068px) {
    &.desk-visible {
      display: block;
    }
  }

  @media (max-width: 1068px) {
    &.mobile-visible {
      display: block;
    }
  }
`;

export const RegulamentoContainer = styled.div`
h2, h3 {
  color: var(--white);
}
  @media (max-width: 480px) {
    h2 {
      font-size: 1rem;
    }
  }
`

export const BGTemplate = styled.div`
  & > div:first-child > div {
    min-height: 80px;
  }

  .js-container {
    margin-top: 20px;
    justify-content: flex-start;
  }
`

export const SContent = styled.div`
  p {
    margin-top: 24px;
    line-height: 1.5rem;
  }
`

import { call, select, put, all, takeLatest } from "redux-saga/effects"
import api from "../../../services/api"

import { addToCartSuccess, updateAmountSuccess, removeFromCartSuccess } from "./actions"
import history from "../../../services/history"

function* addToCart ({ product, amount }) {
    const id = product.skus[0].codigo

    const productExists = yield select(
        state => state.cart.find(p => p.codigo === product.codigo)
    )

    if (productExists) {
        const qtd = productExists.amount + amount;

        yield put(updateAmountSuccess(id, qtd))
    }
    else {

        yield call(api.patch, `cart/${id}/${amount}`)

        const data = {
            ...product,
            amount: parseInt(amount),
            price: product.skus.reduce((acc, item) => Math.min(item.preco, acc), Infinity) / 100,
        }

        yield put(addToCartSuccess(data))

        history.push('cart')
    }
}

function* removeToCart ({ id }) {

    yield put(removeFromCartSuccess(id))

    yield call(api.patch, `/cart/${id}/0`)
}

function* updateToCart ({ id, amount, control }) {
    if (Number(amount) <= 0) {
        yield put(removeFromCartSuccess(id))
    }

    yield put(updateAmountSuccess(id, amount, control))

    if (control === "increase") {
        yield call(api.patch, `/cart/${id}/${amount + 1}`)
    } else if (control === "decrease") {
        yield call(api.patch, `/cart/${id}/${amount - 1}`)
    }
}

export default all([
    takeLatest('ADD_REQUEST', addToCart),
    takeLatest('REMOVE_FROM_CART_REQUEST', removeToCart),
    takeLatest('UPDATE_AMOUNT_REQUEST', updateToCart)
])
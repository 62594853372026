import { useState, useCallback, useEffect } from "react";
import axios from 'axios'
import { toast } from 'react-toastify'
import Template from "../../template"
import api from '../../services/api'
import MemberComponent from '../../components/Member'
import PaginationComponent from '../../components/Pagination'
import { useAuth } from "../../contexts/AuthContext";
import Filter from "../../components/Filter"
import * as S from './style'

export default function Members() {
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('')
    const [membersData, setMembersData] = useState(null);
    const [totalMembers, setTotalMembers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(0);
    const { doLogout } = useAuth();

    const loadData = useCallback(async (filter) => {
        try {
            setMembersData(null);
            setTotalMembers(0);
            setTotalPages(0);
            setPage(0);
            const response = await api.get('/user', {
                params: filter
            });
            setMembersData(response.data.users);
            setTotalMembers(response.data.totalMembers);
            setTotalPages(response.data.totalPages);
            setPage(response.data.currentPage);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response.status === 403) {
                    toast.error('O token expirou', { theme: 'colored' })
                    doLogout();
                }
            }
        }
    }, [doLogout]);

    const handleSearchByName = useCallback(async () => {
        setFilter((filter) => ({ ...filter, name: search.value, page: 1 }));
        loadData({ ...filter, name: search.value, page: 1 });
    }, [search.value, loadData, filter]);

    const handlePageChange = useCallback(async (page) => {
        setFilter((filter) => ({ ...filter, page }));
        loadData({ ...filter, page });
    }, [loadData, filter]);

    useEffect(() => {
        loadData({});
    }, [loadData]);

    return (
        <Template>
            <div style={{ marginTop: '10rem' }}></div>
            <S.Content>

                <Filter onChange={setSearch} onSubmit={handleSearchByName} label="Buscar usuários"></Filter>

                {membersData && <div className="grid grid-4-4-4 centered">
                    {membersData.map(member => <MemberComponent member={member} />)}
                </div>}

                <PaginationComponent currentPage={page} totalPages={totalPages} onChagePage={handlePageChange} />

                {membersData && <p className="section-results-text">Mostrar {membersData.length} de {totalMembers} membros</p>}
            </S.Content>
        </Template>
    )
}
import styled from 'styled-components'

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return props.typeText ? "var(var(--dark))" : "#eeeeee";
};

export const DropzoneContent = styled.div`
  padding: 10%;
  margin: 10%;
  height: 80%;
  position: absolute;
  inset: 0;
  z-index: 2;

  flex: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  border-radius: 12px;
  background-color: #fafafa8F;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    min-height: 60px;
  }
`
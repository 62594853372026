import Template from "../../../template"

import Banner from '../../../assets/img/achievement/banner/01.jpg'
import Social01 from '../../../assets/img/avatar/01-social.png'
import Cover15 from '../../../assets/img/cover/15.jpg'

export default function Days100 () {
  return <Template>
    <div className="grid grid-3-9">
      <div className="grid-column">
        <div className="streamer-box">
          <figure className="streamer-box-cover liquid">
            <img src={Banner} alt="cover-53" />
          </figure>

          <div className="streamer-box-info">
            <div className="streamer-box-image">
              <figure className="picture medium circle liquid">
                <img src={Social01} alt="avatar-01-social" style={{ borderRadius: '50%' }} />
              </figure>
            </div>

            <p className="streamer-box-title">Quiz 100 Day</p>

            <p className="streamer-box-status">Fechado</p>

            <div className="user-stats">
              <div className="user-stat">
                <p className="user-stat-title">149</p>

                <p className="user-stat-text">Realizados</p>
              </div>

              <div className="user-stat">
                <p className="user-stat-title">136</p>

                <p className="user-stat-text">Completos</p>
              </div>
            </div>

            <a className="button small twitch" href="quiz.html" target="_blank">Iniciar Quiz</a>
          </div>
        </div>

        <div className="widget-box">


          <p className="widget-box-title">FAQs</p>

          <div className="widget-box-content">
            <div className="simple-accordion-list">
              <div className="simple-accordion">
                <div className="simple-accordion-header accordion-trigger">
                  <p className="simple-accordion-title">Lorem ipsum dolor sit amet consectetur</p>

                  <div className="simple-accordion-icon">
                    <svg className="icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    <svg className="icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>
                  </div>

                  <div className="simple-accordion-content accordion-content accordion-open">
                    <p className="simple-accordion-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci aliquam quasi iusto repellendus distinctio nihil earum eaque illo, voluptatibus ipsum quisquam consectetur porro totam aliquid impedit temporibus ullam expedita ea!</p>
                  </div>
                </div>
              </div>

              <div className="simple-accordion">
                <div className="simple-accordion-header accordion-trigger">
                  <p className="simple-accordion-title">Lorem ipsum dolor sit amet consectetur</p>

                  <div className="simple-accordion-icon">
                    <svg className="icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    <svg className="icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>
                  </div>

                  <div className="simple-accordion-content accordion-content">
                    <p className="simple-accordion-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aperiam praesentium aliquid, nisi delectus officiis officia error ea debitis tempore suscipit provident autem ipsa deserunt, harum dolore commodi maiores voluptatem!</p>
                  </div>
                </div>
              </div>

              <div className="simple-accordion">
                <div className="simple-accordion-header accordion-trigger">
                  <p className="simple-accordion-title">Lorem ipsum dolor sit amet consectetur</p>

                  <div className="simple-accordion-icon">
                    <svg className="icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    <svg className="icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>
                  </div>

                  <div className="simple-accordion-content accordion-content">
                    <p className="simple-accordion-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo aliquam culpa labore magnam aut iste quisquam? Soluta, quam, autem voluptatibus neque natus nemo itaque quia voluptas porro vero ullam laborum!</p>
                  </div>
                </div>
              </div>

              <div className="simple-accordion">
                <div className="simple-accordion-header accordion-trigger">
                  <p className="simple-accordion-title">Lorem ipsum dolor sit amet consectetur</p>

                  <div className="simple-accordion-icon">
                    <svg className="icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    <svg className="icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>
                  </div>

                  <div className="simple-accordion-content accordion-content">
                    <p className="simple-accordion-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci porro ducimus quae et consequuntur accusamus, laudantium ipsam excepturi sit, soluta alias incidunt pariatur! Doloremque deleniti in illum odit a libero?</p>
                  </div>
                </div>
              </div>

              <div className="simple-accordion">
                <div className="simple-accordion-header accordion-trigger">
                  <p className="simple-accordion-title">Lorem ipsum dolor sit amet consectetur</p>

                  <div className="simple-accordion-icon">
                    <svg className="icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    <svg className="icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>
                  </div>

                  <div className="simple-accordion-content accordion-content">
                    <p className="simple-accordion-text">Lorem ipsum dolor sit amet consectetur</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-column">
        <div className="stream-box big">
          <div className="stream-box-video">
            <iframe src="https://www.youtube.com/embed/9qdHBXcwayo" title="YouTube video player" allowfullscreen></iframe>
          </div>

          <div className="stream-box-info">

            <figure className="stream-box-game-image liquid">
              <img src={Cover15} alt="cover-15" />
            </figure>


            <p className="stream-box-title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

            <p className="stream-box-category">Categoria: <a href="#anchor" target="_blank">Vendas</a></p>

            <p className="stream-box-views">13 visualizações</p>
          </div>
        </div>

        <div className="widget-box">

          <p className="widget-box-title">Resumo</p>

          <div className="widget-box-content">
            <p className="widget-box-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat exercitationem commodi distinctio hic est nemo! Corporis nam recusandae aspernatur modi aperiam hic, fugiat voluptatum alias quis quibusdam cupiditate temporibus dolorem. <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum velit laborum animi soluta perferendis doloribus architecto hic reprehenderit, iusto reiciendis! Fuga ipsum perferendis expedita quibusdam libero culpa quisquam error tempora. <br />
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime corporis distinctio sequi neque aliquid, nesciunt sit dolorem reprehenderit inventore beatae sed adipisci nisi consectetur eaque illum temporibus suscipit dignissimos voluptatibus?</p>

          </div>
        </div>
      </div>
    </div>
  </Template >
}
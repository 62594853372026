import { useCallback, useMemo, useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import BannerTitleComponent from '../../components/BannerTitleHome'
import InputSelect from '../../components/Forms/InputSelect'
import RankingElement from './RankingElement'
import Template from '../../template'
import api from '../../services/api'
import * as S from './style'

function Ranking() {
  const { user, userCan } = useAuth()
  const [ranking, setRanking] = useState()
  const [params, setParams] = useState({ stepId: 1 })
  const [channels, setChannels] = useState([])
  const [channel, setChannel] = useState(user.channel.name)
  const [role, setRole] = useState('EX')

  const isGA = useMemo(() => {
    return userCan('read:ranking-all') || ['Gerente de Área', 'Gerente Regional'].includes(role)
  }, [role, userCan])

  const isGR = useMemo(() => {
    return userCan('read:ranking-all') || ['Gerente Regional'].includes(role)
  }, [role, userCan])

  const loadRanking = useCallback(async (params) => {
    const response = await api.get('/ranking', { params })
    setRanking(response.data.ranking)
    setChannel(response.data.channel)
    setChannels(response.data.channels)
    setRole(response.data.role)
  }, [])

  useEffect(() => {
    loadRanking(params)
  }, [loadRanking, params]);

  return <Template>

    <S.Margin />

    <div className="wrapper">

      <BannerTitleComponent
        username={user.name}
        position={"0"}
        stars={"0"}
      />

      <div className="grid medium-space">
        <div className="account-hub-content">

          <div className="grid-column">
            <div className="widget-box">
              <S.Wrapper>
                <S.FiltrosCargo>
                  <InputSelect onChange={(stepId) => setParams(params => ({ ...params, stepId }))}>
                    <option value="1">Etapa 1</option>
                    <option value="2">Etapa 2</option>
                  </InputSelect>
                  {userCan('read:ranking-all') ? <InputSelect onChange={(channelId) => setParams(params => ({ ...params, channelId }))}>
                    <option value="">Selecione o canal</option>
                    {channels && channels.map(c => <option key={c.id} value={c.id}>{c.name} ({c.team.name})</option>)}
                  </InputSelect> :
                    <S.Selector>{user.channel.name}</S.Selector>}
                  <InputSelect onChange={(role) => setParams(params => ({ ...params, role }))}>
                    <option value="">Selecione um cargo</option>
                    {isGR && <option value="Gerente Regional">Gerente Regional</option>}
                    {isGA && <option value="Gerente de Área">Gerente de Área</option>}
                    <option value="Executivo de Venda">Executivo de Venda</option>
                    <option value="Holding">Holding</option>
                  </InputSelect>
                  {params.role === 'Holding' && <InputSelect onChange={(cluster) => setParams(params => ({ ...params, cluster }))}>
                    <option value="">Selecione um cluster</option>
                    <option value="1">Cluster 1</option>
                    <option value="2">Cluster 2</option>
                  </InputSelect>}
                </S.FiltrosCargo>

                <S.Title>Ranking {channel.name}</S.Title>

                {ranking && ranking.map(r => <RankingElement {...r} />)}
              </S.Wrapper>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Template >
}

export default Ranking;

import styled from 'styled-components'

export const Footer = styled.footer.attrs(() => {
  return {
    className: 'js-footer'
  }
})`
  text-align: center;
  background: var(--primary);
  padding: 1.5rem;
  width: 100%;
  position: relative;
  z-index: 99999;
  display: flex;
  max-height: 140px;
  box-sizing: border-box;
  @media (max-width: 680px) {
    transform: translateX(0);
    max-width: 100%;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-between;
  }

  @media (max-width: 526px) {
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: 90px;
    margin: 0 2rem 0 7rem;
    @media (max-width: 680px) {
      margin-left: 0;
      margin-right: 1rem;
    }
    @media (max-width: 374px) {
      margin-right: 0;
    }
  }

  p {
    color: var(--white);
    line-height: 1.25rem;
  }
  div.footer-links {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    @media (max-width: 680px) {
      text-align: right;
    }
    @media (max-width: 526px) {
      text-align: center;
    }
    
    a {
      font-weight: 700;
      line-height: 1.5rem;
      color: #23d2e2;
      &:hover {
        color: #23d2e270;
      }
    }
  }
`

export const LegalText = styled.p`
  display: block;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 680px) {
    flex: 1 100%;
    padding-bottom: 32px;
  }
`
import styled from "styled-components";

export const ProfileNavContainer = styled.nav`
    & > div > div > .section-menu {
        display: grid;
        overflow: scroll;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-flow: column;
        grid-template-rows: 1fr;
        overscroll-behavior-inline: contain;
        /* scroll-padding-inline: 2.2em; */
        scroll-snap-type: inline mandatory;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none
        }
        scrollbar-width: none;
    
        & > * {
            scroll-snap-align: start;
        }
    }
`
import { useCallback, useEffect, useState } from 'react'
import InputSelect from '../../components/Forms/InputSelect'
import api from '../../services/api'

function MyHoldSelectorComponent ({...props}) {
  const [holdings, setHoldings] = useState()

  const loadHoldings = useCallback(async () => {
    const response = await api.get(`/distributor/holding`)
    setHoldings(response.data)
  }, [])

  useEffect(() => {
    loadHoldings()
  }, [loadHoldings])

  return <InputSelect {...props}>
    <option value="">Selecione uma rede</option>
    {holdings && holdings.map(h => <option value={h.id}>{h.name.toUpperCase()} ({h.cnpj})</option>)}
  </InputSelect>
}

export default MyHoldSelectorComponent


import React from 'react'
import * as S from './style'

export default function Loading () {
    return (
        <S.LoadingContainer className="page-loader-indicator loader-bars">
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
        </S.LoadingContainer>
    )
}
import styled from "styled-components";
import { getTransitionColorByVariableName } from "../../utils/color";

const mixPrimaryToSecondaryColorByPercent = (percent) => getTransitionColorByVariableName(
  "--primary",
  "--secondary",
  percent
);

export const LoadingContainer = styled.div`
  &&&.loader-bars {
    .loader-bar:nth-child(1){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(0)}
    }
    .loader-bar:nth-child(2){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(15)}
    }
    .loader-bar:nth-child(3){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(30)}
    }
    .loader-bar:nth-child(4){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(45)}
    }
    .loader-bar:nth-child(5){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(60)}
    }
    .loader-bar:nth-child(6){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(75)}
    }
    .loader-bar:nth-child(7){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(90)}
    }
    .loader-bar:nth-child(8){
      background-color: ${() => mixPrimaryToSecondaryColorByPercent(100)}
    }
  }
`
import { useNavigate } from "react-router-dom";
import * as S from "./style";
import trofeu from "../../assets/img/quiz/bg-trofeu.png";

const templateList = {
    trofeu
}

export default function CardQuizEdit ({children, template, quiz}) {
    const bg = templateList[template];
    const navigate = useNavigate();
    return (
        <S.Principal bg={bg}>
            <h3>{quiz.title}</h3>
            <S.Link onClick={() => navigate(`/admin/quiz/${quiz.id}`)}>Editar</S.Link>
        </S.Principal>
    )
}
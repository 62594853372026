import React, {forwardRef} from "react";

function Input({label, ...rest}, ref) {
    return (
        <div className="form-input">
             <input 
                type="text" 
                id="login-username" 
                className="form__input__focus" 
                placeholder=""
                ref={ref}
                required
                {...rest}
            />
            <label className="form__label login-username">{label}</label>
        </div>
    )
}

export default forwardRef(Input)
import { useMemo, useCallback } from "react";
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

// Template
import Template from "../../../../template"

// Components
import BannerTitleComponent from "../../../../components/BannerTitle"

// Imagens
import BannerTitle from "../../../../assets/img/banner/marketplace-icon.png"

// utils
import { currency } from "../../../../utils/format"

// Actions
import * as CartActions from "../../../../store/modules/cart/actions"

export default function Cart () {
  //pegar os dados dos produtcs selecionados
  const cart = useSelector((state) => state.cart)
  const dispatch = useDispatch();

  const subtotal = useCallback(() => {
    const valueTotal = cart.reduce((a, b) => a + b.price * b.amount, 0)
    return currency(valueTotal)
  }, [cart])

  const totalItems = useMemo(() => {
    return cart.reduce((acc, item) => Number(item.amount) + acc, 0);
  }, [cart]);

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Marketplace"
        subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
        imgAlt="marketplace-icon"
      />

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Procure seu</p>

          <h2 className="section-title">Carrinho de compras <span className="highlighted">{cart.length}</span></h2>
        </div>
      </div>

      <div className="grid grid-9-3 small-space">
        <div className="grid-column">
          <div className="table-wrap" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: '0px' }}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div className="simplebar-offset" style={{ right: "0px", bottom: '0px' }}>
                <div className="simplebar-content-wrapper" style={{ height: 'auto', overflow: 'auto' }}>
                  <div className="simplebar-content" style={{ padding: '0px' }}>
                    <div className="table table-cart split-rows">
                      <div className="table-header">
                        <div className="table-header-column">
                          <p className="table-header-title">Item</p>
                        </div>

                        <div className="table-header-column padded-left">
                          <p className="table-header-title">Quantidade</p>
                        </div>

                        <div className="table-header-column centered padded-left">
                          <p className="table-header-title">Preço</p>
                        </div>

                        <div className="table-header-column padded-big-left"></div>
                      </div>

                      {cart.map((item, key) => (
                        <div className="table-body same-color-rows" key={key}>
                          <div className="table-row medium">
                            <div className="table-column">
                              <div className="product-preview tiny" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link to={`/product/`}>
                                  <figure className="product-preview-image liquid" style={{ background: `url('https://www.${item.lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=') center center / cover no-repeat` }}>
                                    <img src={`https://www.${item.lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=${item.fotoMedia}`} alt="item-01" />
                                  </figure>
                                </Link>

                                <div className="product-preview-info">
                                  <p className="product-preview-title">
                                    <Link to={`/product/`}>{item.displayName}</Link>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="table-column padded-left">
                              <div className="form-counter">
                                <p className="form-counter-value">{item.amount}</p>

                                <div className="form-counter-controls">
                                  <div className="form-counter-control form-counter-control-increase"
                                    onClick={() => dispatch(CartActions.updateAmountRequest(item.skus[0].codigo, item.amount, 'increase'))}
                                  >
                                    <svg className="form-counter-icon icon-small-arrow"
                                    >
                                      <use href="#svg-small-arrow"></use>
                                    </svg>
                                  </div>

                                  <div className="form-counter-control form-counter-control-decrease"
                                    onClick={() => dispatch(CartActions.updateAmountRequest(item.skus[0].codigo, item.amount, 'decrease'))}
                                  >
                                    <svg className="form-counter-icon icon-small-arrow">
                                      <use href="#svg-small-arrow"></use>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="table-column centered padded-left">
                              <p className="price-title"><span className="currency"></span> {currency(item.price)} Moedas</p>
                            </div>

                            <div className="table-column padded-big-left">
                              <div className="table-action">
                                <svg className="icon-delete" onClick={() => dispatch(CartActions.removeFromCartRequest(item.skus[0].codigo))}>
                                  <use href="#svg-delete"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="simplebar-placeholder" style={{ width: "auto", height: "416px" }}></div>
          </div>
            <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'visible' }}>
              <div className="simplebar-scrollbar" style={{ width: '840px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}></div>
            </div>
            <div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}>
              <div className="simplebar-scrollbar" style={{ height: "0px", display: "none" }}></div>
            </div>
          </div>
        </div>

        <div className="grid-column">
          <div className="sidebar-box margin-top">
            <p className="sidebar-box-title">Total do pedido</p>

            <div className="sidebar-box-items">
              <p className="price-title big"><span className="currency"></span> {subtotal()}</p>

              <div className="totals-line-list">
                <div className="totals-line">
                  <p className="totals-line-title">Carrinho ({totalItems})</p>

                  <p className="price-title"><span className="currency"></span> {subtotal()}</p>
                </div>

                <div className="totals-line">
                  <p className="totals-line-title">Créditos</p>

                  <p className="price-title"><span className="currency"></span>0</p>
                </div>

                <div className="totals-line">
                  <p className="totals-line-title">Saldo Final</p>

                  <p className="price-title"><span className="currency"></span> {subtotal()}</p>
                </div>
              </div>

              <a className="button primary" href="checkout">Continuar para o checkout</a>


            </div>
          </div>
        </div>
      </div>
    </Template >
  )
}
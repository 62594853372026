import { useCallback, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify'
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { useWorksheetDate } from '../../hooks/useWorksheetDate'; 
import Template from '../../template';
import InputSelect from '../../components/Forms/InputSelect';
import PageLoader from '../../components/PageLoader';
import BannerTitleComponent from '../../components/BannerTitle';
import RankingGeral from './RankingGeral';
import Icon from '../../assets/img/banner/overview-icon.png';
import * as S from './style'

function RankingDist () {
  const [myPosition, setMyPosition] = useState()
  const [onda, setOnda] = useState()
  const [isLoading, setIsLoading] = useState(true);

  const { user, doLogout } = useAuth()
  const period = useWorksheetDate();

  const loadPageData = useCallback(async () => {
    setIsLoading(true)
    try {
      const results = await api.get(`/distributor/ranking`, { params: { stepId: onda || 1}});
      setMyPosition(results.data.myPositions)
    } catch (err) {
      console.log(err)
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout()
          return
        }
      }
      toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' })
    }
    setIsLoading(false)
  }, [doLogout, onda]);

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  if (!myPosition) return <PageLoader />
  return <Template>
    
    <BannerTitleComponent
      imgUrl={Icon}
      title="Resultados"
      subTitle=""
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <h2 className="section-title">Resultados</h2>
        <p>Resultados parciais etapa 1 - Atualizado em {period}</p>
      </div>
    </div>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo>
                <InputSelect label="Selecione uma etapa" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Etapa 1</option>
                  <option value="2">Etapa 2</option>
                </InputSelect>
              </S.FiltrosCargo>
              {!isLoading && <RankingGeral onda={onda} ranking={myPosition} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>
}

export default RankingDist;

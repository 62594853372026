import { Link } from "react-router-dom"
import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  background-color: #3a134c;
  color: var(--white);
  display: flex;
  gap: 2rem;
  flex-direction: row;
  font-size: 24px;
  justify-content: space-between;
  padding: 42px 35px;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Escudo = styled.img`
  max-width: 62px;
`

export const Display = styled.div`
  flex: 0 100px;
  position: relative;
  text-align: center;

  &:after {
    font-size: .75rem;
    position: absolute;
    text-transform: uppercase;
    inset: 100% 0px 0 0;
  }
`

export const Posicao = styled(Display)`
  &:after {
    content: "Posição";
  }
`

export const Pontos = styled(Display)`
  &:after {
    content: "Pontos";
  }
`

export const Nome = styled(Link)`
  color: var(--white);
  flex: 1 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  text-align: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NomeNoLink = styled.span`
  color: var(--white);
  flex: 1 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  text-align: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NomeMobile = styled(Nome)`
  display: none;
  grid-column-start: 1;
  grid-column-end: 4;

  @media (max-width: 768px) {
    display: block;
  }
`

export const Icone = styled.img`
  max-width: 62px;
`

import { useCallback, useEffect, useRef, useState } from 'react'
import InputText from '../../../components/Forms/InputText'
import PathImageDropzone from '../../../components/PathImageDropzone'
import PageLoader from '../../../components/PageLoader'
import Template from '../../../template'
import { useModal } from '../../../contexts/ModalContext'
import api from '../../../services/api'
import * as S from './style'

function AdminBanner() {
  const [banners, setBanners] = useState()
  const [params, setParams] = useState({})
  const [errors] = useState({})

  const { openModal, closeModal } = useModal()
  const dragItem = useRef()
  const dragEnterItem = useRef()

  const dragStart = (evt, position) => {
    dragItem.current = position
  }

  const dragEnter = (evt, position) => {
    dragEnterItem.current = position
  }

  const drop = useCallback(async (e) => {
    const newList = [...banners]
    const dragItemContent = newList[dragItem.current]
    newList.splice(dragItem.current, 1)
    newList.splice(dragEnterItem.current, 0, dragItemContent)
    const reordered = newList.map((list, index) => {
      list.order = index
      return list
    })
    await api.put('/banner/reorder', reordered.map(list => {
      return {
        bannerId: list.id,
        order: list.order
      }
    }))
    setBanners(reordered)
  }, [banners])

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      if (!params.id) {
        const response = await api.post(`/banner/`, params)
        setBanners((banners) => [response.data, ...banners])
      } else {
        const response = await api.put(`/banner/`, params)
        const currentBanners = banners.filter(banner => banner.id !== params.id)
        setBanners([response.data, ...currentBanners])
      }
    } catch (error) {
      console.log(error)
    }
  }, [banners, params])

  const loadBanners = useCallback(async () => {
    const response = await api.get(`/banner/`)
    setBanners(response.data.banners)
  }, [])

  const handleEdit = useCallback((banner) => {
    setParams(banner)
  }, [])

  const handleConfirmDelete = useCallback(async (bannerId) => {
    try {
      await api.delete(`/banner/${bannerId}`)
      const newBanners = banners.filter(banner => banner.id !== bannerId)
      setBanners([...newBanners])
      closeModal()
    } catch (error) { }
  }, [banners, closeModal])

  const handleDeleteConfirm = useCallback(async (bannerId) => {
    openModal({
      header: 'Atenção',
      body: <>
        Essa ação é irreversivel, deseja continuar?
        <S.Buttons>
          <button className="button primary" onClick={closeModal}>Não</button>
          <button className="button tertiary" onClick={() => handleConfirmDelete(bannerId)}>Sim</button>
        </S.Buttons>
      </>
    })
  }, [closeModal, handleConfirmDelete, openModal])

  useEffect(() => {
    loadBanners()
  }, [loadBanners])

  if (!banners) return <PageLoader />
  return <Template>
    <div style={{ marginTop: '8rem' }}></div>
    <div className="account-hub-content">
      <S.Container className="widget-box">
        <form onSubmit={handleSubmit} className="widget-box-content" style={{ position: 'relative' }}>

          <S.ImageContainer>
            {params.path && <S.ImagePreview src={params.path} alt="Preview" />}
            <PathImageDropzone typeName="Banner" onDrop={(image) => setParams({ ...params, imageId: image.id, path: image.path })} path="banner" />
          </S.ImageContainer>

          <S.Grid>
            <InputText
              name="name"
              value={params.link}
              onChange={(link) => setParams({ ...params, link })}
              placeholder="Link"
              type="text"
              error={errors.link}
            />

            <button className="primary button">Salvar</button>
          </S.Grid>

        </form>

        <div class="table table-downloads split-rows">
          <div class="table-header">
            <div class="table-header-column">
              <p class="table-header-title">Item</p>
            </div>

            <div class="table-header-column padded">
              <p class="table-header-title">Date</p>
            </div>

            <div class="table-header-column padded-left"></div>
          </div>

          <div class="table-body same-color-rows">
            {banners.map((banner, index) => <div
              className="table-row medium"
              draggable={true}
              onDragStart={(evt) => dragStart(evt, index)}
              onDragEnter={(evt) => dragEnter(evt, index)}
              onDrop={(evt) => drop(evt)}
              key={index}
            >
              <div class="table-column">
                <div class="product-preview tiny">
                  <figure class="product-preview-image short liquid">
                    <img src={banner.path} alt="item-11" />
                  </figure>

                  <div class="product-preview-info">
                    <p class="product-preview-title">{banner.link}</p>

                    <p class="product-preview-creator">{banner.creatorName}</p>
                  </div>
                </div>
              </div>

              <div class="table-column padded">
                <p class="table-title">{new Date(banner.createdAt).toLocaleDateString('pt-BR')}</p>
              </div>

              <div class="table-column padded-left">
                <div class="table-actions">
                  <button class="button tertiary" onClick={() => handleDeleteConfirm(banner.id)}>Deletar</button>
                  <button class="button primary" onClick={() => handleEdit(banner)}>Editar</button>
                </div>
              </div>
            </div>)}

          </div>
        </div>

      </S.Container>
    </div>
  </Template>
}

export default AdminBanner;

import React, { useState } from "react";
// import { Link } from 'react-router-dom'

// Template
import Template from "../../template"

// Component
import BannerTitleComponent from "../../components/BannerTitle"
// import PageLoaderComponent from "../../components/PageLoader"
import Filter from "../../components/Filter"

// Image
import BannerTitle from "../../assets/img/banner/forums-icon.png"
import Category from "../../assets/img/forum/category/02.png"

// Hook
// import { useFetch } from "../../hooks/useFetch"

export default function Forums () {
    const [search, setSearch] = useState('')
    const [, setFilter] = useState('')
    // request API
    // const { data } = useFetch('forums');

    // if (!data) {
    //     return <PageLoaderComponent />
    // }

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Forums"
                subTitle="Fale sobre qualquer coisa!Games, música, projetos!"
                imgAlt="forums-icon"
            />

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Bem vindo</p>

                    <h2 className="section-title">Bragança Forum</h2>
                </div>
            </div>

            <Filter onChange={setSearch} onSubmit={() => setFilter(search.value)} label="Buscar forum" />

            <div className="table table-forum-category">
                <div className="table-header">
                    <div className="table-header-column">
                        <p className="table-header-title">Categoria</p>
                    </div>

                    <div className="table-header-column centered padded-medium">
                        <p className="table-header-title">Tópicos</p>
                    </div>

                    <div className="table-header-column centered padded-medium">
                        <p className="table-header-title">Posts</p>
                    </div>

                    <div className="table-header-column padded-big-left">
                        <p className="table-header-title">Tópicos recentes</p>
                    </div>
                </div>

                <div className="table-body">
                    <div className="table-row big">
                        <div className="table-column">
                            <div className="forum-category">
                                {/* <Link href="/forums"> */}
                                <img className="forum-category-image" src={Category} alt="category-02" />
                                {/* </Link> */}

                                <div className="forum-category-info">
                                    <p className="forum-category-title">
                                        {/* <Link to="/forums">Assuntos Gerais</Link> */}
                                    </p>
                                    <p className="forum-category-text">O Melhor lugar para a comunidade conversar sobre qualquer coisa que quiser!</p>
                                </div>
                            </div>
                        </div>

                        <div className="table-column centered padded-medium">
                            <p className="table-title">44</p>
                        </div>

                        <div className="table-column centered padded-medium">
                            <p className="table-title">236</p>
                        </div>

                        <div className="table-column padded-big-left">
                            {/* <Link className="table-link" to="/forums">Lorem ipsum dolor sit amet consectetur</Link> */}
                            {/* <Link className="table-link" to="/forums">Lorem ipsum dolor sit amet consectetur</Link> */}
                            {/* <Link className="table-link" to="/forums">Lorem ipsum dolor sit amet consectetur</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </Template >
    )
}
import styled from 'styled-components';

export const MobileDropbox = styled.div`
position: absolute;
z-index: 9999;
width: min(90vw, 384px);
top: 64px;
left: 20px;
padding-bottom: 0;
opacity: ${props => props.active ? 1 : 0};
visibility: ${props => props.active ? 'visible' : 'hidden'} ;
transform: ${props => props.active ? 'translate(0px)' : 'translate(0px, -40px)'};
transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
box-shadow: 0 0 1rem 0 var(var(--dark));
`;

export const Li = styled.li`
&&& {
  width: 90%;
  padding: 0 10px;
  margin-bottom: 0.5rem;
  .menu-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    .menu-item-link-icon {
      position: static;
      padding-left: 0;
      fill: var(--white);
    }
  }
  .menu-item-link:hover {
    background-color: var(--hover);
    padding-left: 0;
    div.menu-item-link-icon > p {
      color: var(--white);
      fill: var(--secondary);
    }
  }
}
&.active a div p {
  color: var(--white);
}
`
export const MenuItems = styled.ul`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
width: 100%;
`
export const MobileWrapper = styled.div`
  display: none;
  &.action-list::after, &.action-list::before {
    width: 0;
  }
  .hexagon-container {
    width: 40px;
    aspect-ratio: 1;
  }
  @media(max-width: 960px) {
    display: flex;
  }
  .navigation-widget-section-link:hover {
    color: var(--primary);
  }
`
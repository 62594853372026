import styled from "styled-components"

export const Container = styled.section`
  display: grid;
  grid-template-columns: 6fr 4fr;
  margin: 0 52px;
  gap: 16px;

  @media (max-width: 756px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
    max-width: 90%;
  }
`

export const Video = styled.video.attrs(() => {
  return {
    controls: false,
    autoPlay: false
  }
})`
  max-width: 100%;
  height: auto;
`

export const TextArea = styled.div.attrs(() => {
  return {
    className: 'js-text-area'
  }
})`
  position: relative;
  background-color: rgba(92, 30, 121, .7);
  padding: 16px;
  border-radius: 12px;
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 568px) {
    position: initial;
    margin: 0 auto;
    width: 100%;
  }
`

export const Header = styled.h1`
  span {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    text-transform: capitalize;
    line-height: 1.20em;
    font-size: 4rem;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;

    @media (max-width: 456px) {
      font-size: 76px;
    }
  }
  `
export const SmallHeader = styled.h2`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  outline: none;
  background: none;
  position: relative;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  line-height: 1.20em;
  margin: 0px 0px;
  font-size: 1.5rem;
`
export const Paragraph = styled.p`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  line-height: 1.70em;
  margin-bottom: 0px;
  position: relative;
  display: block;
  padding-top: 20px;
`

export const Links = styled.div.attrs(() => {
  return {
    className: 'js-link-group'
  }
})`
  position: relative;
  display: block;
  padding-top: 70px;

  @media (max-width: 456px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 52px;
  }
`

export const Link = styled.div.attrs(() => {
  return {
    className: 'js-link'
  }
})`
  position: relative;
  float: left;
  margin-right: 50px;

  @media (max-width: 456px) {
    margin-right: 0;
    width: 100%;
  }

  a {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    line-height: 30px;
    padding: 11px 25px 11px;
    color: var(--primary);
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    background: none;
    border: 1px solid var(--secondary);
    border-radius: 6px;
    transition: all 0.3s ease;
    width: 100%;
    background-color: #fff;
  }

  i {
    margin-left: 16px;
  }

  &:nth-child(2) {
    margin-right: 0;
    margin-bottom: 40px;
    width: auto;
  }
`

export const BackgroundImage = styled.div`
  max-width: 600px;
  position: absolute;
  top: -25px;
  z-index: -1;

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`

export const PlayButton = styled.button`
  display: inline-block;
  transition: all 0.3s ease;
  border-radius: 100%;

  .ripple {
    &,
    &:before,
    &:after {
      content: "";
      margin: 0px;
      padding: 0px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 55px;
      height: 55px;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      box-shadow: 0 0 0 0 rgb(11, 2, 4, .6);
    }
    
    &.animate {
      &,
      &:before,
      &:after {
        animation: ripple 3s infinite;
      }

      &:before {
        animation-delay: .9s;
      }

      &:after {
        animation-delay: .6s;
      }
    }
  }

  &:focus {
    outline: 0 none;
  }
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`
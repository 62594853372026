import styled from 'styled-components'
import WidgetBox from "../../components/WidgetBox"

export const MecanicaWidgetBox = styled(WidgetBox)`
  margin-top: 4rem;
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;

  &:last-child {
    margin-bottom: 0;
  }
`
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useModal } from '../../../contexts/ModalContext'
import Notice from '../Notice'
// import { useNotify } from '../../../contexts/NotifyContext'
import { useClickOutside } from '../../../hooks/useClickOutside';
import { Dropbox } from "../style"
import { useNotify } from '../../../contexts/NotifyContext';

function NoticeGroup() {
  const { notices, markAllAsReady } = useNotify();
  const [activeNotification, setActiveNotification] = useState(false)
  const [wrapperRef] = useClickOutside(() => setActiveNotification(false));

  const unread = useMemo(() => {
    return notices.filter(n => !n.isRead).length > 0;
  }, [notices]);

  const toggleNotifications = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveNotification(!activeNotification);
      markAllAsReady();
  }, [activeNotification, markAllAsReady]);

  // const { openModal } = useModal()
  // const history = useHistory()
  // useEffect(() => {
  //   if (!unread) return
  //   openModal({
  //     body: <div className="form-row">Você tem uma nova notificação.<br />Deseja ve-la agora?</div>,
  //     size: 'small',
  //     onConfirm: () => {
  //       markAllAsReady()
  //       history.push('/newsfeed')
  //     }
  //   })
  // }, [openModal, unread, history, markAllAsReady])

  return <div className="action-list-item-wrap">
    <div className={`action-list-item ${unread ? 'unread' : ''} header-dropdown-trigger`} onClick={toggleNotifications}>
      <svg className="icon-notification">
        <use href="#svg-notification"></use>
      </svg>
    </div>

    <div ref={wrapperRef} >
      <Dropbox active={activeNotification} className="dropdown-box header-dropdown notice-box">
        <div className="dropdown-box-header">
          <p className="dropdown-box-header-title">Notificações</p>
        </div>
        <div className="dropdown-box-list" data-simplebar>
          {notices.length > 0 && notices.map((n, idx) => <Notice key={n.id || idx} {...n} />)}
          {notices.length === 0 && <p style={{ 'textAlign': 'center' }}>Você não tem nenhuma mensagem</p>}
        </div>
        <Link className="dropdown-box-button secondary" to="/perfil/newsfeed">Ver todas as notificações</Link>
      </Dropbox>
    </div>
  </div>
}

export default NoticeGroup;
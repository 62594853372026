import { useEffect, useState, useCallback } from 'react'
import { useFetch } from '../../hooks/useFetch'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../components/PageLoader'
import InputText from '../../components/Forms/InputText'
import RoleSelectorComponent from '../../components/RoleSelectorComponent'
import ProfileSelectorComponent from '../../components/ProfileSelectorComponent'
import RegionSelectorComponent from '../../components/RegionSelectorComponent'
import CargoComponent from '../../components/CargoComponent'
import ChannelComponent from '../../components/ChannelComponent'
import RegionComponent from '../../components/RegionComponent'
import { MaskDocument, MaskPhone, MaskCNPJ } from '../../utils/mask'
import { useParams } from 'react-router-dom'
import { useCampaign } from '../../contexts/CampaignContext'
import Template from '../../template'
import api from '../../services/api'
import * as S from './style'

export default function EditUser() {
  const [user, setUser] = useState()
  const [role, setRole] = useState({})
  const [holding, setHolding] = useState({})
  const [address, setAddress] = useState({})
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  })

  const { userId } = useParams()
  const { campaignData } = useCampaign()

  const { data } = useFetch(`/user/${userId}`)

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault()
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })
    try {
      await api.put(`/user/${user.id}`, { ...user, address, holding })
      toast.success('Os dados foram salvos', { theme: "colored" })
    } catch (e) { }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user, address, holding])

  const handleApprove = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      await api.patch(`/user/${user.id}/approve`)
      toast.success('Usuário aprovado com sucesso!', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro e não foi possivel aprovar o usuário', { theme: 'colored' })
    }
  }, [user])

  const handleChangeChannel = useCallback((value) => {
    console.log(value)
    setUser({ ...user, channelId: value })
  }, [user])

  const handleChangeRegion = useCallback((value) => {
    console.log(value)
    setHolding({ ...holding, regionId: value })
  }, [holding])

  useEffect(() => {
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [])

  useEffect(() => {
    if (!data) return
    setUser(data)
    setRole(data.role)
    setHolding(data.holding || {})
    setAddress(data.address || {})
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [campaignData, data])

  if (!user) return <PageLoaderComponent />

  return (
    <Template>

      <div className="grid medium-space wrapper">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Meu perfil</p>

              <h2 className="section-title">Informação de perfil</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre seu perfil</p>

              <div className="widget-box-content">

                <form className="form" action="" method="POST" onSubmit={handleUserSave}>
                  <div className="form-row split">
                    <div className="form-item">
                      <RoleSelectorComponent value={user.role?.id} onChange={(role) => { setUser({ ...user, roleId: role.id }); setRole(role) }} />
                    </div>
                    <div className="form-item">
                      <ProfileSelectorComponent value={user.profileId} onChange={(profileId) => { setUser({ ...user, profileId: profileId.id }) }} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CPF/CNPJ" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskDocument(value) })} maxLength="18" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <CargoComponent name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                    <div className="form-item">
                      <ChannelComponent value={user.channel_id} onChange={handleChangeChannel} />
                    </div>
                    <div className="form-item">
                      <InputText name="matricula" label="Matrícula" id="matricula" value={user.matricula} onChange={(value) => setUser({ ...user, matricula: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome da Holding" id="name" value={holding?.name} onChange={(value) => setHolding({ ...holding, name: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="razaoSocial" label="Razão social" id="razaoSocial" value={holding?.razaoSocial} onChange={(value) => setHolding({ ...holding, razaoSocial: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cnpj" label="CNPJ" id="cnpj" value={user.cnpj} onChange={(value) => setUser({ ...user, cnpj: value })} mask={MaskCNPJ} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cdgHolding" label="Código da Holding" id="cdgHolding" value={holding?.cdgHolding} onChange={(value) => setHolding({ ...holding, cdgHolding: value })} />
                    </div>
                    <div className="form-item">
                      <RegionComponent value={holding.regionId} onChange={handleChangeRegion} />
                    </div>
                  </div>

                  {role?.name === 'Ponto Focal' && <>
                    <div className="form-row split">
                      <p className="widget-box-title">Sobre a holding</p>
                    </div>
                    <div className="form-row split">
                      <div className="form-item">
                        <InputText name="name" label="Nome da holding" id="name" value={holding?.name} onChange={(name) => setHolding({ ...holding, name })} />
                      </div>
                      <div className="form-item">
                        <InputText name="cnpj" label="CNPJ" id="cnpj" value={holding?.cnpj} onChange={(cnpj) => setHolding({ ...holding, cnpj })} />
                      </div>
                      <div className="form-item">
                        <RegionSelectorComponent name="region" label="Regional" id="region" value={holding?.region_id} onChange={(region) => setHolding({ ...holding, region })} />
                      </div>
                    </div>
                  </>}

                  <div className="form-row split">
                    {!user.approved && <div className="form-item">
                      <button className="button primary add-field-button" onClick={handleApprove}>Aprovar usuário</button>
                    </div>}
                    <div className="form-item">
                      <S.SubmitButton className="button primary add-field-button" type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}
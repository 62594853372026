import { useState } from 'react';
import Template from '../../template';
import Accordion from '../../components/Accordion';
import * as S from './style';

const questions = [
  { title: '1- Quem participa da Campanha?', content: '<strong>Time interno Mondelēz:</strong> Gerentes regionais, gerentes de área e executivos/vendedores (todos participam)<br /><strong>Clientes Mondelēz:</strong> Atacados, clientes diretos e distribuidores do TT*, LMT regionais e C&C regionais, todos cadastrados na Mondelēz.<br />*Apenas os auditados (manuais e com integração Visor).' },
  { title: '2- Período de participação e área de abrangência?', content: 'De abril de 2023 a setembro de 2023, em todo o território nacional.' },
  { title: '3- Quais os produtos participantes?', content: '<strong>Produtos Participantes:</strong> Serão consideradas todas as famílias comercializadas pela Mondelēz. Não serão contabilizados produtos que não são produzidos pela Mondelēz, como, por exemplo: sorvetes.<br /><strong>Categorias Foco:</strong> Bebidas, Chocolates e Biscoitos das marcas de propriedade da Mondelēz.' },
  { title: '4- Qual o prêmio da campanha?', content: 'Pacote turístico para viagem internacional, com destino para Israel, com hospedagem para 5(cinco) noites, a ser realizada no calendário de 2024, para 1(uma) pessoa, sem direito a acompanhante.' },
  { title: '5- Quantos participantes serão premiados?', content: '<strong>Time Interno Mondelēz: 13 premiados, sendo:</strong><br />Gerente regional:  1 TT, 1 MT e 1 C&C + FNC = 3 premiados<br />Gerente de área: 1 TT, 1 MT e 1 C&C + FNC = 3 premiados<br />Vendedores executivos: 6 premiados<br />Vendedores executivos GKA: 1 premiado<br /><br />Clientes Mondelēz: 22 premiados, sendo:<br />Distribuidores: Por formato VJ / TD / HIBR e por base de cadastro ativo = 10 premiados no canal TT.<br />Atacados: Clusterizados por faturamento médio Sell Out Total MDLZ = 2 premiados no canal TT.<br />LMT: Clusterizado por regional e por subcanal = 8 premiados<br />C&C: Clusterizado por faturamento médio Sell Out Total MDLZ = 2 premiados' },
  { title: '6- Qual a forma de participação?', content: 'Todos os Participantes devem dar o aceite às condições definidas no Regulamento, acompanhado do aceite do Termo de Adesão à Campanha e cumprirem o requisito exigido para participação informado em Regulamento, estarão elegíveis como Participantes.' },
  { title: '7- Qual o requisito e a mecânica da participação para Time Interno e Clientes?', content: '<strong>Requisito de participação:</strong> É necessário bater a chave de entrada para iniciar a disputa e só pontuam as categorias que fizerem >=100%.<br /><strong>Mecânica de participação:</strong> Concurso/Ranking (melhores performances dentre os Participantes de cada Canal informados em Regulamento).<br /><strong>Consultar os requisitos e mecânica completa,</strong> separada por Canal, dentro do Regulamento da campanha.<br /><br />' },
  { title: '8- Quais os critérios de avaliação (KPI’S) para Time Interno e Clientes?', content: '<strong>Time interno Mondelēz:</strong><br />Os Participantes serão avaliados em 2 etapas e acumularão pontos, de acordo com os seguintes critérios descritos no Regulamento.<br /><strong>ETAPA 1:</strong> De abril a junho de 2023<br /><strong>ETAPA 2:</strong> de julho a setembro de 2023<br /><br /><strong>Clientes Mondelēz:</strong><br />Os Participantes serão avaliados em 2 etapas e acumularão pontos, de acordo com os seguintes critérios descritos no Regulamento.<br /><strong>ETAPA 1:</strong> De abril a junho de 2023<br /><strong>ETAPA 2:</strong> de julho a setembro de 2023<br /><br /><strong>Consultar os critérios de avaliação e mecânica completa,</strong> separada por Canal, dentro do Regulamento da Campanha.<br /><br />' },
  { title: '9- Qual a pontuação para a formação do Ranking?', content: 'Verifique todos os detalhes dos critérios de avaliação separado por Canal, dentro do Regulamento da Campanha.' },
  { title: '10- Qual o critério de apuração?', content: 'De acordo com os critérios/pontuação estabelecidos em Regulamento, ao final de cada Etapa, a Promotora fará o cômputo geral da pontuação obtida pelos Participantes, durante o período de participação, com a finalidade de elaborar o Ranking Final, de cada Canal, e em ordem decrescente, da maior para menor pontuação geral, para premiar os melhores Participantes.<br />Em caso de empate, será utilizado, como critério desempate, o percentual (%) de atingimento da chave de entrada.<br />Após a divulgação do ranking por Regional, todos os participantes terão o prazo de 10 (dez) dias para contestação dos resultados junto à Promotora, findo esse período, os resultados serão considerados como finais e os participantes serão premiados, conforme previsto em Regulamento.' },
  { title: '11- Divulgação do resultado?', content: 'O resultado final da Campanha será divulgado através do site da URL: <a href="https://ganheomundomondelez.com.br">https://ganheomundomondelez.com.br</a> e os vencedores também serão contatados por e-mail e telefone (contato, via WhatsApp, no número de telefone móvel informado no cadastro).' },
  { title: '12- Esclarecimentos gerais sobre a campanha?', content: 'As dúvidas sobre a Campanha poderão ser esclarecidas junto ao SAC, por meio do e-mail: contato@ganheomundomondelez.com.br e via Fale Conosco, dentro da plataforma da Campanha, horário de atendimento de segunda à sexta-feira (exceto feriados), das 09h00 às 18h00. Verifique todos os detalhes da Campanha, separado por canal, dentro do Regulamento em: <a href="https://ganheomundomondelez.com.br">https://ganheomundomondelez.com.br</a>.' }
]

function Faq() {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  return <Template banner={false}>

    <div style={{ marginTop: '8rem' }}></div>

    <S.WidgetBox className="widget-box wrapper">


      <p className="widget-box-title" style={{ color: '#fff' }}>
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final dela.
      </p>

      <div className="widget-box-content">
        <div className="simple-accordion-list">

          {questions.map((q, i) => <Accordion index={i}
            question={q.title}
            isOpen={activeAccordion === i}
            onOpen={newIndex => setActiveAccordion(newIndex)}>
            {q.content}
          </Accordion>)}


        </div>
      </div>
    </S.WidgetBox>
  </Template>;
}

export default Faq;
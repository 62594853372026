import React, { useState, useCallback } from 'react';
import Planet from "../../assets/img/planet.png"
import InputText from "../../components/Forms/InputText"
import { MaskDocument } from "../../utils/mask"
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import RecoveryPassForm from './RecoveryPassForm'
import logo from '../../assets/img/icons/mundo.png'
import * as S from './style'


export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { openModal } = useModal();

    const { doLogin } = useAuth();

    async function handleLogin(event) {
        event.preventDefault();
        await doLogin(email, password)
    }

    const handleMask = useCallback((login) => {
        const charCode = login.charCodeAt()
        if (charCode > 47 && charCode < 58) {
            return MaskDocument(login)
        }
        return login
    }, [])

    const handleAskRecovery = useCallback(() => {
        openModal({
            header: "Redefinir Senha",
            body: <RecoveryPassForm />,
        })
    }, [openModal])

    return <S.Container>
        <S.Logo src={logo} alt="" />

        <S.PlanetContainer>
            <img src={Planet} aria-hidden alt="planet deco" />
        </S.PlanetContainer>

        <S.ContainerLogin>
            <div className="login-content">
                <form className="form" onSubmit={handleLogin}>
                    <div className="form-row">
                        <div className="form-item">
                            <InputText name="login-username" label="Email ou CPF" onChange={(email) => {
                                console.log(email)
                                setEmail(email)
                            }} mask={handleMask} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-item">
                            <S.InputPassword name="login-password" className="form__input__focus" label="Senha" onChange={(password) => {
                                setPassword(password)
                            }} />
                        </div>
                    </div>

                    <div className="form-row space-between">
                        <div className="form-item">
                            <p className="form-link cursor-pointer" type="text" onClick={() => handleAskRecovery()}>Recuperar a senha?</p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-item">
                            <S.Button className="button medium secondary" type="submit">
                                Acesse
                            </S.Button>

                        </div>
                    </div>
                </form>
                <S.Text>Se você tiver problemas, entre em contato pelo e-mail <S.Anchor href={`mailto: ${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</S.Anchor></S.Text>
            </div>
        </S.ContainerLogin>
    </S.Container>
}
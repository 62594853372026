import styled from "styled-components"

export const Container = styled.div`
  aspect-ratio: 497 / 432;
  flex: 1 49%;
`

export const Canvas = styled.canvas`
  width: 100% !important;
  height: auto !important;
`
import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(
    async config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    async error => {
        Promise.reject(error)
    }
);

api.interceptors.response.use(config => config, error => {
    const errorMessage = error.response.data.message;
    if (errorMessage !== 'Token inválido') return Promise.reject(error);

    localStorage.removeItem('token');
    window.location.href = "/"
});


export default api;
import israel from '../../assets/img/imagem-sobre-israel.png'
import * as S from './styled'

function VideoSectionComponent() {
  return <S.Container>
    <S.TextArea>
      <div>
        <S.Header>
          <span>ISRAEL</span>
        </S.Header>
        <S.SmallHeader>Onde tradição e inovação andam juntas.</S.SmallHeader>
        <S.Paragraph>
          Um país único no mundo que é referência em tecnologia e inovação enquanto ocupa um território de histórias milenares, assim como o seu povo.
          É território dos marcos mais sagrados das três principais religiões monoteístas do mundo: islamismo, cristianismo e judaísmo.
          O local exerce fascínio em todos que o conhecem, independentemente de religião.
          Apesar das tradições, Israel é uma nação jovem, de apenas 74 anos, com cidades vibrantes e modernas.
          Com uma das maiores concentrações de unicórnios do mundo é conhecida como a “Startup Nation”.
          Referência em inovação para o varejo, suas Escolas de Negócios atraem executivos de todo o mundo.
        </S.Paragraph>
      </div>
    </S.TextArea>
    <div>
      <S.Image src={israel} alt="" />
    </div>
  </S.Container>
}

export default VideoSectionComponent
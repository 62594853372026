export function addToCartRquest (product, amount) {
    return {
        type: 'ADD_REQUEST',
        amount,
        product
    }
}

export function addToCartSuccess (product) {
    return {
        type: 'ADD_SUCCESS',
        product,
    }
}

export function removeFromCartRequest (id) {
    return {
        type: 'REMOVE_FROM_CART_REQUEST',
        id,
    }
}

export function removeFromCartSuccess (id) {
    return {
        type: 'REMOVE_FROM_CART_SUCCESS',
        id,
    }
}

export function updateAmountRequest (id, amount, control) {
    return {
        type: 'UPDATE_AMOUNT_REQUEST',
        id,
        amount,
        control
    }
}

export function updateAmountSuccess (id, amount, control) {
    return {
        type: 'UPDATE_AMOUNT_SUCCESS',
        id,
        amount,
        control
    }
}